export const EN = {
  AactivityOnboarding: {
    SUMMARY: {
      ACTIVITY_TEXT: 'Frequency of outings'
    }
  },
  HipayPayments: {
    CARDNUMBER: 'Card number',
    EXPIRYDATE: 'Expiry date',
    FULLNAME: 'Full name',
    GOCARDLESS_LINK: 'Pay by bank debit'
  },
  Slider: {
    GPS_INTERVAL: 'GPS frequency',
    GPS_POSITION_REPORT_ENABLED: 'GPS position report enabled',
    TRACKER_INTERVAL_TEXT_WARNING: 'Disabling GPS position tracking will increase battery life, but the safety areas and history will no longer work.'
  },
  TypeSwitch: {
    ANIMAL_SUBTITLE: 'Choose the pet for which you bought this tracker:',
    CAT_TYPE: 'Cat tracker',
    DOG_TYPE: 'Dog tracker',
    FAMILY_SUBTITLE: 'Who did you buy this tracker for?',
    KID_TYPE: 'A child',
    SILVER_TYPE: 'A senior citizen',
    TITLE: 'Who is the tracker for?'
  },
  account: {
    LANGUAGE: {
      MESSAGES: {
        UPDATE_ERROR_UNKNOWN: 'A problem occurred',
        UPDATE_SUCCESS: 'Language updated'
      }
    },
    NAV: {
      LANGUAGE: 'Language',
      PASSWORD: 'Password',
      PAYMENTS: 'Payment and invoicing',
      USER_PREFERENCES: 'Preferences',
      USER_PROFILE: 'User profile'
    },
    PASSWORD: {
      HEADLINE: 'Password change',
      MESSAGES: {
        UPDATE_ERROR_UNKNOWN: 'A problem occurred',
        UPDATE_ERROR_WRONG_PASSWORD: 'The old password you entered is incorrect',
        UPDATE_SUCCESS: 'Password changed'
      }
    },
    PAYMENTS: {
      HISTORY_HEADLINE: 'Payment history',
      MAIN_HEADLINE: 'Invoicing'
    },
    USER_PREFERENCES: {
      EMAIL_PREFERENCES_HEADLINE: 'Email preferences',
      EMAIL_PREFERENCES_USER_INFO: 'The preferences will be applied to the email address {{user_email}}',
      MAIN_HEADLINE: 'Preferences',
      MESSAGES: {
        UPDATE_ERROR_UNKNOWN: 'A problem occurred while saving preferences',
        UPDATE_SUCCESS: 'Preferences updated successfully'
      },
      METRIC_PREFERENCES_DESCRIPTION: 'Choose the distance unit used for your tracker.',
      METRIC_PREFERENCES_HEADLINE: 'Metric system preference:'
    },
    USER_PROFILE: {
      BASIC_INFO_HEADLINE: 'Basic information',
      BILLING_HEADLINE: 'Invoicing address',
      MAIN_HEADLINE: 'User profile',
      MARKETING_AGREEMENT_TEXT: 'I agree to receive special offers and promotions from Weenect\'s partners',
      MESSAGES: {
        UPDATE_ERROR_UNKNOWN: 'A problem occurred',
        UPDATE_SUCCESS: 'Information updated'
      },
      METRIC_KM: 'Kilometers',
      METRIC_MILES: 'Miles',
      PREFERRED_METRIC: 'Measurements to use:'
    }
  },
  activity: {
    AVERAGE_SPEED: 'Average speed',
    CONTROLS: {
      PAUSE_PLAYING_TITLE: 'Pause history playback',
      RESUME_PLAYING_TITLE: 'Restart history playback',
      START_PLAYING: 'Launch',
      STOP_PLAYING: 'Stop',
      STOP_PLAYING_TITLE: 'Stop history playback',
      TOGGLE_HEATMAP: 'Heatmap',
      TOGGLE_HEATMAP_TITLE: 'Display the heatmap',
      TOGGLE_PATH: 'Plot of positions',
      TOGGLE_PATH_TITLE: 'Display position plot'
    },
    DETAIL_TITLE: 'Activity details',
    EMPTY_LIST: 'No alerts to display. Change the period selected or check that the tracker is turned on.',
    EVENTS: {
      ALERT: 'Lateral button activated',
      BATTERY: 'Low battery - {{battery}}% remaining',
      CALL_END: 'SOS call ended',
      CALL_REQ: 'SOS call requested',
      ENTERS_GEOFENCE: 'Geofence entry',
      EXIT_GEOFENCE: 'Geofence exit',
      FLASH_REQ: 'Flash requested',
      FREQ_CHANGE: 'Frequency modified',
      RING: 'Ringtone performed',
      RING_REQ: 'The ringtone has been requested',
      SOS: 'SOS alert',
      TRACKER_OFF: 'Tracker switched off',
      TRACKER_ON: 'Tracker switched on',
      VIBRATE: 'Vibration performed',
      VIBRATE_REQ: 'Vibrator selected'
    },
    FEEDBACKTEXT: {
      FIRST: '',
      SECOND: ''
    },
    HISTORY_TITLE: 'Notifications history',
    LAST_TIME_PERIOD: 'Show last',
    LOADING: 'Loading...',
    MAXIMUM_SPEED: 'Maximum speed',
    OBJECTIVE: {
      CONTENT: 'Set a daily intense activity goal.',
      DAILY_REACHED: '{{name}} has reached her daily goal!',
      HELPTEXT: 'Only intense activity is taken into account when measuring the daily goal. Like a run or a play session for example.',
      RECOMENDED_VALUE: 'Recommended goal: 1 hour',
      SUBTEXT: 'Our algorithm records every movement of your tracker and then classifies the results by intensity level. This way we can determine if it is an intense, moderate or low activity.',
      TITLE: 'Daily goal'
    },
    ONBOARDING: {
      FIRST_SUBTEXT: 'This tool helps you keep track of your furball\'s daily activity.',
      SECOND_SUBTEXT: 'How long did he sleep? How many steps did he take today?',
      START: 'Start!',
      THIRD_SUBTEXT: 'You\'ll even be able to set a goal based on your pet\'s breed, so that your pet gets the daily activity it needs.',
      TITLE: 'Welcome to the Weenect activity-tracking !'
    },
    PROGRESSBAR: {
      TITLE: 'Daily goal'
    },
    SELECT_DAY: 'Choose a day to display',
    SELECT_TIME: 'Use slider to select the time interval to display',
    SUMMARY: {
      ACTIVITY: 'Intense',
      AWAKENING: 'Moderate',
      DATEINTERVAL: '',
      DAY_INDEX: 'd',
      DISTRIBUTIONTEXT: 'Detailed report',
      HOUR_INDEX: 'h',
      LASTWEEK: '',
      MENU_TITLE: 'Activity report',
      MIN_INDEX: 'min',
      MODIFY_PERIOD: 'Change the period',
      PREVIOUS_DAY: '',
      PREVIOUS_MONTH: '',
      PREVIOUS_WEEK: '',
      REGISTERED_ACTIVITY: 'steps',
      REPORT: 'View report ',
      REST: 'Low',
      SUBTEXT: '{{time}} of intense activity left before reaching {{name}}\'s goal.',
      TEXT_OBJECTIVE_COMPLETED: '{{name}} has reached her daily goal!',
      TEXT_OBJECTIVE_COMPLETED_TREAT: 'Congratulations, {{name}} has achieved her goal! Maybe that deserves a treat?',
      TEXT_OBJECTIVE_NONE: '{{name}} has no data for this period',
      TEXT_OBJECTIVE_NONE_VERIFY_TRACKER: '{{name}} hasn\'t recorded any movements yet. Check that your tracker is switched on and the battery is charged!',
      TITTLE: '{{name}} progress',
      TODAY: 'Today,',
      TOTAL: 'Total',
      YESTERDAY: 'Yesterday'
    },
    TIME_ELAPSED: 'Period',
    TOTAL_DISTANCE: 'Total distance'
  },
  activityGeneral: {
    BEHAVIOR: 'Activity',
    STANDARD_MORPHOLOGY: 'Morphology ',
    STERILIZED: 'Sterilized'
  },
  activityOnboarding: {
    COMMON: {
      STEP: 'Step',
      TRACKER_PROFIL: 'Tracker profile'
    },
    MONTH: {
      APRIL: 'April',
      AUGUST: 'August',
      DECEMBER: 'December',
      FEBRUARY: 'February',
      JANUARY: 'January',
      JULY: 'July',
      JUNE: 'June',
      MARCH: 'March',
      MAY: 'May',
      NOVEMBER: 'November',
      OCTOBER: 'October',
      SEPTEMBER: 'September'
    },
    PET_ACTIVITY: {
      FIRST_BUTTON: 'Sedentary',
      FIRST_BUTTON_CAT: 'Exceptionally (holidays, unforeseen events...)',
      FIRST_BUTTON_DOG: 'For its business only',
      FOURTH_BUTTON: 'Hyperactive (2 to 3 walks per day)',
      FOURTH_BUTTON_CAT: 'Regularly, day and night',
      FOURTH_BUTTON_DOG: 'Two or three walks per day',
      SECOND_BUTTON: 'Average (2 to 3 walks per week)',
      SECOND_BUTTON_CAT: 'From time to time, on request',
      SECOND_BUTTON_DOG: 'Two or three walks per week',
      SELECT_ACTIVITY: 'How often does it go out?',
      THIRD_BUTTON: 'Energetic (one walk per day)',
      THIRD_BUTTON_CAT: 'Regularly, during the day\n',
      THIRD_BUTTON_DOG: 'One walk per day',
      TITLE: 'How active is it?'
    },
    PET_AGE: {
      FIRST_COMMON_TEXT: 'year(s) and ',
      SECOND_COMMON_TEXT: 'month(s)',
      TITLE: 'What is its date of birth?'
    },
    PET_GENDER: {
      FEMALE: 'A female',
      FIRST_TITLE: 'What is the gender of your pet?',
      MALE: 'A male',
      SECOND_TITLE: 'Is your pet sterilized?'
    },
    PET_HEALTH: {
      LAST_VACINATION: 'What about the last vaccination?',
      LAST_VET_VISIT: 'When was the last time your pet went to the vet?',
      TITLE: 'Health point'
    },
    PET_IDENTIFICATION: {
      CHIP: 'Chip',
      NONE: 'My pet does not have any form of identification',
      SELECT_IDENTIFICATION: 'What is its identification?',
      TATTOO: 'Tattoo'
    },
    PET_INFO: {
      GET_STARTED: 'Let\'s get started!',
      NEED_TO_KNOW: 'We need to get to know  {{name}} .',
      PETS_DETAILS: 'Please enter your pet\'s details.',
      PETS_GENDER: {
        MIXED_BREED: 'It is a crossbred'
      },
      PET_GENDER_COMMON: 'To begin with, your pet is: ',
      SECOND_PETS_DETAILS: 'Enter your pet\'s details'
    },
    PET_MORPH: {
      AVERAGE: 'Average',
      COMMON_TEXT: 'Around',
      FIRST_TITLE: 'What is its body type?',
      HELP_TEXT: 'How to define the morphology of my animal?\n',
      LEAN: 'Lean',
      OBESE: 'Obese',
      OVERWEIGHT: 'Overweight',
      SECOND_TITLE: 'What does it weigh?',
      WEIGH_TEXT: 'How much does your pet weigh?'
    },
    PET_NAME: {
      COMMON_TEXT: 'Let\'s get to know your furball! You can change this information in the settings.',
      PLACEHOLDER: 'Your pet\'s name',
      TITLE: 'What is your pet called?'
    },
    PET_RACE: {
      COMMON_TEXT: 'I don\'t know its breed',
      PLACEHOLDER: 'What is your dog\'s breed?',
      SELECT_BREED: 'Select its breed',
      TITLE: 'What is your pet\'s breed ?'
    },
    PET_TYPE: {
      CAT: 'A cat',
      DOG: 'A dog'
    },
    SUMMARY: {
      BIRTH_DATE: 'Date of birth',
      BREED: 'Breed',
      CONDITION: 'Condition',
      DAILY_EXERCISE: 'Daily exercise',
      DELETE: 'Reset profile.',
      HEALTH: 'Health',
      IDENTIFICATION: 'Identification',
      MODAL: {
        NO: 'No, cancel',
        SUBTEXT: 'You will then have to start the process all over again.',
        TITLE: 'Are you sure you want to reset your pet\'s information?',
        YES: 'Yes i\'m sure'
      },
      MORPHOLOGY: 'Build/body type',
      NAME: 'Name',
      RECAPTEXT: 'Here is the information about your companion.',
      SEX: 'Sex',
      SPECIES: 'Species',
      SUBMIT: 'Confirm profile',
      UNSPECIFIED: 'unspecified',
      VALIDATION: 'Profile verification',
      VALIDATION_TEXT: 'Please confirm your details before continuing. ',
      WEIGHT: 'Weight'
    }
  },
  activityTracking: {
    ACTIVITY: 'Activity points',
    ACTIVITY_DISTRIBUTION: {
      DATA_COMPARATOR: {
        ACTIVITY_INTENSE: 'Intense activity',
        ACTIVITY_INTENSE_LEGEND: 'of intense activity {{day}}',
        ACTIVITY_MODERATE: 'Moderate activity',
        ACTIVITY_MODERATE_LEGEND: 'of moderate activity {{day}}',
        ACTIVITY_WEAK: 'Low activity',
        ACTIVITY_WEAK_LEGEND: 'of low activity {{day}}',
        TEXT: '{{petName}} had {{comparator}} {{activity}} activity span yesterday than {{day}}.'
      }
    },
    ACTIVITY_REPORT: {
      DATA_COMPARATOR: {
        LEGEND: {
          KM_MONTHLY: 'kilometers daily on average this month',
          KM_WEEKLY: 'kilometers daily on average this week',
          KM_YESTERDAY: 'kilometers covered yesterday',
          STEP_MONTHLY: 'daily steps on average this month',
          STEP_WEEKLY: 'daily steps on average this week',
          STEP_YESTERDAY: 'steps taken yesterday'
        },
        TEXT_INF: 'lower than',
        TEXT_KM_DAILY: 'Yesterday\'s distance covered by {{petName}} was {{comparator}} the day before yesterday.',
        TEXT_KM_MONTHLY: 'This week\'s average distance covered by {{petName}} is {{comparator}} this month\'s average.',
        TEXT_KM_WEEKLY: 'Yesterday\'s distance covered by {{petName}} is {{comparator}} their weekly average.',
        TEXT_SIM: 'similar to',
        TEXT_STEP_DAILY: '{{petName}}\'s steps taken yesterday were {{comparator}} the day before yesterday.',
        TEXT_STEP_MONTHLY: '{{petName}}\'s average steps taken this week were {{comparator}} this month\'s current average.',
        TEXT_STEP_WEEKLY: 'The steps taken yesterday were {{comparator}} {{petName}}\'s weekly average.',
        TEXT_SUP: 'higher than',
        TITLE_KM_TRAVELED: 'Kilometers covered',
        TITLE_STEP: 'Steps taken'
      },
      KM_TRAVELED: '{{step}} kilometers covered'
    },
    AIMED_GOAL: {
      ALERT: 'We recommend a daily goal of at least one hour to keep your pet healthy.',
      TITLE: 'Set a daily activity goal.'
    },
    DAILY_CHART: '{{petName}}\'s Activity',
    DAILY_GOAL: {
      NOT_RECOMMENDED: 'Not recommended goal',
      RECOMMENDED: 'Recommended goal',
      SECOND_TITLE: 'Daily goal',
      TITLE: 'Daily goal'
    },
    DAILY_STEP: 'Daily steps',
    DAYS: 'Day',
    EQUAL: 'the same',
    ERROR_PAGE: {
      BUTTON_TEXT: 'Close page',
      TEXT: 'We\'re unable to load the page right now. Please try again later.',
      TITLE: 'Oops, we\'ve encountered a problem...'
    },
    HEADER: {
      ACTIVITY_DISTRIBUTION: {
        TITLE: 'Activity Breakdown'
      },
      ACTIVITY_REPORT: {
        TITLE: 'Activity Reports'
      },
      GOAL: {
        TITLE: 'Daily goal'
      }
    },
    HOME: {
      ACTIVITY_DISTRIBUTION: {
        INTENSE: 'Intense',
        MODERATE: 'Moderate',
        WEAK: 'Low'
      },
      ALERT: 'A few learning days are needed to ensure reliable data. During this period, the algorithm gathers and analyzes your data to understand your pet\'s activity patterns and habits. Over time, the results will become more accurate and tailored to their profile.',
      FIRST_SECTION: {
        TITLE: 'Daily goal'
      },
      INSURANCE: {
        BUTTON: 'Discover Weenect Insurance',
        TEXT: 'A vet visit is a must. Trust Weenect for a refund of your consultation.',
        TITLE: 'Concerned about {{petName}} health?'
      },
      MODIFY: 'Modify',
      PROFILE: {
        SUBTITLE: 'Monitor their daily activity to keep them in shape.'
      },
      SECOND_SECTION: {
        SUBTITLE: 'For the current day.',
        TITLE: 'Distribution'
      },
      SEE_MORE: 'See more',
      THIRD_SECTION: {
        TITLE: 'Highlights'
      }
    },
    HOUR: '{{count}} h',
    HOURS: 'Hours',
    INTERVAL: {
      TEXT: 'Move the edges of the graph to change the period displayed.',
      TITLE: 'Period selection'
    },
    KEY_POINTS: 'Highlights',
    KILOMETERS: 'Kilometers',
    LESS: 'a shorter',
    MINUTE_ACTIVITY_one: '{{count}} minute of activity',
    MINUTE_ACTIVITY_other: '{{count}} minutes of activity',
    MINUTE_one: '{{count}} minute',
    MINUTE_other: '{{count}} minutes',
    MIN_one: '{{count}} min',
    MIN_other: '{{count}} min',
    MONTHLY_STEP: 'Monthly steps',
    ONBOARDING: {
      FIRST_STEP: {
        ALERT: 'In airplane mode, the activity continues to be tracked. It will appear in the dashboard as soon as you leave the wifi area.',
        BUTTON: 'Start',
        MESSAGE: 'This tool helps you keep track of your furball\'s daily activity.  How long did your pet sleep? How many steps did they take today? You\'ll soon know it all!',
        TITLE: 'Welcome to your pet\'s activity tracking!'
      },
      SECONDE_STEP: {
        ALERT: 'We recommend a daily goal of at least one hour to keep your pet healthy.',
        BUTTON: 'Save',
        PROFILE: {
          SUBTITLE: 'Monitor their daily activity to keep them in shape.',
          TITLE: '{{petName}}\'s Activity'
        },
        TITLE: 'Set a daily activity goal.'
      }
    },
    PERIOD: {
      DAY: 'Day',
      MONTH: 'Month',
      TITLE: 'Period selection',
      WEEK: 'Week',
      YEAR: 'Year'
    },
    PLUS: 'a longer',
    RETURN_HOME: 'Return to home page',
    STEP: 'Steps',
    STEP_SAVED: '{{step}} steps',
    TEXT: 'This graph shows you the activity of your pet. The measurement of this activity is done at an interval of between 0 and infinity. Each activity point represents a change, i.e. that your pet is on the move.',
    TOTAL: 'Total',
    WEEKDAYS: {
      FRIDAY: 'Friday',
      FRIDAY_COMPARATIVE: 'on Friday',
      MONDAY: 'Monday',
      MONDAY_COMPARATIVE: 'on Monday',
      SATURDAY: 'Saturday',
      SATURDAY_COMPARATIVE: 'on Saturday',
      SUNDAY: 'Sunday',
      SUNDAY_COMPARATIVE: 'on Sunday',
      THURSDAY: 'Thursday',
      THURSDAY_COMPARATIVE: 'on Thursday',
      TUESDAY: 'Tuesday',
      TUESDAY_COMPARATIVE: 'on Tuesday',
      WEDNESDAY: 'Wednesday',
      WEDNESDAY_COMPARATIVE: 'on Wednesday'
    },
    WEEKLY_STEP: 'Weekly steps',
    YESTERDAY: 'yesterday'
  },
  alertCustomer: {
    BODY0: 'Our SIM card provider is currently undergoing maintenance.',
    BODY1: 'During this period of maintenance <1>the delay for a SIM activation has been extended to 24h</1>.',
    BODY2: 'We are well aware of the inconvenience this poses. We apologise for this situation beyond our control.\n\nFor more information, you can contact our team at <1>family@weenect.com</1> or by phone at +442070391906.',
    FOOTER: 'Weenect Team'
  },
  animalInfo: {
    LANDING_PAGE: {
      BUTTON: 'Start',
      TEXT: 'Let\'s get to know your furball. You can change this information in the settings. ',
      TITLE: 'Enter your pet\'s information!',
      WARNING: 'This information is necessary for the most accurate activity tracking and to personalize your Weenect experience.'
    },
    NEXT_BUTTON: 'Next',
    PROFIL_VALIDATION: {
      ACTIVITY: 'Activity and behavior',
      BUTTON: 'Validate the profile',
      INDENTIFICATION_INFOS: 'Identification info',
      MEDICAL_HISTORY: 'Medical history',
      PERSONAL_INFOS: 'Personal info',
      PHYSICAL: 'Physical characteristics',
      SEX: 'Sex',
      SPECIES: 'Species',
      TITLE: 'Profile validation',
      UNIQUE_IDENTIFICATION: 'Unique identification'
    },
    QUESTION: 'Question',
    QUESTION_EIGHT: {
      NO: 'No',
      NOT_STERILIZED: 'Not neutered',
      QUESTION: 'Is your pet sterilized?',
      STERILIZATION_STATUS: 'Neutering status',
      STERILIZED: 'Neutered',
      YES: 'Yes'
    },
    QUESTION_ELEVEN: {
      EVERYTIME: 'Often',
      EXIT_FREQUENCY: 'Frequency of outings',
      MOSTLY: 'From time to time',
      OFTEN: 'Regularly',
      QUESTION: 'How often does it go out?',
      RARELY: 'Rarely'
    },
    QUESTION_FIVE: {
      QUESTION: 'How much does your pet weigh?',
      WEIGHT: 'Weight'
    },
    QUESTION_FOUR: {
      FEMALE: 'Female',
      MALE: 'Male',
      QUESTION: 'What is your pet\'s sex?'
    },
    QUESTION_NINE: {
      LAST_VISIT: 'Last vet visit',
      QUESTION: 'When was the last time your pet went to the vet?'
    },
    QUESTION_ONE: {
      CAT: 'A cat',
      DOG: 'A dog',
      QUESTION: 'What is your pet\'s profile?'
    },
    QUESTION_SEVEN: {
      CHIPED: 'Chip',
      IDENTIFICATION: 'Identification',
      NOT_IDENTIFIED: 'My pet is not identified',
      QUESTION: 'What is its identification?',
      TATTOED: 'Tattoo'
    },
    QUESTION_SIX: {
      QUESTION: 'What is your pet\'s breed?',
      RACE: 'Breed'
    },
    QUESTION_TEN: {
      LAST_VACCIN: 'Last vaccination',
      QUESTION: 'What about the last vaccination?'
    },
    QUESTION_THREE: {
      BIRTHDATE: 'Date of birth',
      QUESTION: 'What is its date of birth?'
    },
    QUESTION_TWO: {
      NAME: 'Name',
      QUESTION: 'What is its name?'
    }
  },
  breed: {
    CAT: {
      ABYSSINIAN: 'Abyssinian',
      AMERICAN_BOBTAIL: 'American Bobtail',
      AMERICAN_CURL: 'American Curl',
      AMERICAN_SHORTHAIR: 'American Shorthair',
      AMERICAN_WIREHAIR: 'American Wirehair',
      BALINESE: 'Balinese',
      BENGAL: 'Bengal',
      BIRMAN: 'Birman',
      BOMBAY: 'Bombay',
      BURMESE: 'Burmese',
      BURMILLA: 'Burmilla',
      CALIFORNIA_SPANGLED_CAT: 'California Spangled Cat',
      CHANTILLYTIFFANY: 'Chantilly-Tiffany',
      CHARTREUX: 'Chartreux',
      CORNISH_REX: 'Cornish Rex',
      CYMRIC: 'Cymric',
      DEVON_REX: 'Devon Rex',
      EGYPTIAN_MAU: 'Egyptian Mau',
      EUROPEAN: 'European',
      EXOTIC_SHORTHAIR: 'Exotic Shorthair',
      HAVANA: 'Havana',
      JAPANESE_BOBTAIL: 'Japanese Bobtail',
      JAVANESE: 'Javanese',
      KORAT: 'Korat',
      MAINE_COON: 'Maine Coon',
      MALAYAN: 'Malayan',
      MANX: 'Manx',
      MUNCHKIN: 'Munchkin',
      NEBELUNG: 'Nebelung',
      NORWEGIAN: 'Norwegian',
      NORWEGIAN_FOREST_CAT: 'Norwegian Forest Cat',
      OCICAT: 'Ocicat',
      ORIENTAL_LONGHAIR: 'Oriental Longhair',
      ORIENTAL_SHORTHAIR: 'Oriental Shorthair',
      PERSIAN: 'Persian',
      RAGDOLL: 'Ragdoll',
      RUSSIAN_BLUE: 'Russian Blue',
      SAFARI_CAT: 'Safari Cat',
      SCOTTISH_FOLD: 'Scottish Fold',
      SELKIRK: 'Selkirk',
      SIAMESE: 'Siamese',
      SIBERIAN: 'Siberian',
      SINGAPURA: 'Singapura',
      SNOWSHOE: 'Snowshoe',
      SOMALI: 'Somali',
      SPHYNX: 'Sphynx',
      THAI: 'Thai',
      TONKINESE: 'Tonkinese',
      TURKISH_ANGORA: 'Turkish Angora',
      TURKISH_VAN: 'Turkish Van'
    },
    DOG: {
      AFFENPINSCHER: 'Affenpinscher',
      AFGHAN_HOUND: 'Afghan Hound',
      AIREDALE_TERRIER: 'Airedale Terrier',
      AKITA: 'Akita',
      AKITA_INU_GREAT_JAPANESE_DOG: 'Akita Inu (Great Japanese Dog)',
      ALASKAN_MALAMUTE: 'Alaskan Malamute',
      ALPINE_DACHSBRACKE: 'Alpine Dachsbracke',
      AMERICAN_BULLDOG: 'American Bulldog',
      AMERICAN_COCKER_SPANIEL: 'American Cocker Spaniel',
      AMERICAN_FOXHOUND: 'American Foxhound',
      AMERICAN_STAFFORDSHIRE_TERRIER: 'American Staffordshire Terrier',
      AMERICAN_WATER_SPANIEL: 'American Water Spaniel',
      ANATOLIAN_SHEPHERD_DOG: 'Anatolian Shepherd Dog',
      APPENZELLER_SENNENHUND: 'Appenzeller Sennenhund',
      ARDENNES_CATTLE_DOG: 'Ardennes Cattle Dog',
      ARGENTINE_DOGO: 'Argentine Dogo',
      ARIEGEOIS: 'Ariégeois',
      ARIEGE_POINTER: 'Ariege Pointer',
      ARTOIS_HOUND: 'Artois Hound',
      ATLAS_SHEPHERD_DOG_AIDI: 'Atlas Shepherd Dog (Aidi)',
      AUSTRALIAN_CATTLE_DOG: 'Australian Cattle Dog',
      AUSTRALIAN_KELPIE: 'Australian Kelpie',
      AUSTRALIAN_SHEPHERD: 'Australian Shepherd',
      AUSTRALIAN_TERRIER: 'Australian Terrier',
      AUSTRIAN_BLACK_AND_TAN_HOUND: 'Austrian Black and Tan Hound',
      AUSTRIAN_SHORTHAIRED_PINSCHER: 'Austrian Shorthaired Pinscher',
      AZAWAKH: 'Azawakh',
      BALKAN_HOUND: 'Balkan Hound',
      BARBET: 'Barbet',
      BASENJI: 'Basenji',
      BASSET_ARTESIEN_NORMAND: 'Basset Artésien Normand',
      BASSET_BLEU_DE_GASCOGNE: 'Basset Bleu de Gascogne',
      BASSET_FAUVE_DE_BRETAGNE: 'Basset Fauve de Bretagne',
      BASSET_HOUND: 'Basset Hound',
      BAVARIAN_MOUNTAIN_SCENTHOUND: 'Bavarian Mountain Scenthound',
      BEAGLE: 'Beagle',
      BEARDED_COLLIE: 'Bearded Collie',
      BEAUCERON: 'Beauceron',
      BEDLINGTON_TERRIER: 'Bedlington Terrier',
      BELGIAN_GRIFFON: 'Belgian Griffon',
      BELGIAN_SHEPHERD_DOG_GROENENDAEL: 'Belgian Shepherd Dog (Groenendael)',
      BELGIAN_SHEPHERD_DOG_LAEKENOIS: 'Belgian Shepherd Dog (Laekenois)',
      BELGIAN_SHEPHERD_DOG_MALINOIS: 'Belgian Shepherd Dog (Malinois)',
      BELGIAN_SHEPHERD_DOG_TERVUREN: 'Belgian Shepherd Dog (Tervuren)',
      BERGAMASCO_SHEPHERD: 'Bergamasco Shepherd',
      BERNER_NIEDERLAUFHUND: 'Berner Niederlaufhund',
      BERNESE_HOUND: 'Bernese Hound',
      BERNESE_MOUNTAIN_DOG: 'Bernese Mountain Dog',
      BICHON_FRISE: 'Bichon Frise',
      BILLY_DOG: 'Billy (dog)',
      BLACK_AND_TAN_COONHOUND: 'Black and Tan Coonhound',
      BLOODHOUND_SAINTHUBERT_DOG: 'Bloodhound (Saint-Hubert Dog)',
      BLUE_PICARDY_SPANIEL: 'Blue Picardy Spaniel',
      BOHEMIAN_WIREHAIRED_POINTING_GRIFFON: 'Bohemian Wire-haired Pointing Griffon',
      BOLOGNESE_DOG: 'Bolognese (dog)',
      BORDEAUX_MASTIFF_FRENCH_MASTIFF: 'Bordeaux Mastiff (French Mastiff)',
      BORDER_COLLIE: 'Border Collie',
      BORDER_TERRIER: 'Border Terrier',
      BORZOI: 'Borzoi',
      BOSNIAN_COARSEHAIRED_HOUND: 'Bosnian Coarse-haired Hound',
      BOSTON_TERRIER: 'Boston Terrier',
      BOURBONNAIS_POINTER: 'Bourbonnais Pointer',
      BOUVIER_DES_FLANDRES: 'Bouvier des Flandres',
      BOXER_DOG: 'Boxer (dog)',
      BRAQUE_D_AUVERGNE: 'Braque d\'Auvergne',
      BRAZILIAN_TERRIER: 'Brazilian Terrier',
      BRIARD: 'Briard',
      BRIQUET_GRIFFON_VENDEEN: 'Briquet Griffon Vendéen',
      BRITTANY_SPANIEL: 'Brittany Spaniel',
      BROHOLMER: 'Broholmer',
      BRUNO_JURA_HOUND: 'Bruno Jura Hound',
      BRUNO_JURA_HOUND_BRUNO_TYPE: 'Bruno Jura Hound (Bruno type)',
      BRUNO_JURA_HOUND_ST_HUBERT_TYPE: 'Bruno Jura Hound (St. Hubert type)',
      BRUSSELS_GRIFFON: 'Brussels Griffon',
      BULLMASTIFF: 'Bullmastiff',
      BULL_TERRIER_ENGLISH_BULL_TERRIER: 'Bull Terrier (English Bull Terrier)',
      BURGOS_POINTER: 'Burgos Pointer',
      CAIRN_TERRIER: 'Cairn Terrier',
      CANAAN_DOG: 'Canaan Dog',
      CANARY_ISLAND_PODENCO: 'Canary Island Podenco',
      CANE_CORSO: 'Cane Corso',
      CAO_DE_CASTRO_LABOREIRO: 'Cão de Castro Laboreiro',
      CAO_FILA_DE_SAO_MIGUEL: 'Cão Fila de São Miguel',
      CATAHOULA_LEOPARD_DOG: 'Catahoula Leopard Dog',
      CATALAN_SHEEPDOG: 'Catalan Sheepdog',
      CAUCASIAN_SHEPHERD_DOG: 'Caucasian Shepherd Dog',
      CAVALIER_KING_CHARLES_SPANIEL: 'Cavalier King Charles Spaniel',
      CA_DE_BOU_PERRO_DE_PRESA: 'Ca de Bou (Perro de Presa)',
      CENTRAL_ASIAN_SHEPHERD_DOG: 'Central Asian Shepherd Dog',
      CESKY_TERRIER: 'Cesky Terrier',
      CHESAPEAKE_BAY_RETRIEVER: 'Chesapeake Bay Retriever',
      CHIHUAHUA: 'Chihuahua',
      CHINESE_CRESTED_DOG: 'Chinese Crested Dog',
      CHOW_CHOW: 'Chow Chow',
      CIRNECO_DELL_ETNA: 'Cirneco dell\'Etna',
      CLUMBER_SPANIEL: 'Clumber Spaniel',
      CONTINENTAL_TOY_SPANIEL: 'Continental Toy Spaniel',
      CORSICAN_DOG: 'Corsican Dog',
      COTON_DE_TULEAR: 'Coton de Tulear',
      CROATIAN_SHEEPDOG: 'Croatian Sheepdog',
      CURLYCOATED_RETRIEVER: 'Curly-Coated Retriever',
      CZECHOSLOVAKIAN_WOLFDOG: 'Czechoslovakian Wolfdog',
      DACHSHUND_LONGHAIRED: 'Dachshund (Long-haired)',
      DACHSHUND_LONGHAIRED_RABBIT: 'Dachshund (Long-haired rabbit)',
      DACHSHUND_SMOOTHHAIRED: 'Dachshund (Smooth-haired)',
      DACHSHUND_SMOOTHHAIRED_RABBIT: 'Dachshund (Smooth-haired rabbit)',
      DACHSHUND_WIREHAIRED: 'Dachshund (Wire-haired)',
      DACHSHUND_WIREHAIRED_RABBIT: 'Dachshund (Wire-haired rabbit)',
      DALMATIAN: 'Dalmatian',
      DANDIE_DINMONT_TERRIER: 'Dandie Dinmont Terrier',
      DOBERMAN_PINSCHER: 'Doberman Pinscher',
      DOGO_CANARIO: 'Dogo Canario',
      DOGUE_DE_BORDEAUX: 'Dogue de Bordeaux',
      DRENTSE_PARTRIDGE_DOG: 'Drentse Partridge Dog',
      DREVER: 'Drever',
      DUNKER: 'Dunker',
      DUPUY_POINTER: 'Dupuy Pointer',
      DUTCH_SHEPHERD_LONGHAIRED_VARIETY: 'Dutch Shepherd, Long-Haired Variety',
      DUTCH_SHEPHERD_ROUGHHAIRED_VARIETY: 'Dutch Shepherd, Rough-Haired Variety',
      DUTCH_SHEPHERD_SHORTHAIRED_VARIETY: 'Dutch Shepherd, Short-Haired Variety',
      DUTCH_SMOUSHOND: 'Dutch Smoushond',
      EAST_SIBERIAN_LAIKA: 'East Siberian Laika',
      ENGLISH_BULLDOG: 'English Bulldog',
      ENGLISH_COCKER_SPANIEL: 'English Cocker Spaniel',
      ENGLISH_POINTER: 'English Pointer',
      ENGLISH_SETTER: 'English Setter',
      ENGLISH_SPRINGER_SPANIEL: 'English Springer Spaniel',
      ENGLISH_TOY_TERRIER: 'English Toy Terrier',
      ENTLEBUCHER_MOUNTAIN_DOG: 'Entlebucher Mountain Dog',
      EURASIER: 'Eurasier',
      FAWN_BRITTANY_BASSET: 'Fawn Brittany Basset',
      FIELD_SPANIEL: 'Field Spaniel',
      FILA_BRASILEIRO: 'Fila Brasileiro',
      FINNISH_HOUND: 'Finnish Hound',
      FINNISH_LAPPHUND: 'Finnish Lapphund',
      FINNISH_SPITZ: 'Finnish Spitz',
      FLATCOATED_RETRIEVER: 'Flat-Coated Retriever',
      FOXHOUND_ENGLISH_FOXHOUND: 'Foxhound (English Foxhound)',
      FRENCH_BULLDOG: 'French Bulldog',
      FRENCH_SPANIEL: 'French Spaniel',
      FRENCH_WHITE_AND_BLACK_HOUND: 'French White and Black Hound',
      FRENCH_WHITE_AND_ORANGE_HOUND: 'French White and Orange Hound',
      FRISIAN_WATER_DOG: 'Frisian Water Dog',
      GASCONY_POINTER: 'Gascony Pointer',
      GERMAN_LONGHAIRED_POINTER: 'German Longhaired Pointer',
      GERMAN_PINSCHER: 'German Pinscher',
      GERMAN_SHEPHERD: 'German Shepherd',
      GERMAN_SHORTHAIRED_POINTER: 'German Shorthaired Pointer',
      GERMAN_SPITZ: 'German Spitz',
      GERMAN_WIREHAIRED_POINTER: 'German Wirehaired Pointer',
      GIANT_SCHNAUZER: 'Giant Schnauzer',
      GLEN_OF_IMAAL_TERRIER: 'Glen of Imaal Terrier',
      GOLDEN_RETRIEVER: 'Golden Retriever',
      GORDON_SETTER: 'Gordon Setter',
      GOTH_SHEPHERD_DOG: 'Goth Shepherd Dog',
      GRAND_ANGLOFRANCAIS_BLANC_ET_NOIR: 'Grand Anglo-Français Blanc et Noir',
      GRAND_ANGLOFRANCAIS_BLANC_ET_ORANGE: 'Grand Anglo-Français Blanc et Orange',
      GRAND_ANGLOFRANCAIS_TRICOLORE: 'Grand Anglo-Français Tricolore',
      GRAND_BASSET_GRIFFON_VENDEEN: 'Grand Basset Griffon Vendéen',
      GRAND_BLEU_DE_GASCOGNE: 'Grand Bleu de Gascogne',
      GRAND_GASCONSAINTONGEOIS_HOUND: 'Grand Gascon-Saintongeois Hound',
      GRAND_GRIFFON_VENDEEN: 'Grand Griffon Vendéen',
      GRAND_SPANISH_HOUND: 'Grand Spanish Hound',
      GREATER_SWISS_MOUNTAIN_DOG: 'Greater Swiss Mountain Dog',
      GREAT_DANE: 'Great Dane',
      GREAT_PYRENEES: 'Great Pyrenees',
      GREENLAND_DOG: 'Greenland Dog',
      GREYHOUND: 'Greyhound',
      HALDEN_HOUND: 'Halden Hound',
      HAMILTONSTOVARE: 'Hamiltonstövare',
      HANOVERIAN_SCENTHOUND: 'Hanoverian Scenthound',
      HANOVER_HOUND: 'Hanover Hound',
      HARRIER: 'Harrier',
      HAVANESE_DOG: 'Havanese (dog)',
      HELLENIC_HOUND: 'Hellenic Hound',
      HOKKAIDO: 'Hokkaido',
      HOVAWART: 'Hovawart',
      HUNGARIAN_GREYHOUND: 'Hungarian Greyhound',
      HUNGARIAN_KUVASZ: 'Hungarian Kuvasz',
      HYGEN_HOUND: 'Hygen Hound',
      IBIZAN_HOUND_SHORT_HAIRED: 'Ibizan Hound (short haired)',
      IBIZAN_HOUND_WIRE_HAIRED: 'Ibizan Hound (wire haired)',
      ICELANDIC_SHEEPDOG: 'Icelandic Sheepdog',
      IRISH_RED_AND_WHITE_SETTER: 'Irish Red and White Setter',
      IRISH_SETTER: 'Irish Setter',
      IRISH_TERRIER: 'Irish Terrier',
      IRISH_WATER_SPANIEL: 'Irish Water Spaniel',
      IRISH_WOLFHOUND: 'Irish Wolfhound',
      ISTRIAN_COARSEHAIRED_HOUND: 'Istrian Coarse-haired Hound',
      ISTRIAN_SHORTHAIRED_HOUND: 'Istrian Shorthaired Hound',
      ITALIAN_GREYHOUND: 'Italian Greyhound',
      ITALIAN_POINTING_DOG: 'Italian Pointing Dog',
      ITALIAN_SHORTHAIRED_HOUND: 'Italian Short-haired Hound',
      ITALIAN_SPITZ: 'Italian Spitz',
      ITALIAN_WIREHAIRED_HOUND: 'Italian Wire-haired Hound',
      JACK_RUSSELL_TERRIER: 'Jack Russell Terrier',
      JAGDTERRIER: 'Jagdterrier',
      JAPANESE_SPANIEL_CHIN: 'Japanese Spaniel (Chin)',
      JAPANESE_SPITZ: 'Japanese Spitz',
      JAPANESE_TERRIER: 'Japanese Terrier',
      KAI_KEN: 'Kai Ken',
      KARELIAN_BEAR_DOG: 'Karelian Bear Dog',
      KARST_SHEPHERD: 'Karst Shepherd',
      KERRY_BLUE_TERRIER: 'Kerry Blue Terrier',
      KISHU_KEN: 'Kishu Ken',
      KOMONDOR: 'Komondor',
      KOREAN_JINDO_DOG: 'Korean Jindo Dog',
      KROMFOHRLANDER: 'Kromfohrländer',
      KYILEO: 'Kyi-Leo',
      LABRADOR: 'Labrador',
      LAKELAND_TERRIER: 'Lakeland Terrier',
      LANDSEER: 'Landseer',
      LAPPONIAN_HERDER: 'Lapponian Herder',
      LARGE_MUNSTERLANDER: 'Large Münsterländer',
      LEONBERGER: 'Leonberger',
      LHASA_APSO: 'Lhasa Apso',
      LOWCHEN_LITTLE_LION_DOG: 'Lowchen (Little Lion Dog)',
      LUCERNE_HOUND: 'Lucerne Hound',
      LUZERNER_LAUFHUND: 'Luzerner Laufhund',
      MAJORCA_SHEPHERD_DOG: 'Majorca Shepherd Dog',
      MALTESE_DOG: 'Maltese (dog)',
      MANCHESTER_TERRIER: 'Manchester Terrier',
      MAREMMA_SHEEPDOG: 'Maremma Sheepdog',
      MASTIFF_FRENCH_MASTIFF: 'Mastiff (French Mastiff)',
      MEDIUM_POODLE: 'Medium Poodle',
      MEXICAN_HAIRLESS_DOG: 'Mexican Hairless Dog',
      MINIATURE_BULL_TERRIER: 'Miniature Bull Terrier',
      MINIATURE_DACHSHUND_LONGHAIRED: 'Miniature Dachshund (Long-haired)',
      MINIATURE_DACHSHUND_SMOOTHHAIRED: 'Miniature Dachshund (Smooth-haired)',
      MINIATURE_DACHSHUND_WIREHAIRED: 'Miniature Dachshund (Wire-haired)',
      MINIATURE_PINSCHER: 'Miniature Pinscher',
      MINIATURE_POODLE: 'Miniature Poodle',
      MINIATURE_SCHNAUZER: 'Miniature Schnauzer',
      MIXED_BREED_CROSSBREED: 'Mixed Breed (Crossbreed)',
      MUDI: 'Mudi',
      NEAPOLITAN_MASTIFF: 'Neapolitan Mastiff',
      NEWFOUNDLAND: 'Newfoundland',
      NORFOLK_TERRIER: 'Norfolk Terrier',
      NORRBOTTENSPETS: 'Norrbottenspets',
      NORWEGIAN_BUHUND: 'Norwegian Buhund',
      NORWEGIAN_ELKHOUND_BLACK: 'Norwegian Elkhound Black',
      NORWEGIAN_ELKHOUND_GRAY: 'Norwegian Elkhound Gray',
      NORWEGIAN_LUNDEHUND: 'Norwegian Lundehund',
      NORWICH_TERRIER: 'Norwich Terrier',
      NOVA_SCOTIA_DUCK_TOLLING_RETRIEVER: 'Nova Scotia Duck Tolling Retriever',
      OLD_DANISH_POINTER: 'Old Danish Pointer',
      OLD_ENGLISH_SHEEPDOG: 'Old English Sheepdog',
      OTTERHOUND: 'Otterhound',
      PARSON_RUSSELL_TERRIER: 'Parson Russell Terrier',
      PEKINGESE: 'Pekingese',
      PERUVIAN_HAIRLESS_DOG_LARGE: 'Peruvian Hairless Dog (Large)',
      PERUVIAN_HAIRLESS_DOG_MEDIUM: 'Peruvian Hairless Dog (Medium)',
      PERUVIAN_HAIRLESS_DOG_SMALL: 'Peruvian Hairless Dog (Small)',
      PETIT_BASSET_GRIFFON_VENDEEN: 'Petit Basset Griffon Vendéen',
      PETIT_BRABANCON: 'Petit Brabancon',
      PETIT_GASCON_SAINTONGEOIS: 'Petit Gascon Saintongeois',
      PHARAOH_HOUND: 'Pharaoh Hound',
      PICARDY_SHEPHERD: 'Picardy Shepherd',
      PICARDY_SPANIEL: 'Picardy Spaniel',
      POITEVIN: 'Poitevin',
      POLISH_HOUND: 'Polish Hound',
      POLISH_LOWLAND_SHEEPDOG: 'Polish Lowland Sheepdog',
      PONTAUDEMER_SPANIEL: 'Pont-Audemer Spaniel',
      PORCELAINE: 'Porcelaine',
      PORTUGUESE_PODENGO_GRANDE: 'Portuguese Podengo Grande',
      PORTUGUESE_PODENGO_MEDIO: 'Portuguese Podengo Medio',
      PORTUGUESE_PODENGO_PEQUENO: 'Portuguese Podengo Pequeno',
      PORTUGUESE_POINTER: 'Portuguese Pointer',
      PORTUGUESE_SHEEPDOG: 'Portuguese Sheepdog',
      PORTUGUESE_SHEEPDOG_LONGHAIRED: 'Portuguese Sheepdog (Long-haired)',
      PORTUGUESE_SHEEPDOG_SHORTHAIRED: 'Portuguese Sheepdog (Short-haired)',
      PORTUGUESE_WATER_DOG: 'Portuguese Water Dog',
      POSAVAC_HOUND: 'Posavac Hound',
      PRAGUE_RATTER: 'Prague Ratter',
      PUDEL_POINTER: 'Pudel Pointer',
      PUG: 'Pug',
      PULI_HUNGARIAN_SHEPHERD: 'Puli (Hungarian Shepherd)',
      PUMI_HUNGARIAN_SHEPHERD: 'Pumi (Hungarian Shepherd)',
      PYRENEAN_MOUNTAIN_DOG: 'Pyrenean Mountain Dog',
      PYRENEAN_POINTER: 'Pyrenean Pointer',
      PYRENEAN_SHEEPDOG_LONGFACED_VARIETY: 'Pyrenean Sheepdog, Long-Faced Variety',
      PYRENEAN_SHEEPDOG_SMOOTHFACED_VARIETY: 'Pyrenean Sheepdog, Smooth-Faced Variety',
      RAFEIRO_DE_ALENTEJO: 'Rafeiro de Alentejo',
      REDBONE_COONHOUND: 'Redbone Coonhound',
      REDTICK_COONHOUND: 'Redtick Coonhound',
      RHODESIAN_RIDGEBACK: 'Rhodesian Ridgeback',
      ROMAGNA_WATER_DOG: 'Romagna Water Dog',
      ROTTWEILER: 'Rottweiler',
      ROUGH_COLLIE: 'Rough Collie',
      RUSSIAN_BLACK_TERRIER: 'Russian Black Terrier',
      RUSSOEUROPEAN_LAIKA: 'Russo-European Laika',
      SAARLOOSWOLFHOND: 'Saarlooswolfhond',
      SAINTUSUGE_SPANIEL: 'Saint-Usuge Spaniel',
      SAINT_BERNARD: 'Saint Bernard',
      SALUKI: 'Saluki',
      SAMOYED: 'Samoyed',
      SANSHU: 'Sanshu',
      SCHAPENDOES: 'Schapendoes',
      SCHILLERSTOVARE: 'Schillerstövare',
      SCHIPPERKE: 'Schipperke',
      SCHWEIZER_LAUFHUND: 'Schweizer Laufhund',
      SCOTTISH_DEERHOUND: 'Scottish Deerhound',
      SCOTTISH_TERRIER: 'Scottish Terrier',
      SEALYHAM_TERRIER: 'Sealyham Terrier',
      SHAR_PEI: 'Shar Pei',
      SHETLAND_SHEEPDOG: 'Shetland Sheepdog',
      SHIBA_INU: 'Shiba Inu',
      SHIH_TZU: 'Shih Tzu',
      SHIKOKU: 'Shikoku',
      SHORTHAIRED_HUNGARIAN_POINTER: 'Short-haired Hungarian Pointer',
      SIBERIAN_HUSKY: 'Siberian Husky',
      SILKY_TERRIER: 'Silky Terrier',
      SKYE_TERRIER: 'Skye Terrier',
      SLOUGHI: 'Sloughi',
      SLOVAKIAN_WIREHAIRED_POINTER: 'Slovakian Wirehaired Pointer',
      SLOVAK_CUVAC: 'Slovak Cuvac',
      SLOVAK_HOUND: 'Slovak Hound',
      SMALAND_HOUND: 'Smaland Hound',
      SMALL_MUNSTERLANDER: 'Small Munsterlander',
      SMALL_SPANISH_HOUND: 'Small Spanish Hound',
      SMOOTH_COLLIE: 'Smooth Collie',
      SMOOTH_FOX_TERRIER_ENGLISH_FOX_TERRIER: 'Smooth Fox Terrier (English Fox Terrier)',
      SOFTCOATED_WHEATEN_TERRIER: 'Soft-coated Wheaten Terrier',
      SOUTH_RUSSIAN_OVCHARKA: 'South Russian Ovcharka',
      SPANISH_GREYHOUND_GALGO_ESPANOL: 'Spanish Greyhound (Galgo Español)',
      SPANISH_MASTIFF: 'Spanish Mastiff',
      SPANISH_WATER_DOG: 'Spanish Water Dog',
      SPINONE_ITALIANO: 'Spinone Italiano',
      STABYHOUN: 'Stabyhoun',
      STAFFORDSHIRE_BULL_TERRIER: 'Staffordshire Bull Terrier',
      STANDARD_POODLE: 'Standard Poodle',
      STANDARD_SCHNAUZER: 'Standard Schnauzer',
      STICHELHAAR: 'Stichelhaar',
      STYRIAN_COARSEHAIRED_HOUND: 'Styrian Coarse-haired Hound',
      ST_GERMAIN_POINTING_DOG: 'St. Germain Pointing Dog',
      SUSSEX_SPANIEL: 'Sussex Spaniel',
      SWEDISH_ELKHOUND: 'Swedish Elkhound',
      SWEDISH_LAPPHUND: 'Swedish Lapphund',
      TATRA_MOUNTAIN_SHEEPDOG: 'Tatra Mountain Sheepdog',
      TECKEL_DE_CHASSE_AU_LAPIN_POIL_DUR: 'Teckel de Chasse au lapin (poil dur)',
      TIBETAN_MASTIFF: 'Tibetan Mastiff',
      TIBETAN_SPANIEL: 'Tibetan Spaniel',
      TIBETAN_TERRIER: 'Tibetan Terrier',
      TOSA: 'Tosa',
      TOY_POODLE: 'Toy Poodle',
      TRICOLOUR_HOUND: 'Tricolour Hound',
      TYROLEAN_HOUND: 'Tyrolean Hound',
      WACHTELHUND: 'Wachtelhund',
      WEIMARANER_LONGHAIRED: 'Weimaraner Long-haired',
      WEIMARANER_SHORTHAIRED: 'Weimaraner Short-haired',
      WELSH_CORGI_CARDIGAN: 'Welsh Corgi (Cardigan)',
      WELSH_CORGI_PEMBROKE: 'Welsh Corgi (Pembroke)',
      WELSH_SPRINGER_SPANIEL: 'Welsh Springer Spaniel',
      WELSH_TERRIER: 'Welsh Terrier',
      WESTPHALIAN_DACHSBRACKE: 'Westphalian Dachsbracke',
      WEST_HIGHLAND_WHITE_TERRIER: 'West Highland White Terrier',
      WEST_SIBERIAN_LAIKA: 'West Siberian Laika',
      WHIPPET: 'Whippet',
      WHITE_SWISS_SHEPHERD_DOG: 'White Swiss Shepherd Dog',
      WIREHAIRED_HUNGARIAN_POINTER: 'Wire-haired Hungarian Pointer',
      WIREHAIRED_POINTING_GRIFFON: 'Wirehaired Pointing Griffon',
      WIRE_FOX_TERRIER: 'Wire Fox Terrier',
      YORKSHIRE_TERRIER: 'Yorkshire Terrier',
      YUGOSLAVIAN_MOUNTAIN_HOUND: 'Yugoslavian Mountain Hound',
      YUGOSLAVIAN_SHEPHERD_DOG: 'Yugoslavian Shepherd Dog',
      YUGOSLAVIAN_TRICOLOUR_HOUND: 'Yugoslavian Tricolour Hound'
    }
  },
  changePaymentMean: {
    ADD_BANK_ACCOUNT: 'Add an account to be charged',
    ADD_BANK_CARD: 'Pay by card',
    BANK_ACCOUNT_ERROR: 'There was a problem changing your banking details. Please contact customer services.',
    BANK_ACCOUNT_EXPLANATION: 'Note that none of your bank information are saved in our servers.',
    BANK_ACCOUNT_SUCCESS: 'Banking details successfully updated',
    BANK_CARD_ERRORED: 'Issue during means of payment update. Please contact the <1>after sale service</1>.',
    BANK_CARD_UPDATED: 'Credit card updated',
    CAPTATION: 'To update your bank details, we are going to authorise a charge on your card of {{ amount }}.',
    CAPTATION_EXPLANATION1: 'This amount will not be taken. The transaction is cancelled immediately.',
    CAPTATION_EXPLANATION2: 'This procedure allows us to comply with Internet payment standards and ensure the safety of your bank details. None of this information will pass through our servers.',
    CAPTATION_EXPLANATION3: 'IMPORTANT : the debit form will be named under "Hareau" and not "Weenect". That is normal, as this is the name of our company. "Weenect" being the trade name.',
    CAPTATION_EXPLANATION4: 'Update your bank details while maintaining you subscription.',
    CARD_AUTHORIZED: 'We accept payments by:',
    CHANGE_PAYMENT_MEAN: 'Update credit card',
    CURRENT_CHOICE_CB: 'You chose to pay by credit card.',
    CURRENT_CHOICE_IBAN: 'You chose to pay by IBAN',
    CURRENT_MEAN: 'Current payment method',
    CURRENT_MEAN_PHRASE: 'You have chosen to pay by credit card',
    CURRENT_MEAN_PHRASE_GOCARDLESS: 'You have chosen to pay by short code',
    DEFAULT_MEAN: 'Default payment method:',
    GO_TO_SEPA: 'Switch to direct debit',
    MODIFY: 'Modify means of payment',
    TITLE: 'Means of payment',
    UPDATE_BANK_ACCOUNT: 'Modify account to debit',
    UPDATE_BANK_CARD: 'Update credit card',
    USED_PAYMENT_EXPLANATION: 'This payment method is used to pay for your subscriptions and options.'
  },
  commonActions: {
    ACTIVATE: 'Activate',
    BACK: 'Back',
    CANCEL: 'Cancel',
    CAN_BE_CANCELED: 'You can cancel at any time.',
    CHANGE: 'Save',
    CONFIGURE: 'Set up',
    DEACTIVATE: '',
    GO_MAP: 'Go to the map',
    MESSAGES: {
      UPDATE_ERROR_UNKNOWN: 'A problem occurred'
    },
    NEXT: 'Next',
    NO: 'No',
    OK: 'OK',
    REGISTER: 'Activate',
    SAVE: 'Save',
    SELECT_ALL: 'Select all',
    SEND: 'Send',
    SUBSCRIBE: 'Sign up',
    THEN: 'then',
    UNSELECT_ALL: 'Unselect all',
    UPDATE: 'Save',
    VALIDATE: 'Confirm',
    YES: 'Yes'
  },
  creditCardAlert: {
    AUTO_RENEWAL_ADVICE: 'If you want to continue using your tracker, please add a new payment method now.',
    AUTO_RENEWAL_NOTICE: 'Warning! Your subscription is about to expire and your credit card has expired.'
  },
  emailPreferences: {
    EMAIL_LISTS: {
      COMPANY_NEWS: 'Company news',
      NEW_FEATURES: 'New features',
      OFFERS: 'Promotional offers',
      SERVICE_STATUS: 'Service failures and updates',
      SURVEYS_AND_TESTS: 'Surveys and Beta tests',
      TUTORIALS: 'Tutorials'
    },
    EMAIL_LISTS_HEADER: 'Email list',
    EMAIL_LISTS_INFO: {
      SERVICE_STATUS: 'Necessary for the proper functioning of the service.'
    },
    LANGUAGE_PREFERENCES_DESCRIPTION: 'Choose the language in which you want to receive emails:',
    PREFERENCES_DECRIPTION: 'Choose the type of emails you want to receive. We will only send you the ones that match your choices. '
  },
  error: {
    TITLE: 'Oops a problem has occurred '
  },
  errors: {
    DEFAULT: 'Invalid field',
    FIELD_REQUIRED: 'This field is required',
    GENERAL: 'An error has occurred, please try again',
    IMEI: 'Enter a valid IMEI number',
    MAIL_FORMAT: 'This email format is invalid',
    MAX_LENGTH: 'The field can contain up to 15 characters',
    PASSWORD_FORMAT: 'Your password must contain at least one digit, one uppercase letter, and one lowercase letter.',
    PHONE_FORMAT: 'The phone number must include the international prefix',
    PROMO_CODE: 'Invalid promo code'
  },
  forgotPassword: {
    FORGOT_PASSWORD_BUTTON: 'Send me a reset password link',
    FORGOT_PASSWORD_CANCEL: 'Back to login',
    FORGOT_PASSWORD_ERRORS: {
      UNKNOWN: 'An error occured. Contact our customer help.'
    },
    FORGOT_PASSWORD_EXPIRED: 'Your password reset link has expired.',
    FORGOT_PASSWORD_RETRY: 'Ask for a new forgot password link',
    FORGOT_PASSWORD_SUCCESS: 'If an account is attached to the {{mail}} address, you will receive an email with a link to reset your password.'
  },
  forms: {
    CITY: {
      LABEL: 'Town/City',
      PLACEHOLDER: 'Your town/city'
    },
    CONFIRM_PASSWORD: {
      LABEL: 'Repeat Password',
      PLACEHOLDER: 'Confirm'
    },
    COUNTRY: {
      LABEL: 'Country'
    },
    EMAIL: {
      LABEL: 'Email address',
      PLACEHOLDER: 'votre@email.com'
    },
    FIRST_NAME: {
      LABEL: 'First name',
      PLACEHOLDER: 'Your first name'
    },
    LANGUAGE: {
      LABEL: 'Language'
    },
    LAST_NAME: {
      LABEL: 'Last name',
      PLACEHOLDER: 'Your last name'
    },
    NEW_PASSWORD: {
      LABEL: 'New password',
      PLACEHOLDER: 'Your new password'
    },
    OLD_PASSWORD: {
      LABEL: 'Old password',
      PLACEHOLDER: 'Your old password'
    },
    PASSWORD: {
      LABEL: 'Password',
      PLACEHOLDER: 'Password',
      VALIDATION: 'Your password must be at least 8 characters long.',
      WARNING: 'Warning, "qwertyuiop" and "123456789" are NOT strong security passwords.\nCheck the level of security of your password here : <1>https://howsecureismypassword.net</1>'
    },
    PHONE: {
      LABEL: 'Telephone',
      PLACEHOLDER: '+33600000000'
    },
    POSTAL_CODE: {
      LABEL: 'ZIP code',
      PLACEHOLDER: 'Your postcode',
      STREET_NUMBER: 'Street number and name'
    },
    STREET: {
      LABEL: 'Address',
      PLACEHOLDER: 'Number and name of street'
    }
  },
  geofences: {
    CREATE: {
      HEADLINE: 'Create an area'
    },
    EDIT: {
      DELETE_GEOFENCE: 'Delete area',
      ENTRANCE_NOTIFICATION: 'Geofence entrance notification',
      EXIT_NOTIFICATION: 'Geofence exit notification',
      GEOCODE_LABEL: 'Use an address',
      GEOCODE_PLACEHOLDER: 'Enter the address here',
      LOCATION: 'Centre of the geofence',
      NAME_INPUT: {
        LABEL: 'Area name',
        PLACRHOLDER: 'Enter geofence name'
      },
      OUTDOORS: 'Outdoor zone (park forest)',
      OUTDOORS_TOOLTIP: 'Since GPS is more accurate outdoors, the rules for triggering an entry or exit notification are distinct for an internal zone and an external zone.',
      POINT_ON_THE_MAP_TEXT: 'or click directly on the map',
      RADIUS: 'Geofence size (radius in metres)',
      TRACKER_LAST_POSITION_BUTTON: 'Use the tracker position',
      UNKNOWN_ADDRESS: 'Unknown address'
    }
  },
  header: {
    ALL_TRACKERS: 'All trackers',
    AVAILABLE_PLURAL: 'available',
    NAV: {
      ACCOUNT: 'Account',
      INSURANCE: 'Insurance',
      LOGOUT: 'Logout',
      SUPPORT: 'Support',
      TRACKERS: 'Map'
    },
    SEARCHBAR_RESULT: 'trackers found',
    SEARCHBAR_TITLE: 'Search for a tracker:',
    TRACKERS: 'tracker(s)',
    WHITE_LABEL: {
      IN_PARTERNSHIP_WITH: 'In partnership with'
    }
  },
  imeiInfo: {
    HINT: 'You will find the IMEI number on the back of your tracker.',
    IMAGE_ALT: 'Photo of the tracker',
    TITLE: 'IMEI number'
  },
  login: {
    FORGOT_PASSWORD: 'Forgot your password?',
    LOGIN_ERRORS: {
      UNKNOWN: 'Login failure'
    },
    SIGN_IN_BUTTON: 'Login',
    SIGN_IN_HEADLINE: 'Login',
    SIGN_UP_BUTTON: 'Create an account',
    SIGN_UP_HEADLINE: 'Don\'t have an account yet?',
    TERMS_LINK: 'Terms and conditions of use',
    TERMS_LINK_URL: 'https://www.weenect.com/common/pdf/en/cgv-weenect.pdf'
  },
  manageSubscription: {
    CANCEL: {
      DESCRIBE_YOUR_PROBLEMS: 'Could you please describe your reason for ending your subscription?',
      GIVE_US_DETAILS: 'Reason for ending your subscription.',
      REASONS: {
        AFTER_SALES_SERVICE: 'Bad experience with Customer Services',
        BATTERY: 'Battery life too short',
        LOST_BROKEN: 'I\'ve lost / broke it',
        MOBILE_APPLICATION: 'The app doesn\'t work',
        NOT_SATISFIED: 'I\'m not satisfied with Weenect',
        NO_USE: 'I don\'t need it anymore',
        TOO_EXPENSIVE: 'The subscription is too expensive',
        TRACKER_FORMAT: 'Tracker needs to be redesigned (size and weight)',
        WILL_REACTIVATE_LATER: 'I\'m going to reactivate the subscription later'
      },
      SAD_TO_SEE_YOU_LEAVE: 'We are sorry to see you leave.',
      WHY_DO_YOU_RESIGN: 'For what reason do you wish to cancel your subscription?'
    },
    CANCEL_EXPIRE: 'Automatic renewal has been cancelled. The subscription will expire on {{date}}.',
    CANCEL_QUESTION: 'Do you want to discontinue the subscription?',
    CANCEL_RENEWAL: 'Cancel automatic renewal',
    CANCEL_RENEWAL_BIS: 'Unsubscribe',
    CANT_RESIGN_UNDER_EXTENDED_NOTICE: 'Want to end your subscription or simply stop it renewing automatically?\nCall us on +33 (0)1 87 66 98 71 between 9:30 a.m. and 4 p.m. Monday to Friday.',
    CANT_RESIGN_UNDER_NOTICE: 'It is not possible to cancel your subscription in the final 30 days. Please contact the Customer Support team.',
    CANT_RESIGN_UNTIL: 'You can\'t resign your subscription until the {{date}}',
    CANT_RESIGN_WHILE_COMMITED: 'You are committed for 3 months, the termination of your subscription will only take effect at the end of the current period, i.e. on {{date}}',
    CURRENT_PLAN: 'Current subscription',
    HEADLINE: 'Manage subscription',
    MESSAGES: {
      UPDATE_SUCCESS: 'Subscription successfully updated.'
    },
    RENEW_SUBSCRIPTION: 'Renew subscription',
    RESIGN_DATE: 'The termination of your subscription will take effect at the end of the current period on {{date}}',
    RESUMPTION_DATE: 'Choose a resumption date',
    RETURN_TO_TRACKERS: 'Back to the map'
  },
  map: {
    NO_GPS_MARKER_TITLE: 'Invalid GPS',
    POSITION: 'Position'
  },
  mapControls: {
    MAP_TYPE: 'Map type',
    REFRESH_POSITION: 'Refresh position',
    SUPER_LIVE: 'Super live',
    ZOOM_IN: 'Zoom +',
    ZOOM_OUT: 'Zoom -'
  },
  mapLayers: {
    CLASSIC_MAPS: 'Free maps',
    DONT_SUBSCRIBE: 'Continue with standard maps',
    FOREST_PATH: 'Paths and trails',
    GOOGLE_STREET: 'Google Maps',
    GRAY: 'Black and white',
    PLAN: 'Map',
    PREMIUM_MAPS: 'Premium maps',
    PREMIUM_SUBSCRIBE: 'Subscribe to the Premium Maps option',
    PREMIUM_WARNING_MSG: '<1>Oops, you are not currently subscribed to the Premium Maps option!</1>\n\nThe Premium Maps option gives you the advantage of more comprehensive and regularly updated maps that allow you to better see and monitor your tracker\'s movements. \u2028Offering HD Satellite, Terrain Map and Forest Trails versions, it enables you to find your tracker\'s current location at a glance.\n\nSubscribe to this option for just {{mapOptionPrice}} per month!',
    RELIEF: 'Reliefs',
    SATELLITE: 'Satellite',
    STREET: 'Classic',
    UNLOCK_MAPS: 'Unlock the additional cards by clicking on them!'
  },
  misc: {
    LANGUAGE: {
      DE: 'German',
      EN: 'English',
      ES: 'Spanish',
      FR: 'French',
      IT: 'Italian',
      NL: 'Dutch'
    },
    NO_DATE: 'No known position',
    ROTATE_DEVICE: 'Weenect was designed to be used in portrait mode. Please rotate your device for best use of our services.',
    locale: 'en-US'
  },
  onboarding: {
    ACTIVATING_HINT: 'Average activation time is 5 minutes',
    ACTIVATING_INDICATOR: 'Activation in progress',
    GREET: {
      BUTTON: 'My tracker is on.',
      COMMON_TEXT: 'To get a good start we recommend that you follow this tutorial. It will help you get started with your tracker.\n\nLet\'s start with switching on your tracker. Follow the directions below to turn it on:',
      MAIN_TITLE: 'Contact information',
      RED_TEXT: 'The tracker needs to be outdoors in order for it to obtain a GPS location.'
    },
    MAP_INTERACTIONS: {
      COMMON_TEXT: {
        PET: 'From top to bottom, these buttons allow you to make your tracker ring or vibrate. These features allow you to train your pet to come when called.',
        SILVER: 'From top to bottom, these buttons allow you to make your tracker ring or vibrate. '
      },
      TITLE: 'The buttons that interact with the tracker'
    },
    MAP_INTERVAL: {
      COMMON_TEXT: 'This button allows you to select how often you want to receive a new GPS location for your tracker. Please note, the shorter the interval, the less time the battery of the tracker will last.',
      TITLE: 'The GPS reporting time interval'
    },
    MAP_OPTIONS: {
      COMMON_TEXT: 'These buttons allow you to change the type of map on display and zoom in or out on the map.',
      TITLE: 'Map adjustment buttons'
    },
    MAP_UPDATE: {
      COMMON_TEXT: 'This button allows you to update the location of your tracker (to obtain a new location your tracker must be connected to the server and have a valid GPS signal).',
      TITLE: 'The position refresh button'
    },
    MODAL: {
      BUTTON_HINT: 'Press the button for 3 seconds until the tracker vibrates.',
      HEADLINE: 'Turn on your tracker outdoors or on a window sill',
      IMAGE_ALT: 'Photo of the tracker',
      MAP_HINT: 'Once turned on, the tracker will usually be displayed on the map within 5 minutes.'
    },
    REMOTE_ASSISTANCE: {
      ASSISTANCE: {
        COMMON_TEXT: 'If no one reacts within this period, the alert is transmitted to an operator.'
      },
      EXPLANATION: 'The operator takes charge of the call and responds to the needs of the person carrying the tracker. Depending on the situation, they can contact relatives or call for help.',
      PRICE: 'This option costs € 9.99 per month and can be deactivated at any time.',
      SOS: {
        COMMON_TEXT: 'Your loved one presses the SOS button on their tracker.'
      },
      TIME: {
        COMMON_TEXT: 'You are notified and have 30 seconds to react to the alert.'
      },
      TITLE: 'Learn about our new option: remote assistance ',
      USE_CASE: 'This option allows the person carrying the tracker to be contacted by a specialised tele-assistance platform, available 24/7.'
    },
    SIDE_PAGES: {
      COMMON_TEXT: {
        PET: 'In the vertical tab on the left, you can find all the management and use pages for your tracker. The History tab allows you to view your animal\'s movements and the Activity tracking tab to find out its energy expenditure. The Zones tab allows you to configure zones, the Settings tab contains your pet\'s profile and the Subscription tab allows you to easily manage your subscription and your options.',
        SILVER: 'In the vertical tab on the left, you can find all the management and use pages for your tracker. The History tab allows you to check the movements of your loved one and the Zones tab allows you to configure geofences.\n The Settings tab allows you to configure the number to be called in the event of an SOS, as well as the messages associated with each of the tracker\'s lateral buttons. Finally, the Subscription tab allows you to very easily manage your subscription and your options.'
      },
      TITLE: 'Other pages'
    },
    SKIP: 'Skip the tutorial',
    SUPERLIVE: {
      COMMON_TEXT: 'This button allows you to trigger the "superlive" mode: a new position every 10 seconds for 5 minutes.',
      TITLE: 'The "superlive" button'
    },
    TUTORIAL_START: {
      BUTTON: 'Continue the tutorial',
      COMMON_TEXT: 'Great, your tracker is now ready to use. Let\'s continue with a quick overview of your My Weenect account area.'
    },
    WAITING_GPS_POSITION: 'Finding a GPS position',
    WAITING_GPS_POSITION_HINT: 'Turn your tracker on outdoors'
  },
  optionCardExpired: {
    PROPOSAL: 'Please update your bank card to continue using our services.',
    UPDATE_CARD: 'Update your card details now',
    WARNING: '<1>Warning!</1> You cannot subscribe to this option because <1>your bank card has expired</1>.'
  },
  payment: {
    ADD_NEW_PAYMENT_REGULARISE: 'Please register a new payment method or correct your situation.',
    EXPIRACY_CREDIT_CARD_TEXT: 'Please add a new payment method to regularize your situation.',
    NO_PAYMENT_MEAN_YET: 'No payment method for the moment',
    NO_PAYMENT_YET: 'No payment for the moment',
    UPDATE_PAYMENT_REGULARISE: 'If you want to continue using your tracker, please update or change your payment method now.',
    WARNING_ERROR: 'There seems to be a problem with your payment method.',
    WARNING_EXPIRACY_CREDIT_CARD: 'Warning, your credit card has expired.',
    WARNING_NOT_ENOUGH_FOUNDS: 'Warning, it seems that there are not enough funds in your account.'
  },
  payments: {
    CARD: {
      ACCEPTED: '(CB, VISA, Mastercard, American Express)',
      DESCRIPTION: '30 days satisfaction or refund',
      NAME: 'Credit card'
    },
    STATUS: {
      CANCELED: 'Cancelled',
      ERROR: 'Failure',
      NEW: 'Being processed',
      PAYMENT_ERROR: 'Payment failure',
      PAYMENT_ERROR_EXPLAIN_TEXT: 'An error occurred with your payment. Please try again in a few minutes or contact',
      PAYMENT_RETRY: 'Try again',
      PAYMENT_SAV: 'customer service.',
      PAYMENT_SUCCESS: 'Payment successful!',
      PAYMENT_SUCCESS_TEXT: 'Your payment has been processed. You can now begin your Weenect experience!',
      PAYMENT_SUCCESS_TEXT_APP: 'Your payment has been processed.',
      PENDING: 'Being processed',
      REFUNDED: 'Refunded',
      SUCCESS: 'Confirmed'
    },
    TRANSFER: {
      DESCRIPTION: '15 extra days to change your mind, 30 days satisfaction or refund',
      NAME: 'Direct debit'
    }
  },
  periods: {
    ALT: {
      MONTH: '1 month',
      YEAR: '1 year',
      YEAR_plural: '{{count}} years'
    },
    BASE: {
      MONTH: 'month',
      YEAR: 'year'
    }
  },
  registerSummaryTable: {
    BILLED_MONTH: 'The payment is monthly',
    BILLED_NOW: 'Immediate invoicing',
    BILLED_THREE_YEARS: 'The payment is made once every three years',
    BILLED_TWO_YEARS: 'Billed once every two years',
    BILLED_YEAR: 'The payment is made once a year',
    HINT_OPTIONS_PRICE: 'The options, for the amount of {{amount}}, will be billed separately from the subscription and within two hours.',
    OPTION_UPGRADE: 'Add the {{name}} option for only {{price}}/{{period}}',
    PAY_HINT: 'Click the "Pay and Activate" button and pay {{subscriptionPrice}} for the subscription.',
    SUBSCRIPTION_UPGRADE: 'Change to a 2 year subscription and save {{amount}} each year.',
    TOTAL: 'Total price'
  },
  registerTracker: {
    BUY_LINK: 'Don\'t have a Weenect tracker yet? <1> You can buy them here </1>',
    ERRORS: {
      UNKNOWN: 'There was a problem activating your tracker, please try again'
    },
    HEADLINE: 'Activate a new tracker',
    IMEI_INPUT: {
      LABEL: 'IMEI number',
      PLACEHOLDER: 'You will find the IMEI number on the back of your tracker.'
    },
    NAME_INPUT: {
      LABEL: 'Tracker name',
      PLACEHOLDER: 'Name of who is carrying the tracker'
    }
  },
  remoteAssistance: {
    BASE: 'Remote assistance',
    FEATURES: 'If the SOS button is pressed and you do not respond within 30 seconds, the SOS alert and voice call are automatically transmitted to our call centre, 24 hours a day, 7 days a week. Our agents are empowered to contact emergency services such as the police or the ambulance service.',
    FORM: {
      ADDRESS_INPUT: {
        LABEL: 'Address',
        PLACEHOLDER: 'Number and name of street'
      },
      BACK: 'Cancel',
      CITY_INPUT: {
        LABEL: 'Town/City',
        PLACEHOLDER: ''
      },
      EXTRA_PHONE: {
        LABEL: 'Secondary phone (optional)',
        PLACEHOLDER: ''
      },
      HEADLINE: 'Registration for remote assistance',
      NAME_INPUT: {
        LABEL: 'Full name',
        PLACEHOLDER: 'First name LAST NAME'
      },
      PHONE: {
        LABEL: 'Telephone',
        PLACEHOLDER: ''
      },
      POSTAL_CODE_INPUT: {
        LABEL: 'ZIP code',
        PLACEHOLDER: ''
      },
      SAVE: 'Confirm',
      TERMS: 'I have read and accept the <1> terms of use </1> of the service',
      TEXT: 'Enter the details of your loved one (the holder of the tracker). This will allow our agents to help them in the event of an SOS alert.'
    },
    PENDING: {
      WAITING_FOR_CALL_TEST: 'Registration form validated. Waiting for the test call. \nYou must now make a test call in order to finalise the activation of the option. Refer to the email sent to you on how to do this.',
      WAITING_FOR_RESIGN: 'Option is being deactivated. \nYour request to deactivate the option has been received. Our agents are currently taking care of the administrative formalities. The deactivation will be effective shortly.',
      WAITING_FOR_VALIDATION: 'Registration form being validated. \nThe information you provided when you subscribed to the option is currently being verified by our agents. As soon as this information is validated, we can proceed with the last step of the option\'s activation: a test call must be made to verify that everything is working properly.'
    },
    USER_PHONE: {
      TEXT: 'Enter your details. This will allow our agents to contact you in the event of an SOS alert.'
    }
  },
  santevet: {
    ADVANTAGE: {
      FIRST: '<span>6 months of Weenect subscription offered</span>\nat subscription',
      PROMO_CODE: 'with the code',
      SECOND: '<span>20€ of prevention offered/year</span> in addition to the already included package'
    },
    FOOTER: {
      BUTTON: 'See prices',
      TEXT: 'By continuing, you agree to be contacted by Santévet.'
    },
    OFFERS: {
      BILL: {
        TEXT: 'From <span>60% to 100%</span> and up to 2,500€/year.',
        TITLE: 'Your costs reimbursed in case of illnesses and accidents'
      },
      CROSS: {
        TEXT: 'Benefit <span>free of charge</span> and <span>at any time</span> from a telephone assistance.',
        TITLE: 'A 24/7 emergency veterinary service'
      },
      MONEY_PUSH: {
        TEXT: 'SantéVet advances your costs <span>free of charge</span> at the end of the consultation.',
        TITLE: 'The advance payment of your veterinary expenses with Payvet'
      },
      SERINGE: {
        TEXT: '<span>Up to 150€/year</span> for preventive care (vaccinations, antiparasitics, etc).',
        TITLE: 'A prevention package included in all offers'
      },
      TITLE: 'The advantages of Santévet:'
    },
    SUBTITLE: 'and enjoy exclusive benefits',
    TITLE: 'Insure {{name}} for\nonly {{amount}} €/month',
    TITLE_WITHOUT_PRICE: 'Insure {{name}} right now'
  },
  signup: {
    ALREADY_MEMBER_TEXT: 'Already a user?',
    CREATE_ACCOUNT_HEADLINE: 'Create an account',
    LOGIN_TEXT: 'Log in',
    MARKETING_AGREEMENT_TEXT: 'I agree to receive special offers and promotions from Weenect\'s partners',
    RETRY: 'Try again',
    SIGNUP_ERRORS: {
      EMAIL_ALREADY_EXISTS: 'This email address is already used by an account',
      POPIN_EMAIL_ALREADY_EXIST: '<1>Oops</1>, it looks like this email address has already been used.',
      UNKNOWN: 'Login failure, please try again'
    },
    SIGN_IN_TEXT: 'Login',
    SUBMIT_BUTTON: 'Create my account',
    TERMS_AGREEMENT_TEXT: 'I accept <1> the terms of use </1> of the service'
  },
  simInfo: {
    TITLE: 'SIM number'
  },
  subOptions: {
    CANCEL: {
      EXPIRATION_DATE: 'This option will still be active until {{date}}',
      HEADLINE: 'Would you like to cancel the {{option}} option?'
    },
    MAP: {
      BASE: 'Multimap Pack',
      FEATURES: 'Get the advantage of more comprehensive and regularly updated maps that allow you to better see and monitor your tracker\'s movements. (HD Satellite, Terrain Map, Forest Trails)',
      TITLE: 'Premium maps:'
    },
    PREMIUM: {
      BASE: 'Premium plan',
      BUTTON: {
        FREEMONTH: 'Try it for free for 1 month!'
      },
      DEACTIVATE: 'By deactivating this option you will lose your premium benefits.',
      FEATURE: {
        CHAT: {
          BASE: 'Online chat',
          TEXT: 'Chat live with a Weenect advisor in your app. Service available 7 days a week, from 9 am to 6 pm.',
          TEXT2: 'Enjoy exclusive benefits (online chat, SMS alerts, HD maps) within the application.'
        },
        SMS: {
          TEXT: 'Get text notifications (40 text messages per month), on top of the push and email notifications already included in the subscription.'
        }
      },
      FREEMONTH: {
        BASE: 'First month free!',
        BASE2: 'FREE'
      },
      SUBTITLE: 'The Premium Pack includes the following features:',
      SUB_LEGAL: 'This membership will automatically renew each month, using the payment method you entered when signing up. You can cancel the membership at any time in your personal settings.',
      TEXT_INFO_PRICE: 'Then {{price}}/month after the first month.',
      TITLE_DISCOVER: 'Discover the exclusive benefits: ',
      TITLE_POPUP: 'Become a Premium member!'
    },
    SMS_PACK: {
      ALT_FEATURES: 'Receive all alerts by SMS',
      BASE: 'SMS pack',
      FEATURES: 'Receive all your alerts by text message (up to 20 texts per month). Please note that without this option, you will still receive the alerts via push messages (app) and email.'
    },
    UNDEFINED: {
      PAYMENTS: 'Payment for the option'
    },
    WARRANTY: {
      ALT_FEATURES: {
        one: 'Get a new tracker for free if your existing one is broken, lost or stolen.',
        other: 'Pay by debit card with cancellation possible at any time',
        two: 'Your tracer replaced in 48H in case of loss, breakage or theft, without conditions.'
      },
      BASE: 'Multi-risk warranty',
      EXPLAINER: 'The standard warranty only covers the tracker against manufacturing problems.',
      FEATURES: 'Get a free replacement tracker if your current tracker gets broken, lost or stolen: no claim justification required. The standard warranty covers the tracker against manufacturing defects only.'
    }
  },
  subscription: {
    COMMON_TEXT: {
      MONTH: 'per month',
      OR: 'i.e.'
    },
    FEATURES: {
      APP_ACCESS: 'Unlimited access to the application and location service',
      REFOUND: '15 day trial: satisfied or your money back',
      SAV: 'Customer service available 6 days a week. \nGuaranteed response within 24 hours.',
      WARRANTY: '24 month manufacturer\'s warranty'
    },
    FIRST_PAGE: {
      BUTTON: '',
      MODAL_GIFT_CARD: {
        BUTTON: '',
        INPUT_LABEL: '',
        TITLE: ''
      },
      MODAL_INFORMATION: {
        TEXT: '',
        TITLE: ''
      },
      SUBSCRIPTION_CGV: {
        FIRST_TEXT: '',
        INCLUDES: {
          FIRST: '',
          FIRST_BOLD: '',
          FOURTH: '',
          FOURTH_BOLD: '',
          SECOND: '',
          SECOND_BOLD: '',
          THIRD: '',
          THIRD_BOLD: ''
        },
        SECOND_TEXT: '',
        SECOND_TEXT_LINK: '',
        TITLE: ''
      },
      SUBSCRIPTION_CHOICE: {
        CHIP_ONE_MONTH: '',
        CHIP_ONE_YEAR: '',
        CHIP_THREE_YEAR: '',
        SUBSCRIPTION_TITLE_GIFT_CARD: '',
        SUBSCRIPTION_TITLE_MONTHLY: '',
        SUBSCRIPTION_TITLE_one: '',
        SUBSCRIPTION_TITLE_other: '',
        TITLE: ''
      }
    },
    FREE: '',
    HEADER: {
      STEP: '',
      TITLE: {
        FIRST_PAGE: '',
        SECOND_PAGE: '',
        THIRD_PAGE: ''
      }
    },
    INFO_CGU: 'To benefit from the subscriptions, you must enter valid banking information and accept the general',
    INFO_CGU_BUTTON: 'the general conditions of use of the service.',
    INFO_OPTION: 'Payment is monthly, cancellation is possible at any time.',
    MONEY_BACK: 'All subscriptions are invoiced immediately but can be cancelled within 15 days, with a full refund of the amount paid.',
    MONTH: '',
    PAYMENT_INFO: 'Money back guarantee: 15 days to try your tracker',
    PAYMENT_SUBHEADLINE: 'Select a payment method to pay your subscription and activate your tracker.',
    PAYMENT_TITLE: 'Choose your payment method',
    PAYMENT_TITLE2: 'Register your payment method.',
    PRICE_MONTH_SAVED: 'i.e. {{price}}/month',
    SECONDE_PAGE: {
      BUTTON: ''
    },
    SECOND_PAGE: {
      BASKET: {
        TEXT: {
          premium_pack: '',
          warranty: ''
        },
        TOTAL: ''
      },
      OPTION_CHOICE: {
        CHIP: '',
        TEXT: {
          premium_pack: '',
          warranty: ''
        },
        TITLE: {
          premium_pack: '',
          warranty: ''
        }
      },
      SUBTITLE: '',
      TITLE: ''
    },
    SUMMARY: 'Summary',
    THIRD_PAGE: {
      BUTTON: '',
      MODAL: {
        TITLE: ''
      },
      PAYMENT_CHOICE: {
        BANK: '',
        CARD: '',
        TITLE: ''
      },
      SUBTITLE: ''
    }
  },
  subscriptionAlert: {
    ACTIVATE_AUTO_RENEWAL: 'Re-activate the automatic renewal',
    AUTO_RENEWAL_ADVICE: 'We advise you to re-activate the automatic renewal if you wish to extend your subscription',
    AUTO_RENEWAL_NOTICE: '<1>Warning !</1> Your {{trackerName}} tracker\'s subscription comes to an end and the automatic renewal is deactivated.',
    CONTINUE_BUTTON: 'Keep going',
    MESSAGES: {
      UPDATE_ERROR_UNKNOWN: 'A problem occurred',
      UPDATE_SUCCESS: 'Successfully updated'
    },
    REMIND_LATER: 'Remind me later',
    SUPPORT_TEXT: 'If you have questions or you think this is a mistake, please contact our <1>customer service</1>.',
    TRIAL_CASE: {
      SUBSCRIBE_BUTTON: 'Take out a subscription',
      TRIAL_IS_OVER: 'Your trial period is over',
      TRIAL_IS_OVER_ADVICE: 'We hope your trial has been a success! To continue using the product, you now need to take out a subscription.'
    }
  },
  subscriptionOptionsSelection: {
    CANCEL_WARNING_MODAL_HEADLINE: 'Are you sure you want to disable this option?',
    CANCEL_WARNING_SMS: 'By disabling this option you will not receive alert notifications by SMS.',
    CANCEL_WARNING_WARRANTY: 'By disabling this option you will lose the added protection provided by the multi-risk guarantee.',
    EXPLANATION: 'Option payments are monthly.',
    EXPLANATION2: 'And cancellation is possible at any time.',
    EXPLANATION3: 'No payment will be made.',
    HINT: 'You can enable or disable an option at any time',
    MAIN_HEADLINE: 'Choose your options',
    SUBMIT_BUTTON: 'Confirm'
  },
  subscriptionPayment: {
    ERRORS: {
      UNKNOWN: 'There was a problem during the payment, please try again'
    },
    GIFT_CARD: {
      EXPLANATION: 'Enter the code written on the back of the card provided in your tracker pack.',
      I_HAVE_ONE: 'I have a gift Card',
      NEED_HELP: 'Do you need help with Gift Cards?',
      TITLE: 'Use your gift Card',
      USE: 'Use the card'
    },
    HEADLINE: 'Summary prior to payment',
    PROMOCODE_TEXT: 'I have a coupon code:',
    PROMO_CODE: 'Coupon code',
    PROMO_CODE_GIFT_CARD: 'Gift Card',
    SATISFIED_OR_REFUNDED: 'Satisfaction or refund',
    SATISFIED_OR_REFUNDED_DESCRIPTION: 'Try it risk-free for 30 days and if you are not satisfied we will refund you.',
    SELECT_PAYMENT_MEAN: 'First select a payment method and then pay for and activate your subscription.',
    SUBMIT_BUTTON: 'Pay for subscription',
    SUBMIT_BUTTON2: 'Activate the tracker',
    SUMMARY: 'Summary of your choices'
  },
  subscriptionProcess: {
    GIFT_CARD_EXPLANATION: 'Fill in your preferred payment method to activate your tracker for free.',
    GIFT_CARD_EXPLANATION2: 'This information is required for legal reasons related to the use of SIM cards.',
    RETURN_TO_APP: 'Back to the app',
    RETURN_TO_MAP: 'Return to map',
    RETURN_TO_TRACKERS: 'Back to the map',
    STEPS: [
      'Tracker',
      'Subscription',
      'Options',
      'Summary'
    ]
  },
  subscriptionSelection: {
    BEST_OFFER: 'BEST OFFER',
    CANCELABLE_AFTER_THREE_MONTHS: 'You can cancel at any time after the first three months',
    CANCEL_ANYTIME: 'You can cancel at any time',
    COMMITMENT_TEXT: 'Can be terminated at any time after the minimum commitment period of 3 months.',
    FEATURES: '',
    FEATURES_HEADLINE: 'All subscriptions include:',
    HEADLINE: 'Choose your subscription',
    MIN_THREE_MONTHS: 'Minimum commitment of three months',
    MONEY_BACK: 'All subscriptions are invoiced immediately but can be cancelled within 30 days, with full refund of the amount paid.',
    PAYMENT_MEANS: 'Payment by direct debit and credit card',
    REFUNDED_INFO: 'We refund the subscription without any conditions.',
    REFUNDED_TEXT: 'Money back guarantee for 30 days.',
    SIM_CARD_WARNING: 'Your tracker contains an integrated SIM card. This means it is necessary to have a subscription in order to cover the service charges.',
    SUBMIT_BUTTON: 'Confirm',
    XMAS_OFFER: {
      BODY: 'Nevertheless, we have to make you choose your future subscription now (for security reasons, as the product includes a SIM card). You will not be charged before the end of the 6 free months and can cancel this subscription at any time up to one month before its actual start date.',
      TITLE: 'Your tracker includes a 6-months prepaid subscription that was paid by your loved one.'
    },
    features: [
      '30 days satisfaction or refund',
      'Unlimited GPS localisation',
      'Push notifications (application) and emails',
      '10 minutes of voice call per month'
    ]
  },
  subscriptions: {
    BASE: 'Subscription',
    CANCEL_MODAL: {
      HEADLINE: 'Do you want to cancel the automatic renewal of your subscription?',
      HINT: 'Your subscription will still be active until {{date}} but it will not renew automatically. After this date you will no longer be able to locate your tracker.'
    },
    CHANGE_PLAN: {
      ADD_OPTIONS: 'Add an option',
      EXPLANATION: 'The subscription change will come into effect at the end of the current period.',
      HEADLINE: 'Change subscription?'
    },
    DETAILS: {
      ACTIVATE_BUTTON: {
        LABEL: 'Activate subscription'
      },
      ACTIVE_CASE: {
        MANAGE_BUTTON: 'Manage subscription',
        RENEWAL_INFO: 'Your subscription will be automatically renewed on {{date}} for {{price}}'
      },
      CANCELED: {
        EXPIRED_MAIN: 'Your subscription has expired. Reactivate the subscription to locate your tracker.',
        EXPIRED_SEC: 'Expired',
        UNCANCEL_BUTTON_LABEL: 'Re-activate the automatic renewal of the subscribtion',
        WILL_EXPIRE_DAYS: 'It expires in {{count}} day',
        WILL_EXPIRE_DAYS_plural: 'It expires in {{count}} days',
        WILL_EXPIRE_MAIN: 'Automatic renewal of the subscription has been cancelled. It will expire on {{date}}.'
      },
      CANCEL_RESILLIATION: 'Cancel termination request',
      NEW_CASE: {
        INFO_TEXT: 'We are having a problem with your payment. Please try again or change your payment method.',
        PAY_BUTTON: 'Pay and activate',
        WAITING_TEXT: 'We are currently processing your payment for the subscription of this tracker.'
      },
      NO_SUB: {
        INFO_TEXT: 'Activate the subscription to start using your tracker.'
      },
      RESILLIATION_ASKED: 'Termination requested',
      SUBSCRIBE_BUTTON: {
        LABEL: 'Take out a subscription'
      },
      SUPPORT_TEXT: 'Having trouble activating your tracker? Contact <1> Support </1>.',
      TRIAL_PERIOD_CASE: {
        END_INFO: 'Your trial period will end on {{ date }}'
      }
    },
    OFFERS: {
      SAVING_PER_YEAR: 'save {{amount}} /year'
    },
    OFFERS_TEXT: {
      MOST_POPULAR: 'Most popular',
      ONE_MONTH: {
        INFO: '3 months minimum commitment',
        TITLE: '1 month package'
      },
      ONE_YEAR: {
        INFO: 'Trial period extended by 15 days: 30 days in total',
        TITLE: '1 year package'
      },
      THREE_YEAR: {
        INFO: '1 year additional manufacturer\'s warranty: 3 years in total',
        INFO2: '3-year manufacturer\'s warranty (instead of two).',
        TITLE: '3-year package'
      }
    },
    OVERVIEW: {
      OPTIONS: 'Options details',
      REG_DETAILS: 'Tracker details',
      REPLACE_TRACKER_BUTTON: {
        LABEL: 'Replace tracker'
      },
      SUB_DETAILS: 'Subscription details'
    },
    PLAN_NAMES: {
      FALLBACK: 'Subscription',
      ONE_MONTH: 'Monthly subscription',
      ONE_YEAR: '1-year prepaid subscription',
      THREE_YEARS: '3-year prepaid subscription',
      TRIAL_PERIOD: 'Trial period',
      TWO_YEARS: '2-year subscription'
    },
    STATUS: {
      ACTIVE: 'Subscribed',
      CANCELED: 'Cancelled',
      INACTIVE: 'Non-subscribed',
      NEW: 'Active',
      PENDING: 'Waiting'
    },
    TRACKER_DETAILS: {
      REGISTRATION_DATE: 'Activation date',
      WARRANTY: 'Standard guarantee'
    }
  },
  superLive: {
    ACTIVE_TEXT: 'Superlive is enabled.',
    HEADLINE: 'Super live mode',
    MESSAGES: {
      SUCCESS_ACTIVATED: 'Super live mode activated - One position every {{frequency}} seconds for 5 minutes'
    },
    TIME: 'Position taken every {{frequency}} seconds'
  },
  support: {
    FORM: {
      MESSAGE: {
        LABEL: 'Your message*',
        PLACEHOLDER: ''
      },
      PROBLEM: {
        AVAILABLE_OPTIONS: 'Available options',
        LABEL: 'Type of problem encountered *'
      },
      SUBJECT: {
        LABEL: 'Subject of your request*',
        PLACEHOLDER: ''
      },
      TRACKER: {
        LABEL: 'Your request concerns which tracker? ',
        PLACEHOLDER: 'Select the tracker'
      }
    },
    HEADLINE: 'Support',
    HELP_CENTER: 'Help Center',
    HELP_TEXT: 'Need help? Visit our support centre to find the answer to your question. You can also contact us using the form below; our team will answer you within 24 business hours.',
    MAIN_TEXT: 'Our help centre compiles all our articles to answer all your questions.',
    MESSAGES: {
      SUBMIT_ERROR: 'An error has occurred, please try again',
      SUBMIT_SUCCESS: 'Message sent'
    },
    PROBLEM_TYPES: {
      CHARGE: 'My tracker is not charging normally',
      DOWNLOAD_APP: 'I cannot download the application',
      GPS_INVALID: 'My GPS signal is invalid',
      GPS_PRECISION: 'My tracker\'s GPS position is not accurate',
      LEDS_FIXED: 'The two leds of my tracker remain on',
      NO_GEOFENCE_ALERTS: 'I receive false geofence alerts',
      NO_NOTIFICATIONS: 'I do not receive notifications',
      OTHER: 'Other',
      SIGN_IN: 'I cannot login to my account',
      SUBSCRIPTION: 'I have a problem with my subscription',
      TRACKERS_PARAMS: 'I cannot set my the parameters of my tracker',
      TRACKER_NOT_CONNECTED: 'My tracker is shown as not connected'
    },
    SUBMITTED_TICKET: 'We have received your query, thank you.\nA confirmation email has been sent to you (please check your junk mail folder if you don\'t see it in your inbox).',
    SUB_TEXT: 'If these instructions aren\'t sufficient, you can also contact a member of our service team who will reply within 24 working hours.',
    TITLE: 'Need Help ?',
    URGENT_NUMBER: 'If your query is urgent, our Customer Services are available \n+33 (0)187 660 083 from 10 a.m. to 12 noon and from 2 p.m. to 5 p.m. Monday to Friday.'
  },
  trackerLeftPanel: {
    CALL: 'Voice call',
    CTR_ONLY_SECONDS: 'You have {{SECONDS}} second(s) left for call usage in current month.',
    CTR_WITH_MINUTES: 'You have {{MINUTES}} minute(s) and {{SECONDS}} second(s) left for call usage in current month.',
    FLASH: 'Flashlight',
    GOOD_GPS_ACCURACY: 'Good GPS precision',
    LAST_POSITION: 'Last known position:',
    MEDIUM_GPS_ACCURACY: 'Average GPS precision',
    OFFLINE: 'Tracker offline',
    POOR_GPS_ACCURACY: 'Bad GPS precision',
    RING: 'Ringing',
    SMS_AVAILABLE: 'Available SMS : {{SMS_count}}',
    VIBRATE: 'Buzzer'
  },
  trackerSettings: {
    BUTTONS: {
      LABELS: {
        BUTTON: 'Button'
      },
      PLACEHOLDER_BUTTON: 'Type the message to be sent by Alert {{button_number}}',
      TEXT: 'Write a message for each Alert on your locator. You\'ll get the message in your notifications when the button is pressed.'
    },
    CONTACTS: {
      ADD_EMAIL_BUTTON: 'Add an email address',
      ADD_PHONE_BUTTON: 'Add a phone number',
      EMAILS_LABEL: 'Email addresses for email notifications',
      MAIL: {
        PLACEHOLDER: 'votre@email.com'
      },
      MESSAGES: {
        UPDATE_ERROR_UNKNOWN: 'A problem was encountered, please try again',
        UPDATE_SUCCESS: 'Contacts updated '
      },
      NUMBERS_LABEL: 'Phone numbers for SMS notifications',
      PHONE: {
        PLACEHOLDER: '+33600000000'
      }
    },
    DISPLAY: {
      MESSAGES: {
        UPDATE_ERROR_UNKNOWN: 'A problem occurred',
        UPDATE_SUCCESS: 'Settings updated '
      },
      MODAL: {
        HEADLINE: 'Select the icon',
        TEXT: 'You can select the icon you want from the list below. It will represent your tracker on the map.'
      },
      TRACKER_ICON: {
        EDIT_TEXT: 'Change icon',
        LABEL: 'Tracker icon'
      },
      TRACKER_NAME: {
        LABEL: 'Tracker name',
        PLACEHOLDER: 'Name that will appear on the map'
      },
      TRACKER_TYPE: {
        CHANGE: 'Change tracker type',
        MODIFIED_SUCCESS: 'Type of tracker successfully modified'
      }
    },
    GPS_FREQUENCY: {
      BATTERY_USAGE: 'Average battery life:',
      BATTERY_USAGE_LEVELS: {
        HIGH: 'High',
        LOW: 'Low',
        MEDIUM: 'Medium'
      },
      GEOFENCE_NOTIFICATIONS: 'Average response time for geofence alerts:',
      HINT: 'Greater reporting frequency reduces battery life but also improves response times of geofence entry and exit alerts. Conversely, lower reporting frequency improves battery life but reduces the responsiveness of geofence entry and exit alerts.',
      TEXT: 'Choose the GPS reporting time interval'
    },
    NOTIFICATION_SETTINGS: {
      APP: 'Application',
      BATTERY: {
        HINT: 'Sends a notification to all contacts when the tracker reaches a low battery level.',
        TITLE: 'Battery'
      },
      BUTTONS: {
        HINT: 'Sends a notification to all contacts when one of the lateral buttons is pressed. The message configured for this button will be included in the notification.',
        TITLE: 'Lateral buttons'
      },
      GEOFENCES: {
        HINT: 'Sends a notification to all contacts if the tracker enters or leaves a parameterised area.',
        TITLE: 'Safety areas'
      },
      MAIL: 'Email',
      MESSAGES: {
        UPDATE_ERROR_UNKNOWN: 'A problem was encountered, please try again',
        UPDATE_SUCCESS: 'Notification settings have been updated'
      },
      SMS: 'SMS',
      SOS: {
        HINT: 'Sends a notification to all contacts when the SOS button is pressed.',
        TITLE: 'SOS Call'
      },
      WARNING: {
        SMS: {
          LINK: 'Click here to activate it.',
          TEXT: 'Please note, if you wish to receive SMS alerts, you must activate the corresponding option.'
        }
      }
    },
    SECTION_TITLES: {
      BUTTONS: 'Buttons',
      CONTACTS: 'Contacts for notifications',
      DISPLAY: 'General',
      GPS_FREQUENCY: 'Time interval between each GPS position',
      NOTIFICATION_SETTINGS: 'Types of notifications for each alert',
      SOS: 'SOS Number',
      TRAINING: 'Voice call'
    },
    TRAINING_SOS: {
      DIRECT_CALL: 'Call this number for SOS alerts.',
      INPUT_PLACEHOLDER: '+33600000000',
      MESSAGES: {
        UPDATE_ERROR_UNKNOWN: 'A problem was encountered, please try again',
        UPDATE_SUCCESS: 'Phone number updated'
      },
      SOS_TEXT: 'Phone number that will receive calls in case of SOS alert',
      TRAINING_TEXT: 'Enter the default phone number that will be called by the tracker when using the phone function from the application. This number is can be edited for each call request from the application.'
    }
  },
  trackers: {
    ADD_TRACKER_BUTTON: {
      LABEL: 'Add a tracker'
    },
    EXPIRATION: {
      EXPIRED: 'The subscription for {{tracker}} has expired',
      WILL_EXPIRE: 'The subscription for {{tracker}} will expire in {{days}} days'
    },
    LINKS: {
      ACTIVITY: 'Activity monitoring',
      GEOFENCES: 'Zones',
      HISTORY: 'History',
      SETTINGS: 'Parameters',
      SUBSCRIPTION: 'Subscription'
    }
  },
  trackersPositionRefresh: {
    MESSAGES: {
      NOT_REFRESHED: 'Your tracker has been unable to acquire new GPS positions',
      REFRESHING: 'Please wait, a refresh request is in progress.',
      SUCCESS: 'GPS positions of trackers successfully updated'
    }
  },
  transferPage: {
    ADD_TRACKER: 'I want to add a new tracker to my account',
    BACK_TO_TRACKERS: 'Back to the map',
    FIRST_TITLE: 'What do you want to do?',
    FROM_HEADLINE: 'Tracker to replace:',
    HEADLINE: 'Replace a tracker',
    IMEI_LABEL: 'IMEI number',
    INFO: 'The current subscription, GPS location history and settings will be transferred to the new tracker after replacement.',
    MESSAGES: {
      ERROR_UNKNOWN: 'A problem was encountered, please try again',
      SUCCESS: 'New tracker successfully assigned',
      WRONG_TYPE: 'The locator with that IMEI number is not the same type as your original locator. Please get in touch with our helpdesk.'
    },
    NAME_LABEL: 'Tracker name',
    PURCHASE: 'Don\'t have a Weenect tracker yet? <1> You can buy them here </1>',
    REPLACE_BUTTON: {
      LABEL: 'Replace'
    },
    REPLACE_TRACKER: 'I want to replace an existing tracker',
    TO_HEADLINE: 'New tracker:'
  },
  webappDisconnection: {
    BUG: 'Not connected',
    MANUAL: 'You have turned your tracker off. Press the ON button to turn it back on.',
    NO_BATTERY: 'Your tracker has turned off because the battery is exhausted.',
    NO_SUB: 'You have no active subscription for this tracker. Please set one up by going to'
  },
  wifiZones: {
    AIRPLANE_TEXT: 'Warning: in airplane mode, you cannot make your tracker ring or vibrate, or manually refresh its position, while it\'s in the Power saving zone\n.',
    DEEP_SLEEP_TITLE: 'Airplane mode',
    DISPLAY_BUTTON: 'Display the area on the map',
    DISPLAY_TITLE: 'Notifications and display',
    IN_AIRPLANE: '- airplane mode',
    IN_OUT_TEXT: 'Zone entrances and exits :',
    LAST_POS_IN_WIFI: 'In the power-saving area since {{date}} ',
    MAIL_NOTIF: 'Email notifications',
    NOTIF_TEXT: 'Get a notification for each area entry or exit. ',
    POPIN: {
      AIRPLANE: {
        BUTTON_VALIDATE: 'Exit airplane mode',
        CONTENT: 'You can no longer communicate with your tracker while it is in the <span style="color: #333333; font-family: Verdana, sans-serif;"><span>Power saving zone</span></span>. If you want to regain full functionality,<strong> turn off airplane mode and take the tracker out of the zone</strong>.',
        TITLE: 'Your tracker is in airplane mode in the {{wifiName}} power-saving area.'
      },
      BUTTON_CLOSE: 'Do it later',
      BUTTON_VALIDATE: 'Disable the zone',
      CONTENT: '<strong>Automatic relaying of your tracker\'s GPS locations is turned off</strong> in this zone. However, you still can find your tracker by making it ring.<strong> You can also disable the Power saving zone</strong>.',
      TITLE: 'Your tracker is in the {{wifiName}} power-saving area.'
    },
    PUSH_NOTIF: 'Push notifications',
    SMS_NOTIF: 'Text notifications',
    TITLE: 'Power-saving area',
    ZONE_ACTIVATION: 'Enable the area'
  }
}
