import {
  createApi,
  fetchBaseQuery,
  BaseQueryApi,
} from "@reduxjs/toolkit/query/react";
import { RootState } from "../../types/rtk/api-config/types";
import { config as appConfig } from "../../config";

const query = fetchBaseQuery({
  baseUrl: appConfig.REACT_APP_APIV4_BASE_URL,
  prepareHeaders: async (headers, { getState }) => {
    return new Promise((resolve, reject) => {
      let count = 0;
      function checkToken() {
        const auth = (getState() as RootState).user.auth;
        if (auth) {
          headers.set("external-id", auth);
          resolve(headers)
        } else if (count < 10) {
          count++;
          setTimeout(checkToken, 250) // try again in 500ms
        } else {
          reject("Timeout waiting for an auth value");
        }
      }
      checkToken();
    })
  },
});

const baseQuery = async (args: any, api: BaseQueryApi, extraOptions: {}) => {
  let result = await query(args, api, extraOptions);
  return result;
};

export const apiSlice = createApi({
  baseQuery: baseQuery,
  endpoints: () => ({}),
  tagTypes: [],
});
