export const NL = {
  AactivityOnboarding: {
    SUMMARY: {
      ACTIVITY_TEXT: 'Frequentie van uitstapjes'
    }
  },
  HipayPayments: {
    CARDNUMBER: 'Kaartnummer',
    EXPIRYDATE: 'Vervaldatum',
    FULLNAME: 'Volledige naam',
    GOCARDLESS_LINK: 'Betaal per bankoverschrijving'
  },
  Slider: {
    GPS_INTERVAL: 'Tijdsinterval GPS',
    GPS_POSITION_REPORT_ENABLED: 'Updates van GPS-posities geactiveerd',
    TRACKER_INTERVAL_TEXT_WARNING: 'Door de automatische updates van GPS-posities uit te schakelen wordt de levensduur van de batterij verlengt. Je kan handmatig een nieuwe locatie aanvragen, maar de veilige zones en geschiedenis functies werken dan niet meer.'
  },
  TypeSwitch: {
    ANIMAL_SUBTITLE: 'Kies het dier waarvoor je de tracker gekocht hebt:',
    CAT_TYPE: 'Tracker kat',
    DOG_TYPE: 'Hondentracker',
    FAMILY_SUBTITLE: 'Voor wie heb je deze tracker gekocht?Voor wie heb je deze tracker gekocht?',
    KID_TYPE: 'Een kind',
    SILVER_TYPE: 'Een oudere senior',
    TITLE: 'Voor wie is de tracker?'
  },
  account: {
    LANGUAGE: {
      MESSAGES: {
        UPDATE_ERROR_UNKNOWN: 'Er is een probleem opgetreden',
        UPDATE_SUCCESS: 'Taal met succes gewijzigd'
      }
    },
    NAV: {
      LANGUAGE: 'Taal',
      PASSWORD: 'Wachtwoord',
      PAYMENTS: 'Betaling en facturering',
      USER_PREFERENCES: 'Voorkeuren',
      USER_PROFILE: 'Gebruikersprofiel'
    },
    PASSWORD: {
      HEADLINE: 'Wijzigen wachtwoord',
      MESSAGES: {
        UPDATE_ERROR_UNKNOWN: 'Er is een probleem opgetreden',
        UPDATE_ERROR_WRONG_PASSWORD: 'Je hebt een onjuist oud wachtwoord ingevoerd',
        UPDATE_SUCCESS: 'Het wachtwoord is met succes gewijzigd'
      }
    },
    PAYMENTS: {
      HISTORY_HEADLINE: 'Betaalgeschiedenis',
      MAIN_HEADLINE: 'Facturering'
    },
    USER_PREFERENCES: {
      EMAIL_PREFERENCES_HEADLINE: 'E-mail voorkeuren',
      EMAIL_PREFERENCES_USER_INFO: 'De voorkeuren worden toegepast op het e-mailadres {{user_email}}',
      MAIN_HEADLINE: 'Voorkeuren',
      MESSAGES: {
        UPDATE_ERROR_UNKNOWN: 'Er is een probleem opgetreden bij het opslaan van voorkeuren',
        UPDATE_SUCCESS: 'Voorkeuren succesvol bijgewerkt'
      },
      METRIC_PREFERENCES_DESCRIPTION: 'Kies de afstandseenheid die wordt gebruikt voor uw tracker.',
      METRIC_PREFERENCES_HEADLINE: 'Metrisch systeem voorkeur :'
    },
    USER_PROFILE: {
      BASIC_INFO_HEADLINE: 'Basisgegevens',
      BILLING_HEADLINE: 'Factuuradres',
      MAIN_HEADLINE: 'Gebruikersprofiel',
      MARKETING_AGREEMENT_TEXT: 'Ik ga akkoord met het ontvangen van aanbiedingen en promoties van Weenect-partners',
      MESSAGES: {
        UPDATE_ERROR_UNKNOWN: 'Er is een probleem opgetreden',
        UPDATE_SUCCESS: 'Gegevens met succes gewijzigd'
      },
      METRIC_KM: 'Kilometers',
      METRIC_MILES: 'Mijlen',
      PREFERRED_METRIC: 'Metrisch systeem:'
    }
  },
  activity: {
    AVERAGE_SPEED: 'Gemiddelde snelheid',
    CONTROLS: {
      PAUSE_PLAYING_TITLE: 'Pauzeer de geschiedenis film',
      RESUME_PLAYING_TITLE: 'Herstart de geschiedenis film',
      START_PLAYING: 'Start',
      STOP_PLAYING: 'Stoppen',
      STOP_PLAYING_TITLE: 'Stop de geschiedenis film',
      TOGGLE_HEATMAP: 'Thermokaart',
      TOGGLE_HEATMAP_TITLE: 'Geef de thermokaart weer',
      TOGGLE_PATH: 'Spoor van de posities',
      TOGGLE_PATH_TITLE: 'Geef het spoor van de posities weer'
    },
    DETAIL_TITLE: 'Detail van de activiteit',
    EMPTY_LIST: 'Geen alerts om weer te geven. Wijzig de geselecteerde periode of controleer dat de tracer aanstaat.',
    EVENTS: {
      ALERT: 'Laterale knop geactiveerd',
      BATTERY: 'Zwakke batterij - {{battery}}% over',
      CALL_END: 'SOS oproep afgelopen',
      CALL_REQ: 'SOS oproep aangevraagd',
      ENTERS_GEOFENCE: 'Ingaan van de zone',
      EXIT_GEOFENCE: 'Uitgaan van de zone',
      FLASH_REQ: 'Flash aangevraagd',
      FREQ_CHANGE: 'Tijdsinterval gewijzigd',
      RING: 'Bellen uitgevoerd',
      RING_REQ: 'Beltoon gevraagd',
      SOS: 'SOS alarm',
      TRACKER_OFF: 'Tracer uit',
      TRACKER_ON: 'Tracer aan',
      VIBRATE: 'Trillen uitgevoerd',
      VIBRATE_REQ: 'Trilfunctie gevraagd'
    },
    FEEDBACKTEXT: {
      FIRST: '',
      SECOND: ''
    },
    HISTORY_TITLE: 'Meldingengeschiedenis',
    LAST_TIME_PERIOD: 'Geef de laatste weer',
    LOADING: 'Bezig met laden...',
    MAXIMUM_SPEED: 'Maximale snelheid',
    OBJECTIVE: {
      CONTENT: 'Stel een dagelijks te bereiken intensiteitsdoel in.',
      DAILY_REACHED: '{{name}} heeft haar dagelijkse doel bereikt!',
      HELPTEXT: 'Alleen intensieve activiteit wordt in aanmerking genomen bij het meten van het dagelijkse doel. Bijvoorbeeld een rondje hardlopen of een gamesessie.',
      RECOMENDED_VALUE: 'Aanbevolen doel: 1 uur',
      SUBTEXT: 'Ons algoritme registreert elke beweging van uw tracker en deelt de resultaten vervolgens in op intensiteitsniveau. Zo kunnen we bepalen of het gaat om intense activiteit, waakzaamheid of rusttijd.',
      TITLE: 'Dagelijks doel'
    },
    ONBOARDING: {
      FIRST_SUBTEXT: 'Met deze functie kan je de dagelijkse activiteit van jouw viervoeter volgen.',
      SECOND_SUBTEXT: 'Hoe lang heeft hij geslapen? Hoeveel stappen heeft hij vandaag gezet?',
      START: 'Starten!',
      THIRD_SUBTEXT: 'U kunt zelfs een doel instellen op basis van het ras van uw huisdier, zodat uw huisdier de dagelijkse activiteit krijgt die het nodig heeft.',
      TITLE: 'Welkom bij de Weenect activiteitentracker!'
    },
    PROGRESSBAR: {
      TITLE: 'Dagelijks doel'
    },
    SELECT_DAY: 'Kies een weer te geven dag',
    SELECT_TIME: 'Gebruik de schuifknop om het weer te geven tijdsinterval te selecteren',
    SUMMARY: {
      ACTIVITY: 'Intensief',
      AWAKENING: 'Matig',
      DATEINTERVAL: '',
      DAY_INDEX: 'd',
      DISTRIBUTIONTEXT: 'Gedetailleerd verslag',
      HOUR_INDEX: 'u',
      LASTWEEK: '',
      MENU_TITLE: 'Activiteitenverslag',
      MIN_INDEX: 'min',
      MODIFY_PERIOD: 'Periode wijzigen',
      PREVIOUS_DAY: '',
      PREVIOUS_MONTH: '',
      PREVIOUS_WEEK: '',
      REGISTERED_ACTIVITY: 'stappen',
      REPORT: 'Verslag bekijken ',
      REST: 'Laag',
      SUBTEXT: 'Nog {{time}} intensieve activiteit voordat we {{name}}\'s doel bereiken.',
      TEXT_OBJECTIVE_COMPLETED: '{{name}} heeft haar dagelijkse doel bereikt!',
      TEXT_OBJECTIVE_COMPLETED_TREAT: 'Gefeliciteerd, {{name}} heeft haar doel bereikt! Misschien verdient dat een traktatie?',
      TEXT_OBJECTIVE_NONE: '{{name}} heeft geen gegevens voor deze periode',
      TEXT_OBJECTIVE_NONE_VERIFY_TRACKER: '{{name}} heeft nog geen bewegingen geregistreerd. Controleer of uw tracker is ingeschakeld en of de batterij is opgeladen!',
      TITTLE: '{{name}} vooruitgang',
      TODAY: 'Vandaag,',
      TOTAL: 'Totaal',
      YESTERDAY: 'gisteren'
    },
    TIME_ELAPSED: 'Duur',
    TOTAL_DISTANCE: 'Totale afstand'
  },
  activityGeneral: {
    BEHAVIOR: 'Activiteit ',
    STANDARD_MORPHOLOGY: 'Morfologie ',
    STERILIZED: 'Gesteriliseerd'
  },
  activityOnboarding: {
    COMMON: {
      STEP: 'Stap',
      TRACKER_PROFIL: 'Profiel van de tracker'
    },
    MONTH: {
      APRIL: 'April',
      AUGUST: 'Augustus',
      DECEMBER: 'December',
      FEBRUARY: 'Februari',
      JANUARY: 'Januari',
      JULY: 'Juli',
      JUNE: 'Juni',
      MARCH: 'Maart',
      MAY: 'Mei',
      NOVEMBER: 'November',
      OCTOBER: 'Oktober',
      SEPTEMBER: 'September'
    },
    PET_ACTIVITY: {
      FIRST_BUTTON: 'Af en toe (het strikte minimum)',
      FIRST_BUTTON_CAT: 'Uitzonderlijk (vakantie, onvoorziene gebeurtenissen...)',
      FIRST_BUTTON_DOG: 'Alleen voor haar eigen behoeften',
      FOURTH_BUTTON: 'Hyperactief (2 tot 3 wandelingen per dag)',
      FOURTH_BUTTON_CAT: 'Regelmatig, dag en nacht',
      FOURTH_BUTTON_DOG: '2 tot 3 wandelingen/dag',
      SECOND_BUTTON: 'Normaal (2 tot 3 wandelingen per week)',
      SECOND_BUTTON_CAT: 'Van tijd tot tijd, op zijn verzoek',
      SECOND_BUTTON_DOG: '2 tot 3 wandelingen / week',
      SELECT_ACTIVITY: 'Hoe vaak gaat hij uit?',
      THIRD_BUTTON: 'Energiek (één wandeling per dag)',
      THIRD_BUTTON_CAT: 'Regelmatig, gedurende de dag',
      THIRD_BUTTON_DOG: 'Eén wandeling per dag',
      TITLE: 'Hoeveel beweegt hij?'
    },
    PET_AGE: {
      FIRST_COMMON_TEXT: 'jaar en ',
      SECOND_COMMON_TEXT: 'maand(en)',
      TITLE: 'Wat is zijn geboortedatum?'
    },
    PET_GENDER: {
      FEMALE: 'Een vrouwtje',
      FIRST_TITLE: 'Is uw huisdier een vrouwtje of een mannetje?',
      MALE: 'Een mannetje',
      SECOND_TITLE: 'Is uw huisdier gesteriliseerd?'
    },
    PET_HEALTH: {
      LAST_VACINATION: 'Wanneer was zijn laatste vaccinatie?',
      LAST_VET_VISIT: 'Wanneer was zijn laatste bezoek aan de dierenarts?',
      TITLE: 'Gezondheidspunt'
    },
    PET_IDENTIFICATION: {
      CHIP: 'Gechipt',
      NONE: 'Mijn huisdier is niet geïdentificeerd',
      SELECT_IDENTIFICATION: 'Hoe wordt hij geïdentificeerd?',
      TATTOO: 'Getatoeëerd'
    },
    PET_INFO: {
      GET_STARTED: 'Klaar voor de start!',
      NEED_TO_KNOW: 'Het is belangrijk voor ons om {{name}} te leren kennen.',
      PETS_DETAILS: 'Vul de gegevens van uw huisdier in!',
      PETS_GENDER: {
        MIXED_BREED: 'Dit dier is een kruising'
      },
      PET_GENDER_COMMON: 'Je huisdier is...',
      SECOND_PETS_DETAILS: 'Vul de gegevens van uw huisdier in'
    },
    PET_MORPH: {
      AVERAGE: 'Normaal',
      COMMON_TEXT: 'Ongeveer',
      FIRST_TITLE: 'Wat is de lichaamsvorm?',
      HELP_TEXT: 'Hoe bepaal ik de morfologie van mijn huisdier?',
      LEAN: 'Mager',
      OBESE: 'Zwaarlijvig',
      OVERWEIGHT: 'Overgewicht',
      SECOND_TITLE: 'Wat is zijn gewicht?',
      WEIGH_TEXT: 'Wat is het gewicht van uw huisdier?'
    },
    PET_NAME: {
      COMMON_TEXT: 'We zouden graag kennis willen maken met uw huisdier! \u2028U kunt de gegevens daarna wijzigen in de instellingen. ',
      PLACEHOLDER: 'Naam van uw huisdier',
      TITLE: 'Hoe heet hij?'
    },
    PET_RACE: {
      COMMON_TEXT: 'Ik ken het ras niet',
      PLACEHOLDER: 'Ras van de hond',
      SELECT_BREED: 'Selecteer het dierenras',
      TITLE: 'Wat is het ras?'
    },
    PET_TYPE: {
      CAT: 'En kat',
      DOG: 'Een hond'
    },
    SUMMARY: {
      BIRTH_DATE: 'geboortedatum',
      BREED: 'Ras',
      CONDITION: 'Status',
      DAILY_EXERCISE: 'Dagelijkse inspanning',
      DELETE: 'Resetten het profiel',
      HEALTH: 'Gezondheid',
      IDENTIFICATION: 'Identificatie',
      MODAL: {
        NO: 'Nee, annuleren',
        SUBTEXT: 'U zult dan het proces helemaal opnieuw moeten beginnen.',
        TITLE: 'Weet u zeker dat u de gegevens van uw huisdier opnieuw wilt instellen?',
        YES: 'Ja, dat weet ik zeker'
      },
      MORPHOLOGY: 'Lichaamsbouw',
      NAME: 'Naam',
      RECAPTEXT: 'Dit is de informatie over uw metgezel.',
      SEX: 'Geslacht',
      SPECIES: 'Ras',
      SUBMIT: 'Het profiel bevestigen',
      UNSPECIFIED: 'lege geboortedatum',
      VALIDATION: 'Bevestiging van profiel',
      VALIDATION_TEXT: 'Gelieve uw informatie te bevestigen voordat u verder gaat. ',
      WEIGHT: 'Gewicht'
    }
  },
  activityTracking: {
    ACTIVITY: 'Punten van activiteit',
    ACTIVITY_DISTRIBUTION: {
      DATA_COMPARATOR: {
        ACTIVITY_INTENSE: 'Intensieve activiteit',
        ACTIVITY_INTENSE_LEGEND: 'intensieve activiteit op {{day}}',
        ACTIVITY_MODERATE: 'Matige activiteit',
        ACTIVITY_MODERATE_LEGEND: 'matige activiteit op {{day}}',
        ACTIVITY_WEAK: 'Lage activiteit',
        ACTIVITY_WEAK_LEGEND: 'lage activiteit op {{day}}',
        TEXT: '{{petName}} had gisteren {{comparator}} {{activity}} activiteit dan {{day}}.'
      }
    },
    ACTIVITY_REPORT: {
      DATA_COMPARATOR: {
        LEGEND: {
          KM_MONTHLY: 'kilometer gemiddeld per dag deze maand',
          KM_WEEKLY: 'kilometer gemiddeld per dag deze week',
          KM_YESTERDAY: 'kilometer afgelegd gisteren',
          STEP_MONTHLY: 'stappen gemiddeld per dag deze maand',
          STEP_WEEKLY: 'stappen gemiddeld per dag deze week',
          STEP_YESTERDAY: 'stappen gezet gisteren'
        },
        TEXT_INF: 'minder',
        TEXT_KM_DAILY: 'Het aantal afgelegde kilometers gisteren door {{petName}} is {{comparator}} vergeleken met eergisteren.',
        TEXT_KM_MONTHLY: 'Het gemiddelde aantal afgelegde kilometers deze week door {{petName}} is {{comparator}} vergeleken met deze maand.',
        TEXT_KM_WEEKLY: 'Het aantal kilometers dat gisteren door {{petName}} is afgelegd, is {{comparator}} vergeleken met het wekelijkse gemiddelde.',
        TEXT_SIM: 'vergelijkbaar',
        TEXT_STEP_DAILY: 'Het aantal stappen gisteren door {{petName}} is {{comparator}} vergeleken met eergisteren.',
        TEXT_STEP_MONTHLY: 'Het gemiddelde aantal stappen dat {{petName}} deze week heeft gezet, is {{comparator}} deze maand.',
        TEXT_STEP_WEEKLY: 'Het aantal stappen dat {{petName}} gisteren heeft gezet, is {{comparator}} dan zijn wekelijkse gemiddelde.',
        TEXT_SUP: 'meer',
        TITLE_KM_TRAVELED: 'Afgelegde kilometers',
        TITLE_STEP: 'Genomen stappen'
      },
      KM_TRAVELED: '{{step}} afgelegde kilometers'
    },
    AIMED_GOAL: {
      ALERT: 'We raden een dagelijks doel van minimaal één uur aan om uw huisdier gezond te houden.',
      TITLE: 'Stel een dagelijks te bereiken activiteitsdoel in.'
    },
    DAILY_CHART: 'Activiteit van {{petName}}',
    DAILY_GOAL: {
      NOT_RECOMMENDED: 'Afgeraden doel',
      RECOMMENDED: 'Aanbevolen doel',
      SECOND_TITLE: 'Dagelijks doel',
      TITLE: 'Dagelijks doel'
    },
    DAILY_STEP: 'Dagelijkse stappen',
    DAYS: 'Dag',
    EQUAL: 'dezelfde',
    ERROR_PAGE: {
      BUTTON_TEXT: 'Sluit de pagina',
      TEXT: 'We kunnen de pagina momenteel niet laden. Probeer het later opnieuw.',
      TITLE: 'Oeps, we hebben een probleem ondervonden...'
    },
    HEADER: {
      ACTIVITY_DISTRIBUTION: {
        TITLE: 'Verdeling van de activiteit'
      },
      ACTIVITY_REPORT: {
        TITLE: 'Activiteitsrapporten'
      },
      GOAL: {
        TITLE: 'Dagelijks doel'
      }
    },
    HOME: {
      ACTIVITY_DISTRIBUTION: {
        INTENSE: 'Intensief',
        MODERATE: 'Matig',
        WEAK: 'Laag'
      },
      ALERT: 'Een paar dagen leren is nodig om betrouwbare gegevens te garanderen. Gedurende deze periode verzamelt en analyseert het algoritme uw gegevens om de activiteitsgewoonten en -patronen van uw huisdier te begrijpen. Na verloop van tijd worden de resultaten steeds nauwkeuriger en aangepast aan zijn profiel.',
      FIRST_SECTION: {
        TITLE: 'Dagelijks doel'
      },
      INSURANCE: {
        BUTTON: 'Ontdek Weenect Verzekering',
        TEXT: 'Een bezoek aan de dierenarts is nodig. Vertrouw op Weenect voor de vergoeding van uw consult.',
        TITLE: 'Twijfelt u aan de gezondheid van {{petName}}?'
      },
      MODIFY: 'Wijzigen',
      PROFILE: {
        SUBTITLE: 'Controleer zijn dagelijkse activiteit om hem in vorm te houden.'
      },
      SECOND_SECTION: {
        SUBTITLE: 'Gedurende de huidige dag.',
        TITLE: 'Verdeling'
      },
      SEE_MORE: 'Meer zien',
      THIRD_SECTION: {
        TITLE: 'Belangrijke punten'
      }
    },
    HOUR: '{{count}} h',
    HOURS: 'Uren',
    INTERVAL: {
      TEXT: 'Verschuif de randen van de grafiek om de weergegeven periode te veranderen.',
      TITLE: 'Periode selectie'
    },
    KEY_POINTS: 'Belangrijke punten',
    KILOMETERS: 'Kilometer',
    LESS: 'een korter',
    MINUTE_ACTIVITY_one: '{{count}} minuut activiteit',
    MINUTE_ACTIVITY_other: '{{count}} minuten activiteit',
    MINUTE_one: '{{count}} minuut',
    MINUTE_other: '{{count}} minuten',
    MIN_one: '{{count}} min',
    MIN_other: '{{count}} min',
    MONTHLY_STEP: 'Maandelijkse stappen',
    ONBOARDING: {
      FIRST_STEP: {
        ALERT: 'In de vliegtuigmodus wordt zijn activiteit ook geregistreerd. Deze verschijnt in het dashboard zodra je de wifi-zone verlaat.',
        BUTTON: 'Starten',
        MESSAGE: 'Met deze functie kan je de dagelijkse activiteit van jouw viervoeter volgen. Hoe lang heeft hij geslapen? Hoeveel stappen heeft hij vandaag gewandeld? Jij komt alles over hem te weten!',
        TITLE: 'Welkom bij de activiteitstracker van jouw huisdier!'
      },
      SECONDE_STEP: {
        ALERT: 'We raden een dagelijks doel van minimaal één uur aan om uw huisdier gezond te houden.',
        BUTTON: 'Opslaan',
        PROFILE: {
          SUBTITLE: 'Controleer zijn dagelijkse activiteit om hem in vorm te houden.',
          TITLE: 'Activiteit van {{petName}}'
        },
        TITLE: 'Stel een dagelijks te bereiken activiteitsdoel in.'
      }
    },
    PERIOD: {
      DAY: 'Dag',
      MONTH: 'Maand ',
      TITLE: 'Periode selectie',
      WEEK: 'Week',
      YEAR: 'Jaar'
    },
    PLUS: 'een langer',
    RETURN_HOME: 'Terug naar de startpagina',
    STEP: 'Stappen',
    STEP_SAVED: '{{step}} stappen',
    TEXT: 'Deze grafiek laat u de voortgang zien van de activiteit die uw dier heeft vertoond. Het meten van die activiteit vindt plaats op een interval die bij 0 begint en die geen maximumgrens heeft. Elk punt van activiteit stelt een versnelling voor, dat wil zeggen een beweging van uw dier.',
    TOTAL: 'Totaal',
    WEEKDAYS: {
      FRIDAY: 'Vrijdag',
      FRIDAY_COMPARATIVE: 'vrijdag',
      MONDAY: 'Maandag',
      MONDAY_COMPARATIVE: 'el lunes',
      SATURDAY: 'Zaterdag',
      SATURDAY_COMPARATIVE: 'zaterdag',
      SUNDAY: 'Zondag',
      SUNDAY_COMPARATIVE: 'zondag',
      THURSDAY: 'Donderdag',
      THURSDAY_COMPARATIVE: 'donderdag',
      TUESDAY: 'Dinsdag',
      TUESDAY_COMPARATIVE: 'dinsdag',
      WEDNESDAY: 'Woensdag',
      WEDNESDAY_COMPARATIVE: 'woensdag'
    },
    WEEKLY_STEP: 'Wekelijkse stappen',
    YESTERDAY: 'gisteren'
  },
  alertCustomer: {
    BODY0: 'Op dit moment is er onderhoud aan de gang bij onze SIM-kaartprovider.',
    BODY1: '<1>Tijdens dit onderhoud wordt de activeringstijd van de SIM-kaart verlengd tot 24 uur</1>.',
    BODY2: 'We zijn ons terdege bewust van het ongemak dat dit met zich meebrengt.\n\nVoor meer informatie kunt u contact opnemen met ons team op <1>family@weenect.com</1> of telefonisch op +33.1.87.66.00.83.',
    FOOTER: 'Het team van Weenect'
  },
  animalInfo: {
    LANDING_PAGE: {
      BUTTON: 'Starten',
      TEXT: 'Vertel ons meer over jouw viervoeter. Je kan deze informatie vervolgens in de instellingen wijzigen.',
      TITLE: 'Voer de informatie van uw huisdier in!',
      WARNING: 'Deze informatie is noodzakelijk voor de meest nauwkeurige activiteiten tracking en om uw Weenect ervaring te personaliseren.'
    },
    NEXT_BUTTON: 'Volgende',
    PROFIL_VALIDATION: {
      ACTIVITY: 'Activiteit en gedrag',
      BUTTON: 'Valideer het profiel',
      INDENTIFICATION_INFOS: 'Identificatie informatie',
      MEDICAL_HISTORY: 'Medische geschiedenis',
      PERSONAL_INFOS: 'Persoonlijke informatie',
      PHYSICAL: 'Fysieke kenmerken',
      SEX: 'Geslacht',
      SPECIES: 'Soort',
      TITLE: 'Profielvalidatie',
      UNIQUE_IDENTIFICATION: 'Unieke identificatie'
    },
    QUESTION: 'Vraag',
    QUESTION_EIGHT: {
      NO: 'Nee',
      NOT_STERILIZED: 'Niet gesteriliseerd',
      QUESTION: 'Is je dier gesteriliseerd?',
      STERILIZATION_STATUS: 'Sterilisatiestatus',
      STERILIZED: 'Gesteriliseerd',
      YES: 'Ja'
    },
    QUESTION_ELEVEN: {
      EVERYTIME: 'Vaak',
      EXIT_FREQUENCY: 'Frequentie van uitlaten',
      MOSTLY: 'Af en toe',
      OFTEN: 'Regelmatig',
      QUESTION: 'Hoe vaak gaat jouw dier naar buiten?',
      RARELY: 'Weinig'
    },
    QUESTION_FIVE: {
      QUESTION: 'Hoeveel weegt je huisdier?',
      WEIGHT: 'Gewicht'
    },
    QUESTION_FOUR: {
      FEMALE: 'Vrouwtje',
      MALE: 'Mannetje',
      QUESTION: 'Wat is het geslacht van uw huisdier?'
    },
    QUESTION_NINE: {
      LAST_VISIT: 'Laatste bezoek',
      QUESTION: 'Wanneer was zijn laatste bezoek aan de dierenarts?'
    },
    QUESTION_ONE: {
      CAT: 'Een kat',
      DOG: 'Een hond',
      QUESTION: 'Wat is het profiel van uw huisdier?'
    },
    QUESTION_SEVEN: {
      CHIPED: 'Chip',
      IDENTIFICATION: 'Identificatie',
      NOT_IDENTIFIED: 'Mijn huisdier is niet geïdentificeerd',
      QUESTION: 'Hoe is jouw huisdier geïdentificeerd?',
      TATTOED: 'Tatoeage'
    },
    QUESTION_SIX: {
      QUESTION: 'Wat is het ras van uw huisdier?',
      RACE: 'Ras'
    },
    QUESTION_TEN: {
      LAST_VACCIN: 'Laatste vaccinatie',
      QUESTION: 'En zijn laatste vaccinatie?'
    },
    QUESTION_THREE: {
      BIRTHDATE: 'Geboortedatum',
      QUESTION: 'Wat is zijn geboortedatum?'
    },
    QUESTION_TWO: {
      NAME: 'Naam',
      QUESTION: 'Hoe heet het?'
    }
  },
  breed: {
    CAT: {
      ABYSSINIAN: 'Abessijn',
      AMERICAN_BOBTAIL: 'Amerikaanse bobtail',
      AMERICAN_CURL: 'Amerikaanse krul',
      AMERICAN_SHORTHAIR: 'Amerikaanse Korthaar',
      AMERICAN_WIREHAIR: 'Amerikaans ruwhaar',
      BALINESE: 'Balinees',
      BENGAL: 'Bengalen',
      BIRMAN: 'heilige kat van Birma',
      BOMBAY: 'Bombay',
      BURMESE: 'Birmees',
      BURMILLA: 'Burmilla',
      CALIFORNIA_SPANGLED_CAT: 'Californië Spangled Cat',
      CHANTILLYTIFFANY: 'Tiffany',
      CHARTREUX: 'Chartreux',
      CORNISH_REX: 'Cornish Rex',
      CYMRIC: 'Cymric',
      DEVON_REX: 'Devon Rex',
      EGYPTIAN_MAU: 'Egyptische Mau',
      EUROPEAN: 'Europese',
      EXOTIC_SHORTHAIR: 'Exotische korthaar',
      HAVANA: 'Havanna',
      JAPANESE_BOBTAIL: 'Japanse Bobtail',
      JAVANESE: 'Javaans',
      KORAT: 'Korat',
      MAINE_COON: 'Maine coon',
      MALAYAN: 'Malayan',
      MANX: 'Manx',
      MUNCHKIN: 'Munchkin',
      NEBELUNG: 'Nebelung',
      NORWEGIAN: 'Noors',
      NORWEGIAN_FOREST_CAT: 'noorse boskat',
      OCICAT: 'Ocicat',
      ORIENTAL_LONGHAIR: 'Mandarijn',
      ORIENTAL_SHORTHAIR: 'Oosters',
      PERSIAN: 'Perzisch',
      RAGDOLL: 'Ragdoll',
      RUSSIAN_BLUE: 'Russisch blauw',
      SAFARI_CAT: 'safari kat',
      SCOTTISH_FOLD: 'Scottish Fold',
      SELKIRK: 'Selkirk',
      SIAMESE: 'Siamees',
      SIBERIAN: 'Siberisch',
      SINGAPURA: 'Singapura',
      SNOWSHOE: 'Snowshoe',
      SOMALI: 'Somalisch',
      SPHYNX: 'Sphynx',
      THAI: 'Thais',
      TONKINESE: 'Tonkinees',
      TURKISH_ANGORA: 'turkse angora',
      TURKISH_VAN: 'Turkse Vans'
    },
    DOG: {
      AFFENPINSCHER: 'Affenpinscher',
      AFGHAN_HOUND: 'Afghaanse windhond',
      AIREDALE_TERRIER: 'Airedale terriër',
      AKITA: 'Akita Inu',
      AKITA_INU_GREAT_JAPANESE_DOG: 'Grote Japanse Hond',
      ALASKAN_MALAMUTE: 'Alaskan Malamute',
      ALPINE_DACHSBRACKE: 'Alpine basset',
      AMERICAN_BULLDOG: 'Amerikaanse Bulldog',
      AMERICAN_COCKER_SPANIEL: 'Amerikaanse Cocker-spaniël',
      AMERICAN_FOXHOUND: 'Amerikaanse jachthond',
      AMERICAN_STAFFORDSHIRE_TERRIER: 'Amerikaanse Staffordshire Terriër',
      AMERICAN_WATER_SPANIEL: 'Amerikaanse waterhond',
      ANATOLIAN_SHEPHERD_DOG: 'Anatolische herder',
      APPENZELLER_SENNENHUND: 'Appenzeller Sennenhond',
      ARDENNES_CATTLE_DOG: 'Bouvier des Ardennen',
      ARGENTINE_DOGO: 'Dogo Argentino',
      ARIEGEOIS: 'Ariegeois',
      ARIEGE_POINTER: 'Ariège Braque',
      ARTOIS_HOUND: 'Artois hond',
      ATLAS_SHEPHERD_DOG_AIDI: 'Atlashond (Aïdi - Atlasherder)',
      AUSTRALIAN_CATTLE_DOG: 'Australische berghond',
      AUSTRALIAN_KELPIE: 'Australische Herder Kelpie',
      AUSTRALIAN_SHEPHERD: 'Amerikaanse Australische Herder',
      AUSTRALIAN_TERRIER: 'Australische terriër',
      AUSTRIAN_BLACK_AND_TAN_HOUND: 'Oostenrijkse Black and Tan Hound',
      AUSTRIAN_SHORTHAIRED_PINSCHER: 'Oostenrijkse Korthaar Pinscher',
      AZAWAKH: 'Azawakh',
      BALKAN_HOUND: 'Balkanhond',
      BARBET: 'Barbet',
      BASENJI: 'Basenji',
      BASSET_ARTESIEN_NORMAND: 'Basset Artesien Normand',
      BASSET_BLEU_DE_GASCOGNE: 'Basset Bleu van Gascogne',
      BASSET_FAUVE_DE_BRETAGNE: 'Basset Fauve de Bretagne',
      BASSET_HOUND: 'Basset-hond',
      BAVARIAN_MOUNTAIN_SCENTHOUND: 'Beierse rode hond',
      BEAGLE: 'Beagle',
      BEARDED_COLLIE: 'Bearded Colley',
      BEAUCERON: 'Beau Herder',
      BEDLINGTON_TERRIER: 'Bedlington terriër',
      BELGIAN_GRIFFON: 'Belgische Griffioen',
      BELGIAN_SHEPHERD_DOG_GROENENDAEL: 'Belgische Herder Groenendaeler',
      BELGIAN_SHEPHERD_DOG_LAEKENOIS: 'Belgische Herder Laekense herder',
      BELGIAN_SHEPHERD_DOG_MALINOIS: 'Belgische Herder Mechelaar',
      BELGIAN_SHEPHERD_DOG_TERVUREN: 'Belgische Herder Tervuren',
      BERGAMASCO_SHEPHERD: 'Bergamo Herder',
      BERNER_NIEDERLAUFHUND: 'Kleine Bernerhond',
      BERNESE_HOUND: 'Berner Hond',
      BERNESE_MOUNTAIN_DOG: 'Berner Sennenhond',
      BICHON_FRISE: 'Bichon Frisé',
      BILLY_DOG: 'Billy',
      BLACK_AND_TAN_COONHOUND: 'Black and Tan Coonhound',
      BLOODHOUND_SAINTHUBERT_DOG: 'Sint-Hubertus',
      BLUE_PICARDY_SPANIEL: 'Blauwe Picard-spaniël',
      BOHEMIAN_WIREHAIRED_POINTING_GRIFFON: 'Tsjechische wijzende Griffon',
      BOLOGNESE_DOG: 'Bichon Bolonais',
      BORDEAUX_MASTIFF_FRENCH_MASTIFF: 'Mastiff van Bordeaux',
      BORDER_COLLIE: 'Border Collie',
      BORDER_TERRIER: 'Border Terrier',
      BORZOI: 'Borzoi (Russische windhond)',
      BOSNIAN_COARSEHAIRED_HOUND: 'Bosnische hond',
      BOSTON_TERRIER: 'Boston terrier',
      BOURBONNAIS_POINTER: 'Braque du Bourbonnais',
      BOUVIER_DES_FLANDRES: 'Bouvier des Flandres',
      BOXER_DOG: 'Bokser',
      BRAQUE_D_AUVERGNE: 'Braque d\'Auvergne',
      BRAZILIAN_TERRIER: 'Braziliaanse terriër',
      BRIARD: 'Brie Herder',
      BRIQUET_GRIFFON_VENDEEN: 'Griffon Vendeen',
      BRITTANY_SPANIEL: 'Bretonse Spaniël',
      BROHOLMER: 'Broholmer',
      BRUNO_JURA_HOUND: 'Kleine hond van Jura',
      BRUNO_JURA_HOUND_BRUNO_TYPE: 'Bruno uit de Jura type Bruno',
      BRUNO_JURA_HOUND_ST_HUBERT_TYPE: 'Bruno uit de Jura type Saint Hubert',
      BRUSSELS_GRIFFON: 'Brussel Griffioen',
      BULLMASTIFF: 'Bullmastiff',
      BULL_TERRIER_ENGLISH_BULL_TERRIER: 'Bull Terriër (Engelse Bull Terriër)',
      BURGOS_POINTER: 'Burgos Braque',
      CAIRN_TERRIER: 'Cairn Terriër',
      CANAAN_DOG: 'Kanaan hond',
      CANARY_ISLAND_PODENCO: 'Podenco Canario',
      CANE_CORSO: 'Cane Corso',
      CAO_DE_CASTRO_LABOREIRO: 'Cao de Castro Laboreiro',
      CAO_FILA_DE_SAO_MIGUEL: 'Cao Fila uit São Miguel',
      CATAHOULA_LEOPARD_DOG: 'Catahoula-luipaardhond',
      CATALAN_SHEEPDOG: 'Catalaanse herder',
      CAUCASIAN_SHEPHERD_DOG: 'Kaukasische herder',
      CAVALIER_KING_CHARLES_SPANIEL: 'Cavalier King Charles',
      CA_DE_BOU_PERRO_DE_PRESA: 'Perro de Presa Mallorquin',
      CENTRAL_ASIAN_SHEPHERD_DOG: 'Centraal-Aziatische herder',
      CESKY_TERRIER: 'Tsjechische terriër',
      CHESAPEAKE_BAY_RETRIEVER: 'Chesapeake Bay Retriever',
      CHIHUAHUA: 'Chihuahua',
      CHINESE_CRESTED_DOG: 'Chinese Crested hond',
      CHOW_CHOW: 'Chow-chow',
      CIRNECO_DELL_ETNA: 'Cirneco van de Etna',
      CLUMBER_SPANIEL: 'Clumber',
      CONTINENTAL_TOY_SPANIEL: 'Continentale speelgoedspaniël',
      CORSICAN_DOG: 'Corsicaanse hond',
      COTON_DE_TULEAR: 'Katoen Tuléar',
      CROATIAN_SHEEPDOG: 'Kroatische Herder',
      CURLYCOATED_RETRIEVER: 'Gekrulde retriever',
      CZECHOSLOVAKIAN_WOLFDOG: 'Tsjechische Wolfhond',
      DACHSHUND_LONGHAIRED: 'Langharige teckel',
      DACHSHUND_LONGHAIRED_RABBIT: 'Konijn Jagende Teckel (langharig)',
      DACHSHUND_SMOOTHHAIRED: 'Kortharige teckel',
      DACHSHUND_SMOOTHHAIRED_RABBIT: 'Rabbit Hunting Teckel (kort haar)',
      DACHSHUND_WIREHAIRED: 'Ruwharige teckel',
      DACHSHUND_WIREHAIRED_RABBIT: 'Rabbit Hunting Teckel (harde vacht)',
      DALMATIAN: 'Dalmatiër',
      DANDIE_DINMONT_TERRIER: 'Dandie Dinmont Terriër',
      DOBERMAN_PINSCHER: 'Dobermann',
      DOGO_CANARIO: 'Dogo Canario',
      DOGUE_DE_BORDEAUX: 'Leeuw van Occitanië',
      DRENTSE_PARTRIDGE_DOG: 'Drentse patrijsspaniël',
      DREVER: 'Drever',
      DUNKER: 'Dunker',
      DUPUY_POINTER: 'Dupuy Braque',
      DUTCH_SHEPHERD_LONGHAIRED_VARIETY: 'Nederlandse Langharige herder',
      DUTCH_SHEPHERD_ROUGHHAIRED_VARIETY: 'Nederlandse Ruwharige Herder',
      DUTCH_SHEPHERD_SHORTHAIRED_VARIETY: 'Nederlandse Kortharige Herder',
      DUTCH_SMOUSHOND: 'Kleine Hollandse Jachthond',
      EAST_SIBERIAN_LAIKA: 'Oost-Siberische Laika',
      ENGLISH_BULLDOG: 'Engelse bulldog',
      ENGLISH_COCKER_SPANIEL: 'Cocker spaniel',
      ENGLISH_POINTER: 'Wijzer Engels',
      ENGLISH_SETTER: 'Engelse Setter',
      ENGLISH_SPRINGER_SPANIEL: 'Engelse springerspaniël',
      ENGLISH_TOY_TERRIER: 'Black and Tan Toy Terriër',
      ENTLEBUCHER_MOUNTAIN_DOG: 'Entlebuch Sennenhond',
      EURASIER: 'Euraziër',
      FAWN_BRITTANY_BASSET: 'Fauve de Bretagne Griffon',
      FIELD_SPANIEL: 'Veldspaniël',
      FILA_BRASILEIRO: 'Fila Brasiliro',
      FINNISH_HOUND: 'Finse hond',
      FINNISH_LAPPHUND: 'Finse Laplandhond',
      FINNISH_SPITZ: 'Finse spits',
      FLATCOATED_RETRIEVER: 'Flatcoated retriever',
      FOXHOUND_ENGLISH_FOXHOUND: 'Foxhound (Engelse jachthond)',
      FRENCH_BULLDOG: 'Franse bulldog',
      FRENCH_SPANIEL: 'Franse spaniël',
      FRENCH_WHITE_AND_BLACK_HOUND: 'Français Blanc et Noir',
      FRENCH_WHITE_AND_ORANGE_HOUND: 'Français Blanc et Orange',
      FRISIAN_WATER_DOG: 'Friese Waterhond',
      GASCONY_POINTER: 'Franse Braque Gascogne',
      GERMAN_LONGHAIRED_POINTER: 'Duitse langharige staande hond',
      GERMAN_PINSCHER: 'Middelgrote Pinscher',
      GERMAN_SHEPHERD: 'Duitse herder',
      GERMAN_SHORTHAIRED_POINTER: 'Duitse kortharige Braque',
      GERMAN_SPITZ: 'Duitse spits (groot)',
      GERMAN_WIREHAIRED_POINTER: 'Duitse ruwharige Braque',
      GIANT_SCHNAUZER: 'Reuzenschnauzer',
      GLEN_OF_IMAAL_TERRIER: 'Glen of Imaal Ierse terriër',
      GOLDEN_RETRIEVER: 'Golden retriever',
      GORDON_SETTER: 'Gordon Zetter',
      GOTH_SHEPHERD_DOG: 'Hond van de Goten',
      GRAND_ANGLOFRANCAIS_BLANC_ET_NOIR: 'Grand Anglo Français Blanc et Noir',
      GRAND_ANGLOFRANCAIS_BLANC_ET_ORANGE: 'Grand Anglo Français Blanc et Noir',
      GRAND_ANGLOFRANCAIS_TRICOLORE: 'Grand Anglo Français Blanc et Noir',
      GRAND_BASSET_GRIFFON_VENDEEN: 'Grand Basset Griffon Vendeen',
      GRAND_BLEU_DE_GASCOGNE: 'Grand Bleu de Gascogne',
      GRAND_GASCONSAINTONGEOIS_HOUND: 'Grote Gascogne Saintongeois',
      GRAND_GRIFFON_VENDEEN: 'Grand Griffon Vendéen',
      GRAND_SPANISH_HOUND: 'Spaanse Hond (groot)',
      GREATER_SWISS_MOUNTAIN_DOG: 'Grote Zwitserse Sennenhond',
      GREAT_DANE: 'Duitse Mastiff',
      GREAT_PYRENEES: 'Pyreneeën berg',
      GREENLAND_DOG: 'Groenlandse Eskimo',
      GREYHOUND: 'Windhond',
      HALDEN_HOUND: 'Halden-hond',
      HAMILTONSTOVARE: 'Hamilton Stovare',
      HANOVERIAN_SCENTHOUND: 'Rode Hannoveraanse hond',
      HANOVER_HOUND: 'Duitse kortharige hond',
      HARRIER: 'Beagle Harrier',
      HAVANESE_DOG: 'Havanezer',
      HELLENIC_HOUND: 'Griekse hond',
      HOKKAIDO: 'Hokkaido',
      HOVAWART: 'Hovawart',
      HUNGARIAN_GREYHOUND: 'Hongaarse Windhond',
      HUNGARIAN_KUVASZ: 'Kuvasz (Hongaarse Herder)',
      HYGEN_HOUND: 'Hygen-hond',
      IBIZAN_HOUND_SHORT_HAIRED: 'Podenco Ibicenco de pelo corto',
      IBIZAN_HOUND_WIRE_HAIRED: 'Podenco Ibicenco de pelo duro',
      ICELANDIC_SHEEPDOG: 'IJslandse Herder',
      IRISH_RED_AND_WHITE_SETTER: 'Rode Ierse Setter',
      IRISH_SETTER: 'Ierse rode en witte setter',
      IRISH_TERRIER: 'Ierse terriër',
      IRISH_WATER_SPANIEL: 'Ierse waterhond',
      IRISH_WOLFHOUND: 'Ierse windhond',
      ISTRIAN_COARSEHAIRED_HOUND: 'Ruwharige Istrische Hond',
      ISTRIAN_SHORTHAIRED_HOUND: 'Kortharige Istrische Hond',
      ITALIAN_GREYHOUND: 'Italiaanse windhond',
      ITALIAN_POINTING_DOG: 'Italiaanse Braque',
      ITALIAN_SHORTHAIRED_HOUND: 'Italiaanse kortharige hond',
      ITALIAN_SPITZ: 'Italiaanse spits',
      ITALIAN_WIREHAIRED_HOUND: 'Italiaanse ruwharige hond',
      JACK_RUSSELL_TERRIER: 'Jack Russell Terriër',
      JAGDTERRIER: 'Jagd Terriër',
      JAPANESE_SPANIEL_CHIN: 'Japanse spaniël',
      JAPANESE_SPITZ: 'Japanse spits',
      JAPANESE_TERRIER: 'Japanse terriër',
      KAI_KEN: 'Kai',
      KARELIAN_BEAR_DOG: 'Karelische berenhond',
      KARST_SHEPHERD: 'Krast Herder',
      KERRY_BLUE_TERRIER: 'Kerry Blue Terriër',
      KISHU_KEN: 'Kishu',
      KOMONDOR: 'Komondor',
      KOREAN_JINDO_DOG: 'Korea Jindo Dog',
      KROMFOHRLANDER: 'Kromfohrlander',
      KYILEO: 'Kyi Leo',
      LABRADOR: 'Labrador',
      LAKELAND_TERRIER: 'Lakeland terriër',
      LANDSEER: 'Landseer',
      LAPPONIAN_HERDER: 'Finse Laplandse Herder',
      LARGE_MUNSTERLANDER: 'Grote Munsterlander',
      LEONBERGER: 'Leonberger (Leeuw)',
      LHASA_APSO: 'Lhasa Apso',
      LOWCHEN_LITTLE_LION_DOG: 'Kleine leeuwenhond',
      LUCERNE_HOUND: 'Luzern hond',
      LUZERNER_LAUFHUND: 'Luzern hond',
      MAJORCA_SHEPHERD_DOG: 'Mallorcaanse Spaanse herder',
      MALTESE_DOG: 'Maltees',
      MANCHESTER_TERRIER: 'Manchester terriër',
      MAREMMA_SHEEPDOG: 'Maremma en Abruzzo Herder',
      MASTIFF_FRENCH_MASTIFF: 'Mastiff geregistreerd LOF',
      MEDIUM_POODLE: 'Poedel (Medium)',
      MEXICAN_HAIRLESS_DOG: 'Mexico-hond met blote huid',
      MINIATURE_BULL_TERRIER: 'Miniatuur Bull Terriër',
      MINIATURE_DACHSHUND_LONGHAIRED: 'Langharige dwergteckel',
      MINIATURE_DACHSHUND_SMOOTHHAIRED: 'Kortharige miniatuur teckel',
      MINIATURE_DACHSHUND_WIREHAIRED: 'Ruwharige dwerg teckel',
      MINIATURE_PINSCHER: 'Dwergpinscher',
      MINIATURE_POODLE: 'Poedel (Dwerg)',
      MINIATURE_SCHNAUZER: 'Dwergschnauzer',
      MIXED_BREED_CROSSBREED: 'Kruising',
      MUDI: 'Mudi (Hongaarse Herder)',
      NEAPOLITAN_MASTIFF: 'Mastiff van Napels',
      NEWFOUNDLAND: 'Newfoundland',
      NORFOLK_TERRIER: 'Norfolk Terriër',
      NORRBOTTENSPETS: 'Norbotten Spitz',
      NORWEGIAN_BUHUND: 'Noorse Buhund',
      NORWEGIAN_ELKHOUND_BLACK: 'Zwarte Noorse Elandhond',
      NORWEGIAN_ELKHOUND_GRAY: 'Grijze Noorse Elkhound',
      NORWEGIAN_LUNDEHUND: 'Noorse Papegaaiduiker Hond',
      NORWICH_TERRIER: 'Norwich Terriër',
      NOVA_SCOTIA_DUCK_TOLLING_RETRIEVER: 'Nieuwe schotland retriever',
      OLD_DANISH_POINTER: 'Oude Deense Staande Hond',
      OLD_ENGLISH_SHEEPDOG: 'Bobtail',
      OTTERHOUND: 'Otterjachthond',
      PARSON_RUSSELL_TERRIER: 'Parson jack russell terriër',
      PEKINGESE: 'Pekinees',
      PERUVIAN_HAIRLESS_DOG_LARGE: 'Peruaanse hond met blote huid (groot)',
      PERUVIAN_HAIRLESS_DOG_MEDIUM: 'Peruaanse hond met blote huid (Medium)',
      PERUVIAN_HAIRLESS_DOG_SMALL: 'Peruaanse hond met blote huid (klein)',
      PETIT_BASSET_GRIFFON_VENDEEN: 'Engels-Franse van Petite Vénerie',
      PETIT_BRABANCON: 'Klein Brabants',
      PETIT_GASCON_SAINTONGEOIS: 'Petit Gascon Saintongeois',
      PHARAOH_HOUND: 'Farao\'s hond',
      PICARDY_SHEPHERD: 'Picard Herder',
      PICARDY_SPANIEL: 'Picardische spaniël',
      POITEVIN: 'Poitevin',
      POLISH_HOUND: 'Poolse hond',
      POLISH_LOWLAND_SHEEPDOG: 'Poolse vlakte Herder',
      PONTAUDEMER_SPANIEL: 'Pont Audemer-spaniël',
      PORCELAINE: 'Porselein',
      PORTUGUESE_PODENGO_GRANDE: 'Grote Portugese Podengo',
      PORTUGUESE_PODENGO_MEDIO: 'Portugese Podengo Medio',
      PORTUGUESE_PODENGO_PEQUENO: 'Podengo Portugese Pequeno',
      PORTUGUESE_POINTER: 'Portugese Braque',
      PORTUGUESE_SHEEPDOG: 'Portugese herder',
      PORTUGUESE_SHEEPDOG_LONGHAIRED: 'Golvende Portugese Sennenhond',
      PORTUGUESE_SHEEPDOG_SHORTHAIRED: 'Kortharige Portugese Sennenhond',
      PORTUGUESE_WATER_DOG: 'Portugese Waterhond',
      POSAVAC_HOUND: 'Posavatz-hond',
      PRAGUE_RATTER: 'Praagse rattenvanger',
      PUDEL_POINTER: 'Pudel Pointer',
      PUG: 'Carlin',
      PULI_HUNGARIAN_SHEPHERD: 'Puli (Hongaarse Herder)',
      PUMI_HUNGARIAN_SHEPHERD: 'Pumi (Hongaarse Herder)',
      PYRENEAN_MOUNTAIN_DOG: 'Pyreneese Mastiff',
      PYRENEAN_POINTER: 'Franse Braque Pyreneeën',
      PYRENEAN_SHEEPDOG_LONGFACED_VARIETY: 'Langharige Pyreneese Herder',
      PYRENEAN_SHEEPDOG_SMOOTHFACED_VARIETY: 'Pyreneese herder met kort gezicht',
      RAFEIRO_DE_ALENTEJO: 'Rafeiro de Alentejo',
      REDBONE_COONHOUND: 'Redbone Coonhound',
      REDTICK_COONHOUND: 'Redtick Coonhound',
      RHODESIAN_RIDGEBACK: 'Rhodesische Ridgeback',
      ROMAGNA_WATER_DOG: 'Romagnolo-waterhond',
      ROTTWEILER: 'Rottweiler',
      ROUGH_COLLIE: 'Ruwharige Colley',
      RUSSIAN_BLACK_TERRIER: 'Russische zwarte terriër',
      RUSSOEUROPEAN_LAIKA: 'Laika Russo Europeaan',
      SAARLOOSWOLFHOND: 'Saarlooswolfhond',
      SAINTUSUGE_SPANIEL: 'St. Usuge-spaniël',
      SAINT_BERNARD: 'Sint Bernard',
      SALUKI: 'Saluki',
      SAMOYED: 'Samojeed',
      SANSHU: 'sanshu',
      SCHAPENDOES: 'Schapendoes',
      SCHILLERSTOVARE: 'Schiller Stovare',
      SCHIPPERKE: 'Schipperke',
      SCHWEIZER_LAUFHUND: 'Schwyz-hond',
      SCOTTISH_DEERHOUND: 'Schotse windhond',
      SCOTTISH_TERRIER: 'Schotse terriër',
      SEALYHAM_TERRIER: 'Sealyham terriër',
      SHAR_PEI: 'Shar Pei',
      SHETLAND_SHEEPDOG: 'Shetland herdershond',
      SHIBA_INU: 'Shiba (Shiba Inus)',
      SHIH_TZU: 'Shih Tzu',
      SHIKOKU: 'Sikoku',
      SHORTHAIRED_HUNGARIAN_POINTER: 'Hongaarse Kortharige Braque',
      SIBERIAN_HUSKY: 'Siberische husky',
      SILKY_TERRIER: 'Zijdeachtige terriër (Sydney Silky)',
      SKYE_TERRIER: 'skye terriër',
      SLOUGHI: 'Sloughi',
      SLOVAKIAN_WIREHAIRED_POINTER: 'Slowaakse ruwharige Braque',
      SLOVAK_CUVAC: 'Slowaakse Herder',
      SLOVAK_HOUND: 'Slowaakse hond',
      SMALAND_HOUND: 'Smalland',
      SMALL_MUNSTERLANDER: 'Kleine Munsterlander',
      SMALL_SPANISH_HOUND: 'Spaanse Hond (klein)',
      SMOOTH_COLLIE: 'Kortharige Colley',
      SMOOTH_FOX_TERRIER_ENGLISH_FOX_TERRIER: 'Kortharige foxterriër (Engelse vos)',
      SOFTCOATED_WHEATEN_TERRIER: 'zacht gecoate Ierse terriër',
      SOUTH_RUSSIAN_OVCHARKA: 'Zuid-Russische herder',
      SPANISH_GREYHOUND_GALGO_ESPANOL: 'Galgo Spaans',
      SPANISH_MASTIFF: 'Spaanse Mastiff',
      SPANISH_WATER_DOG: 'Spaanse Waterhond',
      SPINONE_ITALIANO: 'Spinone Italiano',
      STABYHOUN: 'Stabijhoun (Friese Staande Hond)',
      STAFFORDSHIRE_BULL_TERRIER: 'Staffordshire-bulterriër LOF',
      STANDARD_POODLE: 'Poedel (groot)',
      STANDARD_SCHNAUZER: 'middelgrote schnauzer',
      STICHELHAAR: 'Stichelhaar',
      STYRIAN_COARSEHAIRED_HOUND: 'Ruwharige Stiermarkse wandeling',
      ST_GERMAIN_POINTING_DOG: 'Braque St. Germain',
      SUSSEX_SPANIEL: 'Sussex-spaniël',
      SWEDISH_ELKHOUND: 'Zweedse elandhond',
      SWEDISH_LAPPHUND: 'Zweedse Laplandhond',
      TATRA_MOUNTAIN_SHEEPDOG: 'Poolse Podhale Herder',
      TECKEL_DE_CHASSE_AU_LAPIN_POIL_DUR: 'Transsylvanische hond',
      TIBETAN_MASTIFF: 'Tibetaanse Mastiff',
      TIBETAN_SPANIEL: 'Tibetaanse spaniël',
      TIBETAN_TERRIER: 'Tibetaanse terriër',
      TOSA: 'Tosa heeft LOF geregistreerd',
      TOY_POODLE: 'Poedel (Speelgoed)',
      TRICOLOUR_HOUND: 'Français Tricolore',
      TYROLEAN_HOUND: 'Tiroolse hond',
      WACHTELHUND: 'Oysel\'s hond (Wachtelhund)',
      WEIMARANER_LONGHAIRED: 'Langharige Weimar Braque',
      WEIMARANER_SHORTHAIRED: 'Weimaraner kortharige Braque',
      WELSH_CORGI_CARDIGAN: 'Welsh Corgi-vest',
      WELSH_CORGI_PEMBROKE: 'Welse Corgi Pembroke',
      WELSH_SPRINGER_SPANIEL: 'Welse springerspaniël',
      WELSH_TERRIER: 'Welse terriër',
      WESTPHALIAN_DACHSBRACKE: 'Basset van Westfalen',
      WEST_HIGHLAND_WHITE_TERRIER: 'West Highland White Terriër',
      WEST_SIBERIAN_LAIKA: 'West-Siberische Laika',
      WHIPPET: 'Whippet',
      WHITE_SWISS_SHEPHERD_DOG: 'Zwitserse witte herder',
      WIREHAIRED_HUNGARIAN_POINTER: 'Hongaarse Ruwharige Braque',
      WIREHAIRED_POINTING_GRIFFON: 'Wolharige Griffon',
      WIRE_FOX_TERRIER: 'Ruwharige foxterriër',
      YORKSHIRE_TERRIER: 'Yorkshire terriër',
      YUGOSLAVIAN_MOUNTAIN_HOUND: 'Joegoslavische berghond',
      YUGOSLAVIAN_SHEPHERD_DOG: 'Joegoslavische herder',
      YUGOSLAVIAN_TRICOLOUR_HOUND: 'Driekleurige Joegoslavische Hond'
    }
  },
  changePaymentMean: {
    ADD_BANK_ACCOUNT: 'Een te debiteren rekening toevoegen',
    ADD_BANK_CARD: 'Overschakelen op betaling met kredietkaart',
    BANK_ACCOUNT_ERROR: 'Er is een fout opgetreden bij het wijzigen van uw bankgegevens.  Neem alstublieft contact met de klantenservice op.',
    BANK_ACCOUNT_EXPLANATION: 'U moet weten dat geen van uw bankgegevens door onze servers wordt opgeslagen.',
    BANK_ACCOUNT_SUCCESS: 'Bankgegevens bijgewerkt',
    BANK_CARD_ERRORED: 'Probleem tijdens het wijzigen van het betaalmiddel. Neem alstublieft contact met de <1>klantenservice op</1>.',
    BANK_CARD_UPDATED: 'Bankpas is bijwerkt',
    CAPTATION: 'Voordat u uw bankgegevens bijwerkt, verlenen wij toestemming voor het aanrekenen van {{ amount }} op uw kaart.',
    CAPTATION_EXPLANATION1: 'Dit bedrag wordt niet afgehouden. De transactie wordt onmiddellijk geannuleerd.',
    CAPTATION_EXPLANATION2: 'Deze procedure stelt ons in staat om te voldoen aan de online betalingsstandaarden en om de veiligheid van uw bankgegevens te garanderen. Niets van deze informatie verlaat onze servers.',
    CAPTATION_EXPLANATION3: 'BELANGRIJK: op het incassoformulier zal de naam "Hareau" en niet "Weenect" staan. Dat is normaal, het gaat om de naam van ons bedrijf. Terwijl "Weenect" de handelsnaam is.',
    CAPTATION_EXPLANATION4: 'Wijzig de bankrekening waarop wordt afgeschreven, terwijl u toch hetzelfde abonnement houdt.',
    CARD_AUTHORIZED: 'De toegestane betaalwijzen zijn:',
    CHANGE_PAYMENT_MEAN: 'Update mijn creditcard',
    CURRENT_CHOICE_CB: 'U hebt ervoor gekozen om met een creditcard te betalen.',
    CURRENT_CHOICE_IBAN: 'U heeft ervoor gekozen om te betalen via IBAN',
    CURRENT_MEAN: 'Huidige betalingsmethode',
    CURRENT_MEAN_PHRASE: 'U heeft gekozen voor betaling per creditcard',
    CURRENT_MEAN_PHRASE_GOCARDLESS: 'U heeft gekozen om te betalen via automatische incasso',
    DEFAULT_MEAN: 'Standaardbetaalmethode:',
    GO_TO_SEPA: 'Overschakelen op automatische incasso',
    MODIFY: 'Mijn betaalmiddel wijzigen',
    TITLE: 'Betaalmiddel',
    UPDATE_BANK_ACCOUNT: 'Andere debetrekening kiezen',
    UPDATE_BANK_CARD: 'Update mijn creditcard',
    USED_PAYMENT_EXPLANATION: 'Deze betaalmethode wordt gebruikt om uw abonnementen en opties te betalen.'
  },
  commonActions: {
    ACTIVATE: 'Activeren',
    BACK: 'Terug',
    CANCEL: 'Annuleren',
    CAN_BE_CANCELED: 'Annuleren is mogelijk op elk gewenst moment.',
    CHANGE: 'Opslaan',
    CONFIGURE: 'Configureren',
    DEACTIVATE: '',
    GO_MAP: 'Ga naar de kaart',
    MESSAGES: {
      UPDATE_ERROR_UNKNOWN: 'Er is een probleem opgetreden'
    },
    NEXT: 'Volgende',
    NO: 'Nee',
    OK: 'Ok',
    REGISTER: 'Activeren',
    SAVE: 'Opslaan',
    SELECT_ALL: 'Alles selecteren',
    SEND: 'Versturen',
    SUBSCRIBE: 'Ik meld me aan',
    THEN: 'dan',
    UNSELECT_ALL: 'Unselect all',
    UPDATE: 'Opslaan',
    VALIDATE: 'Bevestigen',
    YES: 'Ja'
  },
  creditCardAlert: {
    AUTO_RENEWAL_ADVICE: 'Als je je tracker wilt blijven gebruiken, voeg dan een nieuwe betaalmethode toe.',
    AUTO_RENEWAL_NOTICE: '<1>Let op!</1> Je abonnement loopt af en je bankkaart is verlopen.'
  },
  emailPreferences: {
    EMAIL_LISTS: {
      COMPANY_NEWS: 'Nieuws over het bedrijf',
      NEW_FEATURES: 'Nieuwe functies',
      OFFERS: 'Aanbiedingen',
      SERVICE_STATUS: 'Storingen en updates',
      SURVEYS_AND_TESTS: 'Vragenlijsten en betatests',
      TUTORIALS: 'Tutorials'
    },
    EMAIL_LISTS_HEADER: 'E-mail lijst',
    EMAIL_LISTS_INFO: {
      SERVICE_STATUS: 'Noodzakelijk voor de goede werking van de dienst.'
    },
    LANGUAGE_PREFERENCES_DESCRIPTION: 'Kies de taal waarin u e-mails wenst te ontvangen :',
    PREFERENCES_DECRIPTION: 'Kies het type e-mails dat u wenst te ontvangen. Wij sturen u alleen de e-mails die overeenkomen met uw keuzes. '
  },
  error: {
    TITLE: 'Er is een probleem opgetreden'
  },
  errors: {
    DEFAULT: 'Ongeldig veld',
    FIELD_REQUIRED: 'Dit veld is vereist',
    GENERAL: 'Er is een fout opgetreden, probeer het nogmaals',
    IMEI: 'Voer een geldig IMEI-nummer in',
    MAIL_FORMAT: 'Dit e-mailformaal is ongeldig',
    MAX_LENGTH: 'Dit veld mag maximaal 15 tekens bevatten',
    PASSWORD_FORMAT: 'Je wachtwoord dient minstens één cijfer, één hoofdletter en een kleine letter.',
    PHONE_FORMAT: 'Het telefoonnummer dient het internationale kerngetal te bevatten',
    PROMO_CODE: 'Ongeldige kortingscode'
  },
  forgotPassword: {
    FORGOT_PASSWORD_BUTTON: 'Stuur mij een link om mijn wachtwoord opnieuw in te stellen',
    FORGOT_PASSWORD_CANCEL: 'Terug naar inloggen',
    FORGOT_PASSWORD_ERRORS: {
      UNKNOWN: 'Er is een fout opgetreden. Gelieve contact met ons op te nemen via onze helpdesk.'
    },
    FORGOT_PASSWORD_EXPIRED: 'De koppeling waarmee u uw wachtwoord opnieuw kunt instellen, is verlopen.',
    FORGOT_PASSWORD_RETRY: 'Vraag een nieuwe link aan voor een vergeten wachtwoord',
    FORGOT_PASSWORD_SUCCESS: 'Als er een account is gekoppeld aan het adres {{mail}}, ontvang je een e-mail met een link om je  wachtwoord opnieuw in te stellen.'
  },
  forms: {
    CITY: {
      LABEL: 'Stad',
      PLACEHOLDER: 'Je plaatsnaam'
    },
    CONFIRM_PASSWORD: {
      LABEL: 'Wachtwoord herhalen',
      PLACEHOLDER: 'Bevestigen'
    },
    COUNTRY: {
      LABEL: 'Land'
    },
    EMAIL: {
      LABEL: 'E-mailadres',
      PLACEHOLDER: 'votre@email.com'
    },
    FIRST_NAME: {
      LABEL: 'Voornaam',
      PLACEHOLDER: 'Je voornaam'
    },
    LANGUAGE: {
      LABEL: 'Taal'
    },
    LAST_NAME: {
      LABEL: 'Naam',
      PLACEHOLDER: 'Je naam'
    },
    NEW_PASSWORD: {
      LABEL: 'Nieuw wachtwoord',
      PLACEHOLDER: 'Je nieuwe wachtwoord'
    },
    OLD_PASSWORD: {
      LABEL: 'Vorig wachtwoord',
      PLACEHOLDER: 'Je vorige wachtwoord'
    },
    PASSWORD: {
      LABEL: 'Wachtwoord',
      PLACEHOLDER: 'Wachtwoord',
      VALIDATION: 'Uw wachtwoord moet bestaan uit minstens 8 tekens.',
      WARNING: 'Waarschuwing, "qwertyuiop" en "123456789" zijn GEEN sterke wachtwoorden.\nTest hier de beveiliging van uw wachtwoord: <1>https://howsecureismypassword.net</1>'
    },
    PHONE: {
      LABEL: 'Telefoon',
      PLACEHOLDER: '+33600000000'
    },
    POSTAL_CODE: {
      LABEL: 'Postcode',
      PLACEHOLDER: 'Je postcode',
      STREET_NUMBER: 'Straatnaam en huisnummer'
    },
    STREET: {
      LABEL: 'Adres',
      PLACEHOLDER: 'Straatnaam en huisnummer'
    }
  },
  geofences: {
    CREATE: {
      HEADLINE: 'Een gebied toevoegen'
    },
    EDIT: {
      DELETE_GEOFENCE: 'Gebied verwijderen',
      ENTRANCE_NOTIFICATION: 'Melding binnengaan zone',
      EXIT_NOTIFICATION: 'Melding verlaten zone',
      GEOCODE_LABEL: 'Gebruik een adres',
      GEOCODE_PLACEHOLDER: 'Typ het adres hier',
      LOCATION: 'Positie van het centrum van de zone',
      NAME_INPUT: {
        LABEL: 'Naam van de zone',
        PLACRHOLDER: 'Voer de naam van de zone in'
      },
      OUTDOORS: 'Buitenzone (park, bos)',
      OUTDOORS_TOOLTIP: 'Aangezien GPS buiten preciezer is, zijn de regels voor het afgaan van  een in- of uitgaande melding verschillend tussen zones die zich binnen bevinden en zones die zich buiten bevinden.',
      POINT_ON_THE_MAP_TEXT: 'of klik rechtstreeks op de kaart',
      RADIUS: 'Grootte van de zone (radius in meters)',
      TRACKER_LAST_POSITION_BUTTON: 'Gebruik de positie van de tracer',
      UNKNOWN_ADDRESS: 'Onbekend adres'
    }
  },
  header: {
    ALL_TRACKERS: 'Alle tracers',
    AVAILABLE_PLURAL: 'beschikbaar',
    NAV: {
      ACCOUNT: 'Rekening',
      INSURANCE: 'Verzekering',
      LOGOUT: 'Uitloggen',
      SUPPORT: 'Ondersteuning',
      TRACKERS: 'Kaart'
    },
    SEARCHBAR_RESULT: 'trackers gevonden',
    SEARCHBAR_TITLE: 'Een tracker zoeken:',
    TRACKERS: 'Tracer(s)',
    WHITE_LABEL: {
      IN_PARTERNSHIP_WITH: 'In samenwerking met'
    }
  },
  imeiInfo: {
    HINT: 'Je vindt het EMEI-nummer op de achterkant van je tracer.',
    IMAGE_ALT: 'Foto van de tracer',
    TITLE: 'IMEI-nummer'
  },
  login: {
    FORGOT_PASSWORD: 'Uw wachtwoord vergeten?',
    LOGIN_ERRORS: {
      UNKNOWN: 'Het inloggen is mislukt'
    },
    SIGN_IN_BUTTON: 'Inloggen',
    SIGN_IN_HEADLINE: 'Inloggen',
    SIGN_UP_BUTTON: 'Maak een account aan',
    SIGN_UP_HEADLINE: 'Heb je nog geen account?',
    TERMS_LINK: 'Gebruiksvoorwaarden',
    TERMS_LINK_URL: 'https://www.weenect.com/common/pdf/en/cgv-weenect.pdf'
  },
  manageSubscription: {
    CANCEL: {
      DESCRIBE_YOUR_PROBLEMS: 'Kunt u de reden voor het opzeggen van uw abonnement specificeren?',
      GIVE_US_DETAILS: 'Reden voor het opzeggen van uw abonnement.',
      REASONS: {
        AFTER_SALES_SERVICE: 'Slechte ervaring met de klantenservice',
        BATTERY: 'Onvoldoende accuduur',
        LOST_BROKEN: 'Ik ben het kwijt / het is kapot',
        MOBILE_APPLICATION: 'De app functioneert niet',
        NOT_SATISFIED: 'Ik ben niet tevreden met Weenect',
        NO_USE: 'Ik heb het niet meer nodig',
        TOO_EXPENSIVE: 'Het abonnement is te duur',
        TRACKER_FORMAT: 'Formaat van de tracker te herzien (maat en gewicht)',
        WILL_REACTIVATE_LATER: 'Ik zal het abonnement later opnieuw activeren'
      },
      SAD_TO_SEE_YOU_LEAVE: 'We vinden het heel jammer dat u weggaat.',
      WHY_DO_YOU_RESIGN: 'Waarom wilt u uw abonnement opzeggen?'
    },
    CANCEL_EXPIRE: 'De automatische verlenging van is geannuleerd. Het abonnement verloopt op {{date}}.',
    CANCEL_QUESTION: 'Wil je het abonnement niet verlengen?',
    CANCEL_RENEWAL: 'De automatische verlenging annuleren',
    CANCEL_RENEWAL_BIS: 'Het abonnement opzeggen',
    CANT_RESIGN_UNDER_EXTENDED_NOTICE: 'Wilt u uw abonnement beëindigen of alleen maar de automatische verlenging van uw abonnement deactiveren?\nBel ons dan op +33 (0)1 87 66 98 71 van 9.30 u tot 16.00 u. van maandag tot en met vrijdag.',
    CANT_RESIGN_UNDER_NOTICE: 'Het is niet mogelijk om uw abonnement in de laatste 30 dagen op te zeggen. Neem contact op met het Customer Support-team.',
    CANT_RESIGN_UNTIL: 'Je kunt je abonnement pas op {{date}} neerleggen',
    CANT_RESIGN_WHILE_COMMITED: 'U bent voor 3 maanden vastgelegd, de beëindiging van uw abonnement wordt pas van kracht aan het einde van de lopende periode, d.w.z. op {{date}}',
    CURRENT_PLAN: 'Huidig abonnement',
    HEADLINE: 'Abonnement beheren',
    MESSAGES: {
      UPDATE_SUCCESS: 'Abonnement met succes bijgewerkt.'
    },
    RENEW_SUBSCRIPTION: 'Het abonnement vernieuwen',
    RESIGN_DATE: 'De beëindiging van uw abonnement gaat in op het einde van de huidige periode, die {{date}}',
    RESUMPTION_DATE: 'Kies een hervattingsdatum',
    RETURN_TO_TRACKERS: 'Terug naar de kaart'
  },
  map: {
    NO_GPS_MARKER_TITLE: 'Geen GPS signaal',
    POSITION: 'Positie'
  },
  mapControls: {
    MAP_TYPE: 'Kaarttype',
    REFRESH_POSITION: 'Vernieuw positie',
    SUPER_LIVE: 'Super live',
    ZOOM_IN: 'Zoom +',
    ZOOM_OUT: 'Zoom -'
  },
  mapLayers: {
    CLASSIC_MAPS: 'Standaard kaarten',
    DONT_SUBSCRIBE: 'Verdergaan met de klassieke kaarten',
    FOREST_PATH: 'Boswegen en paden',
    GOOGLE_STREET: 'Google Maps',
    GRAY: 'Zwart en wit',
    PLAN: 'Kaart',
    PREMIUM_MAPS: 'Premium kaarten',
    PREMIUM_SUBSCRIBE: 'Abonneer voor de optie Premium Kaarten',
    PREMIUM_WARNING_MSG: 'Oeps, u bent momenteel niet geabonneerd voor de optie Premium kaarten! \u2028\n\nMet deze optie profiteert u van completere kaarten en regelmatige updates zodat u de verplaatsingen van uw tracker beter in de gaten kunt houden. \u2028Met de versies Satelliet HD, Reliëfkaarten of Bospaden vindt u uw tracker in één ogenblik terug.\n\nAbonneer u voor deze optie voor slechts {{mapOptionPrice}} per maand!',
    RELIEF: 'Bergkaart',
    SATELLITE: 'Sateliet',
    STREET: 'Klassiek',
    UNLOCK_MAPS: 'Speel extra kaarten vrij door erop te klikken!'
  },
  misc: {
    LANGUAGE: {
      DE: 'Duits',
      EN: 'Engels',
      ES: 'Spaans',
      FR: 'Frans',
      IT: 'Italiaans',
      NL: 'Nederlands'
    },
    NO_DATE: 'Geen positie bekend',
    ROTATE_DEVICE: 'Weenect is ontworpen om te worden gebruikt in portret modus. Draai je apparaat om zo goed mogelijk van onze diensten gebruik te kunnen maken.',
    locale: 'nl-NL'
  },
  onboarding: {
    ACTIVATING_HINT: 'Gemiddelde activatietijd van 5 minuten',
    ACTIVATING_INDICATOR: 'Wordt geactiveerd',
    GREET: {
      BUTTON: 'Mijn tracker is ingeschakeld',
      COMMON_TEXT: 'Om goed te beginnen raden we u aan om de volgende tutorial te volgen. het zal u helpen om uw tracker te beheren.\n\nLaten we beginnen met het inwerking stellen van uw tracker. Volg de indicaties hieronder om het aan te zetten:',
      MAIN_TITLE: 'Contactgegevens',
      RED_TEXT: 'De tracker dient zich buiten te bevinden zodat deze een GPS-positie kan bepalen.'
    },
    MAP_INTERACTIONS: {
      COMMON_TEXT: {
        PET: 'Van boven naar onder kunnen deze knoppen uw tracker laten afgaan of laten vibreren. Met deze functionaliteiten kunt u uw huisdier trainen om terug te komen.',
        SILVER: 'Van boven naar onder kunnen deze knoppen uw tracker laten afgaan of laten vibreren. '
      },
      TITLE: 'De knoppen voor interactie met de tracker'
    },
    MAP_INTERVAL: {
      COMMON_TEXT: 'Met deze knop kunt u kiezen op welke frequentie u een nieuwe GPS-positie voor uw tracker wilt ontvangen. Let op, hoe korter de tussentijd, hoe minder autonoom de autonomie van de tracker.',
      TITLE: 'De tijdsinterval tussen iedere GPS-positie'
    },
    MAP_OPTIONS: {
      COMMON_TEXT: 'Met deze knoppen kunt u het type kaart dat u wilt weergeven wijzigen en kunt u in- of uitzoomen.',
      TITLE: 'De afstelknoppen van de kaart'
    },
    MAP_UPDATE: {
      COMMON_TEXT: 'Met deze knop kunt u de positie van uw tracker actualiseren (om een nieuwe positie te krijgen dient uw tracker met de server te zijn verbonden en een geldig gps signaal te hebben).',
      TITLE: 'De knop om de positie te actualiseren'
    },
    MODAL: {
      BUTTON_HINT: 'Druk gedurende 3 seconden op de knop totdat de tracer trilt.',
      HEADLINE: 'Zet de tracer buiten of bij de rand van een raam aan.',
      IMAGE_ALT: 'Foto van de tracer',
      MAP_HINT: 'Eenmaal aangezet zal de tracer gemiddeld binnen 5 minuten op de kaart verschijnen.'
    },
    REMOTE_ASSISTANCE: {
      ASSISTANCE: {
        COMMON_TEXT: 'Indien niemand binnen 30 seconden op het alert reageert, wordt deze doorgestuurd naar een medewerker.'
      },
      EXPLANATION: 'De medewerker neemt de oproep aan en beantwoord de behoefte van de tracker-drager. Afhangende van de situatie kan hij <1>contact opnemen met naasten of hulpdiensten oproepen</1>.',
      PRICE: 'De optie kost € 9,99 per maand en kan op ieder gewenst moment worden uitgeschakeld.',
      SOS: {
        COMMON_TEXT: 'Je naaste drukt op de SOS knop van zijn/haar tracker.'
      },
      TIME: {
        COMMON_TEXT: 'U krijgt een alert en heeft 30 seconden om erop te reageren.'
      },
      TITLE: 'Ontdek onze nieuwe optie: teleassistentie ',
      USE_CASE: 'Met deze optie kan de drager van de tracker 7/7d en 24/24u contact maken met een gespecialiseerd teleassistentie plateau.'
    },
    SIDE_PAGES: {
      COMMON_TEXT: {
        PET: 'In het linkerdeel vindt u alle informatie betreffende het beheer en het gebruik van uw tracker. Onder de tab Geschiedenis vindt u alle verplaatsingen van uw huisdier en de onder de tab Activiteit volgen zijn energieverbruik. Onder de tab Zones kunt u deze configureren, de tab Instellingen bevat het profiel van uw huisdier en de in de tab Abonnement kunt u uw abonnement en uw opties zeer eenvoudig beheren.',
        SILVER: 'In het linkerdeel vindt u alle informatie betreffende het beheer en het gebruik van uw tracker. Onder de tab Geschiedenis vindt u alle verplaatsingen van uw huisdier en onder de tab  Zones kunt u de veiligheidsinstellingen configureren..\n Onder de tab Instellingen kunt u het te bellen nummer invoeren waarnaar gebeld dient te worden in geval van SOS evenals de meldingen die worden toegewezen aan iedere zijknop van de tracker. Als laatste kunt u in de tab Abonnement heel eenvoudig uw abonnement en uw opties beheren.'
      },
      TITLE: 'Extra pagina\'s'
    },
    SKIP: 'De tutorial overslaan',
    SUPERLIVE: {
      COMMON_TEXT: 'Met deze knop kunt u de  "superlive" modus aanzetten: iedere 10 seconden een nieuwe positie gedurende 5 minuten.',
      TITLE: 'De "superlive" knop'
    },
    TUTORIAL_START: {
      BUTTON: 'Verder gaan met de tutorial',
      COMMON_TEXT: 'Geweldig, uw tracker is nu klaar voor gebruik. Laten we verder gaan met een rondleiding door uw persoonlijke account in My Weenect.'
    },
    WAITING_GPS_POSITION: 'Zoeken naar een gps positie',
    WAITING_GPS_POSITION_HINT: 'Zet uw tracker buiten aan.'
  },
  optionCardExpired: {
    PROPOSAL: 'Als u uw tracker wilt blijven gebruiken, moet u deze nu bijwerken.',
    UPDATE_CARD: 'Nu bijwerken',
    WARNING: '<1>Opgelet!</1> U kunt u niet op deze optie inschrijven want <1>uw bankpas is verlopen</1>.'
  },
  payment: {
    ADD_NEW_PAYMENT_REGULARISE: 'Registreer een nieuwe betaalmethode of corrigeer uw situatie.',
    EXPIRACY_CREDIT_CARD_TEXT: 'Voeg een nieuwe betaalmethode toe om uw situatie te regulariseren.',
    NO_PAYMENT_MEAN_YET: 'Momenteel geen betaalmiddel',
    NO_PAYMENT_YET: 'Geen betaling op dit moment',
    UPDATE_PAYMENT_REGULARISE: 'Als u uw tracker wilt blijven gebruiken, moet u deze nu bijwerken of wijzigen.',
    WARNING_ERROR: 'Er lijkt een probleem te zijn met uw betaalmethode.',
    WARNING_EXPIRACY_CREDIT_CARD: 'Let op: Uw creditcard is verlopen.',
    WARNING_NOT_ENOUGH_FOUNDS: 'Let op, het lijkt erop dat er niet genoeg geld op je rekening staat.'
  },
  payments: {
    CARD: {
      ACCEPTED: '(CB, VISA, Mastercard, American Express)',
      DESCRIPTION: '30 dagen niet goed geld terug',
      NAME: 'Creditcard'
    },
    STATUS: {
      CANCELED: 'Gebruik een adres',
      ERROR: 'Mislukt',
      NEW: 'In behandeling',
      PAYMENT_ERROR: 'Betaling mislukt',
      PAYMENT_ERROR_EXPLAIN_TEXT: 'Er is een fout opgetreden tijdens uw betaling. Probeer het over enkele ogenblikken opnieuw of neem contact',
      PAYMENT_RETRY: 'Probeer opnieuw',
      PAYMENT_SAV: 'klantendienst.',
      PAYMENT_SUCCESS: 'Betaling geslaagd!',
      PAYMENT_SUCCESS_TEXT: 'Uw betaling is verwerkt. U kunt nu beginnen met uw ervaring bij Weenect !',
      PAYMENT_SUCCESS_TEXT_APP: 'Uw betaling is verwerkt.',
      PENDING: 'In behandeling',
      REFUNDED: 'Terugbetaald',
      SUCCESS: 'Bevestigd'
    },
    TRANSFER: {
      DESCRIPTION: '15 dagen extra om je te bedenken, oftewel 30 dagen niet goed geld terug garantie.',
      NAME: 'Bankafschrijving'
    }
  },
  periods: {
    ALT: {
      MONTH: '1 maand',
      YEAR: '1 jaar',
      YEAR_plural: '{{count}} jaar'
    },
    BASE: {
      MONTH: 'maand',
      YEAR: 'Jaar'
    }
  },
  registerSummaryTable: {
    BILLED_MONTH: 'De betaling is maandelijks',
    BILLED_NOW: 'Onmiddelijk gefactureerd',
    BILLED_THREE_YEARS: 'De betaling gebeurt eens in de 3 jaar',
    BILLED_TWO_YEARS: 'Dit wordt éénmaal per twee jaar gefactureerd',
    BILLED_YEAR: 'De betaling gebeurt eens per jaar',
    HINT_OPTIONS_PRICE: 'De opties, voor een bedrag van {{amount}}, zullen binnen twee uur afzonderlijk van het abonnement worden gefactureerd..',
    OPTION_UPGRADE: 'Voeg de optie {{name}} toe voor slechts {{price}} / {{period}}',
    PAY_HINT: 'Klik op de knop "Betalen en activeren" en betaal {{subscriptionPrice}} voor het abonnement.',
    SUBSCRIPTION_UPGRADE: 'Zet om naar een abonnement voor 2 jaar en bespaar ieder jaar {{amount}}.',
    TOTAL: 'Totale prijs'
  },
  registerTracker: {
    BUY_LINK: 'Heb je nog geen Weenect tracer? <1>Je kunt er hier één kopen</1>',
    ERRORS: {
      UNKNOWN: 'Er is een probleem ontstaan tijdens het activeren van de tracer, probeer het nogmaals'
    },
    HEADLINE: 'Een nieuwe tracer activeren',
    IMEI_INPUT: {
      LABEL: 'IMEI-nummer',
      PLACEHOLDER: 'Je vindt het EMEI-nummer op de achterkant van je tracer.'
    },
    NAME_INPUT: {
      LABEL: 'Naam van de tracker',
      PLACEHOLDER: 'Naam drager van de tracker'
    }
  },
  remoteAssistance: {
    BASE: 'Tele-ondersteuning',
    FEATURES: 'Als er na het drukken op de SOS-knop 30 seconden lang geen antwoord van u komt, worden de SOS-melding en de spraakoproep automatisch doorgestuurd, 7/7 dagen en 24/24 uur, naar onze hulpdienst op afstand. Onze medewerkers zijn bevoegd om contact op te nemen met reddingsdiensten zoals de politie of ambulance.',
    FORM: {
      ADDRESS_INPUT: {
        LABEL: 'Adres',
        PLACEHOLDER: 'Straatnaam en huisnummer'
      },
      BACK: 'Annuleren',
      CITY_INPUT: {
        LABEL: 'Stad',
        PLACEHOLDER: ''
      },
      EXTRA_PHONE: {
        LABEL: 'Tweede telefoon (optioneel)',
        PLACEHOLDER: ''
      },
      HEADLINE: 'Aanmelden voor tele-ondersteuning',
      NAME_INPUT: {
        LABEL: 'Volledige naam',
        PLACEHOLDER: 'Voornaam NAAM'
      },
      PHONE: {
        LABEL: 'Telefoon',
        PLACEHOLDER: ''
      },
      POSTAL_CODE_INPUT: {
        LABEL: 'Postcode',
        PLACEHOLDER: ''
      },
      SAVE: 'Bevestigen',
      TERMS: 'Ik heb <1>de gebruiksvoorwaarden</1> van de dienst gelezen en aanvaard ze.',
      TEXT: 'Voor de gegevens van je naaste (de drager van de tracer) in. Zo kunnen onze medewerkers te hulp komen bij een SOS-alert.'
    },
    PENDING: {
      WAITING_FOR_CALL_TEST: 'Inschrijfformulier bevestigd. In afwachting van de testoproep.\n U moet nu een testoproep uitvoeren om het activeren van de keuze af te ronden. Raadpleeg de aan u gerichte e-mail om de te volgen procedure te achterhalen.',
      WAITING_FOR_RESIGN: 'De desactivering van de keuze is bezig.\n Uw aanvraag voor de desactivering van de keuze is goed ontvangen. Onze medewerkers zijn op dit moment bezig met de administratieve formaliteiten. De desactivering zal binnenkort ingaan.',
      WAITING_FOR_VALIDATION: 'Inschrijfformulier in afwachting van bevestiging. \nDe gegevens die u hebt opgegeven tijdens uw inschrijving op de keuze worden op dit moment door onze medewerkers gecontroleerd. Zodra deze gegevens worden bevestigd, kunnen we overgaan tot het laatste stadium van de activering van de keuze: een testoproep moet worden uitgevoerd om te controleren of alles goed functioneert.'
    },
    USER_PHONE: {
      TEXT: 'Geef uw gegevens op. Zo kunnen onze medewerkers contact met u opnemen bij een SOS-alarm.'
    }
  },
  santevet: {
    ADVANTAGE: {
      FIRST: '<span>6 maanden Weenect-abonnement aangeboden</span>\nbij de inschrijving',
      PROMO_CODE: 'met de code',
      SECOND: '<span>20€ preventie per jaar aangeboden</span> bovenop het reeds inbegrepen pakket'
    },
    FOOTER: {
      BUTTON: 'Prijzen bekijken',
      TEXT: 'Door verder te gaan, gaat u ermee akkoord dat Santévet contact met u opneemt.'
    },
    OFFERS: {
      BILL: {
        TEXT: 'Van <span>60% tot 100%</span> en tot 2.500€/jaar.',
        TITLE: 'Je kosten vergoed in geval van ziekten en ongevallen'
      },
      CROSS: {
        TEXT: 'Profiteer <span>gratis</span> en <span>op elk moment</span> van telefonische assistentie.',
        TITLE: 'Een 24/7 veterinaire noodservice'
      },
      MONEY_PUSH: {
        TEXT: 'SantéVet schiet je kosten <span>gratis</span> voor aan het einde van de consultatie.',
        TITLE: 'De voorschotregeling van je dierenartskosten met Payvet'
      },
      SERINGE: {
        TEXT: '<span>Tot 150€/jaar</span> voor preventieve zorg (vaccinaties, antiparasitica, etc).',
        TITLE: 'Een preventiepakket inbegrepen in alle aanbiedingen'
      },
      TITLE: 'De voordelen van Santévet:'
    },
    SUBTITLE: 'en geniet van exclusieve voordelen',
    TITLE: 'Verzeker {{name}} voor\nslechts {{amount}} €/maand',
    TITLE_WITHOUT_PRICE: 'Verzeker {{name}} direct'
  },
  signup: {
    ALREADY_MEMBER_TEXT: 'Al gebruiker?',
    CREATE_ACCOUNT_HEADLINE: 'Maak een account aan',
    LOGIN_TEXT: 'In loggen',
    MARKETING_AGREEMENT_TEXT: 'Ik ga akkoord met het ontvangen van aanbiedingen en promoties van Weenect-partners',
    RETRY: 'Probeer opnieuw',
    SIGNUP_ERRORS: {
      EMAIL_ALREADY_EXISTS: 'Dit e-mailadres is al aan een account gekoppeld.',
      POPIN_EMAIL_ALREADY_EXIST: 'Oeps, dit emailadres schijnt al gebruikt te zijn.',
      UNKNOWN: 'Het inloggen is mislukt, probeer het nogmaals'
    },
    SIGN_IN_TEXT: 'Inloggen',
    SUBMIT_BUTTON: 'Mijn account aanmaken',
    TERMS_AGREEMENT_TEXT: 'Ik aanvaard <1>de gebruiksvoorwaarden</1> van de dienst'
  },
  simInfo: {
    TITLE: 'SIM-nummer'
  },
  subOptions: {
    CANCEL: {
      EXPIRATION_DATE: 'Deze optie blijft actief tot {{date}}',
      HEADLINE: 'Wil je de optie {{option}} annuleren?'
    },
    MAP: {
      BASE: 'Multi-kaarten pakket',
      FEATURES: 'Profiteer van completere kaarten en regelmatige updates zodat u de verplaatsingen beter in de gaten kunt houden. (Satelliet HD, Reliëfkaarten, Bospaden)',
      TITLE: 'HD kaarten: '
    },
    PREMIUM: {
      BASE: 'Premium pakket',
      BUTTON: {
        FREEMONTH: 'Probeer 1 maand gratis!'
      },
      DEACTIVATE: 'Door deze optie te deactiveren verliest u uw premie voordelen.',
      FEATURE: {
        CHAT: {
          BASE: 'Online chat',
          TEXT: 'Chat met een Weenect-adviseur meteen via je app. Deze dienst is 7 dagen per week beschikbaar, van 9:00 tot 18:00 uur.',
          TEXT2: 'Geniet van exclusieve voordelen (online chat, sms-waarschuwingen, HD-kaarten) binnen de applicatie.'
        },
        SMS: {
          TEXT: 'Ontvang alle meldingen per sms (tegoed van 40 sms\'jes per maand), naast pushmeldingen en e-mails die al in het abonnement zijn inbegrepen.'
        }
      },
      FREEMONTH: {
        BASE: 'Eerste maand gratis!',
        BASE2: 'GRATIS'
      },
      SUBTITLE: 'Het Premium Pack bevat de volgende functies:',
      SUB_LEGAL: 'Dit lidmaatschap wordt elke maand automatisch verlengd, via de betaalwijze die u bij het afsluiten van uw abonnement hebt gekozen. U kunt het lidmaatschap op elk moment opzeggen via uw persoonlijke ruimte.',
      TEXT_INFO_PRICE: 'Daarna €{{price}}/maand na de eerste maand.',
      TITLE_DISCOVER: 'Ontdek de exclusieve voordelen:',
      TITLE_POPUP: 'Wordt premium lid!'
    },
    SMS_PACK: {
      ALT_FEATURES: 'Ontvang alle meldingen per SMS',
      BASE: 'SMS pakket',
      FEATURES: 'Ontvang alle meldingen per sms (20 sms-berichten beschikbaar per maand). Zonder deze optie ontvangt u nog altijd meldingen via pushberichten (app) en e-mails.'
    },
    UNDEFINED: {
      PAYMENTS: 'Betaling voor de optie'
    },
    WARRANTY: {
      ALT_FEATURES: {
        one: 'Ontvang gratis een nieuwe tracker in geval van schade, verlies of diefstal van je huidige tracker.',
        other: 'Betaald met debetkaart met mogelijkheid tot annuleren op iedere gewenst moment',
        two: 'Uw tracer is in 48H vervangen in geval van verlies, breuk of diefstal, zonder voorwaarden.'
      },
      BASE: 'Multi-risk verzekering',
      EXPLAINER: 'De standaard garantie dekt alleen de fabricatiefouten van de tracer.',
      FEATURES: 'Ontvang een gratis vervangende tracker bij schade, verlies of diefstal van uw huidige tracker; u hoeft geen bewijs te leveren. De standaard garantie dekt alleen de fabricatiefouten van de tracker.'
    }
  },
  subscription: {
    COMMON_TEXT: {
      MONTH: '/ maand',
      OR: 'Dus'
    },
    FEATURES: {
      APP_ACCESS: 'Onbeperkte toegang tot de applicatie en locatiedienst',
      REFOUND: '15 dagen proberen: tevreden of uw geld terug',
      SAV: 'Klantenservice beschikbaar 6/7 dagen. \nGegarandeerd antwoord binnen 24 uur.',
      WARRANTY: '24 maanden fabrieksgarantie'
    },
    FIRST_PAGE: {
      BUTTON: '',
      MODAL_GIFT_CARD: {
        BUTTON: '',
        INPUT_LABEL: '',
        TITLE: ''
      },
      MODAL_INFORMATION: {
        TEXT: '',
        TITLE: ''
      },
      SUBSCRIPTION_CGV: {
        FIRST_TEXT: '',
        INCLUDES: {
          FIRST: '',
          FIRST_BOLD: '',
          FOURTH: '',
          FOURTH_BOLD: '',
          SECOND: '',
          SECOND_BOLD: '',
          THIRD: '',
          THIRD_BOLD: ''
        },
        SECOND_TEXT: '',
        SECOND_TEXT_LINK: '',
        TITLE: ''
      },
      SUBSCRIPTION_CHOICE: {
        CHIP_ONE_MONTH: '',
        CHIP_ONE_YEAR: '',
        CHIP_THREE_YEAR: '',
        SUBSCRIPTION_TITLE_GIFT_CARD: '',
        SUBSCRIPTION_TITLE_MONTHLY: '',
        SUBSCRIPTION_TITLE_one: '',
        SUBSCRIPTION_TITLE_other: '',
        TITLE: ''
      }
    },
    FREE: '',
    HEADER: {
      STEP: '',
      TITLE: {
        FIRST_PAGE: '',
        SECOND_PAGE: '',
        THIRD_PAGE: ''
      }
    },
    INFO_CGU: 'Om van de abonnementen te kunnen genieten, moet u geldige bankgegevens invoeren en de algemene',
    INFO_CGU_BUTTON: 'de algemene voorwaarden voor het gebruik van de dienst.',
    INFO_OPTION: 'Betaling is maandelijks, opzegging is op elk moment mogelijk.',
    MONEY_BACK: 'Alle abonnementen worden onmiddellijk gefactureerd, maar kunnen binnen 15 dagen worden opgezegd, met volledige terugbetaling van het betaalde bedrag.',
    MONTH: '',
    PAYMENT_INFO: 'Geld-terug-garantie: 15 dagen om uw tracker uit te proberen',
    PAYMENT_SUBHEADLINE: 'Selecteer een betaalmethode om uw abonnement te betalen en uw tracker te activeren.',
    PAYMENT_TITLE: 'Kies uw betalingsmethode',
    PAYMENT_TITLE2: 'Register your payment method.',
    PRICE_MONTH_SAVED: 'dus {{price}} / maand',
    SECONDE_PAGE: {
      BUTTON: ''
    },
    SECOND_PAGE: {
      BASKET: {
        TEXT: {
          premium_pack: '',
          warranty: ''
        },
        TOTAL: ''
      },
      OPTION_CHOICE: {
        CHIP: '',
        TEXT: {
          premium_pack: '',
          warranty: ''
        },
        TITLE: {
          premium_pack: '',
          warranty: ''
        }
      },
      SUBTITLE: '',
      TITLE: ''
    },
    SUMMARY: 'Samenvatting',
    THIRD_PAGE: {
      BUTTON: '',
      MODAL: {
        TITLE: ''
      },
      PAYMENT_CHOICE: {
        BANK: '',
        CARD: '',
        TITLE: ''
      },
      SUBTITLE: ''
    }
  },
  subscriptionAlert: {
    ACTIVATE_AUTO_RENEWAL: 'De automatische verlenging weer inschakelen',
    AUTO_RENEWAL_ADVICE: 'Wij adviseren u om de automatische verlenging weer in te schakelen indien u uw abonnement wilt verlengen',
    AUTO_RENEWAL_NOTICE: '<1>Let op!</1> Uw abonnement voor de tracer {{trackerName}} loopt af en de automatische verlening ervan is uitgeschakeld.',
    CONTINUE_BUTTON: 'Doorgaan',
    MESSAGES: {
      UPDATE_ERROR_UNKNOWN: 'Er is een probleem opgetreden',
      UPDATE_SUCCESS: 'Met succes bijgewerkt'
    },
    REMIND_LATER: 'Herinner mij hier later aan',
    SUPPORT_TEXT: 'Indien u vragen hebt of u denkt dat het een fout betreft, neem dan contact met onze <1>klantenservice op</1>.',
    TRIAL_CASE: {
      SUBSCRIBE_BUTTON: 'Abonnement afsluiten',
      TRIAL_IS_OVER: 'Je proefperiode is voorbij',
      TRIAL_IS_OVER_ADVICE: 'We hopen dat je proefperiode een succes was! Om het product te kunnen blijven gebruiken, moet je nu een abonnement nemen.'
    }
  },
  subscriptionOptionsSelection: {
    CANCEL_WARNING_MODAL_HEADLINE: 'Weet je zeker dat je deze optie wilt deactiveren?',
    CANCEL_WARNING_SMS: 'Door deze optie te deactiveren ontvang je geen meldingen voor alerts via SMS.',
    CANCEL_WARNING_WARRANTY: 'Door deze optie te deactiveren verlies je de extra bescherming van de multi-risico verzekering.',
    EXPLANATION: 'De opties staan standaard geselecteerd, maar u kunt ze uitvinken door erop te klikken.',
    EXPLANATION2: 'And cancellation is possible at any time.',
    EXPLANATION3: 'Er wordt niet betaald.',
    HINT: 'je kunt op ieder gewenst moment een optie activeren of deactiveren',
    MAIN_HEADLINE: 'Kies je opties',
    SUBMIT_BUTTON: 'Bevestigen'
  },
  subscriptionPayment: {
    ERRORS: {
      UNKNOWN: 'Er is een probleem opgetreden tijdens de betaling, probeer het nogmaals'
    },
    GIFT_CARD: {
      EXPLANATION: 'Voer de code in die op de achterkant van de kaart staat die in uw tracker pack zit.',
      I_HAVE_ONE: 'Ik heb een cadeaubon',
      NEED_HELP: 'Hulp nodig met cadeaubonnen?',
      TITLE: 'Gebruik uw geschenk',
      USE: 'Gebruiken'
    },
    HEADLINE: 'Overzicht vóór betaling',
    PROMOCODE_TEXT: 'Ik heb een coupon code:',
    PROMO_CODE: 'Coupon code',
    PROMO_CODE_GIFT_CARD: 'Cadeaubon',
    SATISFIED_OR_REFUNDED: 'Niet goed geld terug garantie',
    SATISFIED_OR_REFUNDED_DESCRIPTION: '30 dagen om deze zonder risico uit te proberen, als u niet tevreden bent geven we uw geld terug,',
    SELECT_PAYMENT_MEAN: 'Selecteer een betaalmethode voordat u uw abonnement betaalt en activeert.',
    SUBMIT_BUTTON: 'Abonnement instellen',
    SUBMIT_BUTTON2: 'Activeer de tracker',
    SUMMARY: 'Herhaling van je keuzes'
  },
  subscriptionProcess: {
    GIFT_CARD_EXPLANATION: 'Voer de betalingsmethode van uw voorkeur in om uw tracker gratis te activeren.',
    GIFT_CARD_EXPLANATION2: 'Deze informatie is nodig om juridische redenen in verband met het gebruik van SIM-kaarten.',
    RETURN_TO_APP: 'Terugkeren naar de app.',
    RETURN_TO_MAP: 'Terugkeren naar de kaart',
    RETURN_TO_TRACKERS: 'Terug naar de kaart',
    STEPS: [
      'Tracer',
      'Abonnement',
      'Opties',
      'Samenvatting'
    ]
  },
  subscriptionSelection: {
    BEST_OFFER: 'BESTE AANBIEDING',
    CANCELABLE_AFTER_THREE_MONTHS: 'Mogelijkheid tot annuleren op iedere gewenst moment na de eerste drie maanden',
    CANCEL_ANYTIME: 'Mogelijkheid tot annuleren op iedere gewenst moment',
    COMMITMENT_TEXT: 'Kan op elk moment worden opgezegd na de minimale verbintenisperiode van 3 maanden.',
    FEATURES: '',
    FEATURES_HEADLINE: 'Alle abonnementen zijn inclusief:',
    HEADLINE: 'Kies je abonnement',
    MIN_THREE_MONTHS: 'Minimum inschrijving voor drie maanden',
    MONEY_BACK: 'Alle abonnementen worden onmiddellijk gefactureerd maar kunnen binnen een termijn van 30 dagen worden geannuleerd, met terugbetaling van het volledige betaalde bedrag.',
    PAYMENT_MEANS: 'Betaling via automatische incasso of kredietkaart',
    REFUNDED_INFO: 'We betalen uw abonnement terug zonder voorwaarden.',
    REFUNDED_TEXT: 'Tevreden of terugbetaald 30 dagen.',
    SIM_CARD_WARNING: 'Een SIM kaart is direct in uw tracker ingebouwd. Dit verklaart waarom het nodig is om in te schrijven op een abonnement (om de servicekosten te dekken).',
    SUBMIT_BUTTON: 'Bevestigen',
    XMAS_OFFER: {
      BODY: 'Toch zijn we verplicht om u nu al te laten kiezen voor uw toekomstig abonnement (om veiligheidsreden is het product namelijk uitgerust met een SIM-kaart). Er worden geen kosten in rekening gebracht en u kan het abonnement op elk moment opzeggen tot één maand voor de ingangsdatum.',
      TITLE: 'Uw tracker is voorgeladen met een abonnement van 6 maanden geschonken  door uw geliefde.'
    },
    features: [
      '30 dagen niet goed geld terug',
      'Onbeperkte GPS lokalisatie',
      'Meldingen via push-berichten (App) en e-mails',
      '10 minuten spraakoproep per maand'
    ]
  },
  subscriptions: {
    BASE: 'Abonnement',
    CANCEL_MODAL: {
      HEADLINE: 'Wil je de automatische verlenging van je abonnement annuleren?',
      HINT: 'Je abonnement blijft actief tot {{date}} maar zal niet automatisch worden verlengd. Na deze datum kun je je tracer niet meer localiseren.'
    },
    CHANGE_PLAN: {
      ADD_OPTIONS: 'Een optie toevoegen',
      EXPLANATION: 'De verandering van abonnement zal ingaan aan het eind van de lopende periode.',
      HEADLINE: 'Van abonnement veranderen?'
    },
    DETAILS: {
      ACTIVATE_BUTTON: {
        LABEL: 'Abonnement beheren'
      },
      ACTIVE_CASE: {
        MANAGE_BUTTON: 'Abonnement beheren',
        RENEWAL_INFO: 'Je abonnement wordt automatisch verlengd op {{date}} voor de prijs van {{price}}'
      },
      CANCELED: {
        EXPIRED_MAIN: 'Uw abonnement is verlopen. Activeer het abonnement opnieuw om uw tracker te kunnen lokaliseren.',
        EXPIRED_SEC: 'Verlopen',
        UNCANCEL_BUTTON_LABEL: 'Automatische verlenging van abonnement opnieuw activeren',
        WILL_EXPIRE_DAYS: 'Verloopt over {{count}} dag',
        WILL_EXPIRE_DAYS_plural: 'Verloopt over {{count}} dagen',
        WILL_EXPIRE_MAIN: 'De automatische verlenging van je abonnement is geannuleerd. Het verloopt op {{date}}.'
      },
      CANCEL_RESILLIATION: 'Annuleer het annuleringsverzoek',
      NEW_CASE: {
        INFO_TEXT: 'Er is een probleem met je betaling. Probeer het opnieuw of wijzig je betaalmethode.',
        PAY_BUTTON: 'Betalen en activeren',
        WAITING_TEXT: 'We verwerken momenteel je betaling voor het abonnement op deze tracker.'
      },
      NO_SUB: {
        INFO_TEXT: 'Activeer het abonnement om te beginnen de tracer te gebruiken.'
      },
      RESILLIATION_ASKED: 'Gevraagde annulering',
      SUBSCRIBE_BUTTON: {
        LABEL: 'Abonnement afsluiten'
      },
      SUPPORT_TEXT: 'Heb je problemen met het activeren van je tracer? Neem contact op met <1>ondersteuning</1>.',
      TRIAL_PERIOD_CASE: {
        END_INFO: 'Uw proefperiode eindigt op {{ date }}'
      }
    },
    OFFERS: {
      SAVING_PER_YEAR: 'bespaar {{amount}} / jaar'
    },
    OFFERS_TEXT: {
      MOST_POPULAR: 'De populairste',
      ONE_MONTH: {
        INFO: 'Verbintenis van 3 maanden',
        TITLE: '1 maand pakket'
      },
      ONE_YEAR: {
        INFO: '15 dagen proefperiode: 30 dagen in totaal',
        TITLE: '1 jaar pakket'
      },
      THREE_YEAR: {
        INFO: '1 jaar extra fabrieksgarantie: 3 jaar in totaal',
        INFO2: '3-year manufacturer\'s warranty (instead of two).',
        TITLE: '3-jarig pakket'
      }
    },
    OVERVIEW: {
      OPTIONS: 'Details van de opties',
      REG_DETAILS: 'Tracker gegevens',
      REPLACE_TRACKER_BUTTON: {
        LABEL: 'Tracer vervangen'
      },
      SUB_DETAILS: 'Details van het abonnement'
    },
    PLAN_NAMES: {
      FALLBACK: 'Abonnement',
      ONE_MONTH: 'Maandelijks abonnement',
      ONE_YEAR: 'Prepaid abonnement van 1 jaar',
      THREE_YEARS: 'Prepaid abonnement van 3 jaar',
      TRIAL_PERIOD: 'Proefperiode',
      TWO_YEARS: 'Abonnement 2 jaar'
    },
    STATUS: {
      ACTIVE: 'Geabonneerd',
      CANCELED: 'Geannuleerd',
      INACTIVE: 'Niet Geabonneerd',
      NEW: 'Actief',
      PENDING: 'In behandeling'
    },
    TRACKER_DETAILS: {
      REGISTRATION_DATE: 'Datum van activatie',
      WARRANTY: 'Standaard garantie'
    }
  },
  superLive: {
    ACTIVE_TEXT: 'De super live modus is actief',
    HEADLINE: 'Super live modus',
    MESSAGES: {
      SUCCESS_ACTIVATED: 'Super live modus met succes geactiveerd - Eén positie iedere {{frequency}} seconden gedurende 5 minuten'
    },
    TIME: 'Eén positie elke {{frequency}} seconden'
  },
  support: {
    FORM: {
      MESSAGE: {
        LABEL: 'Je bericht*',
        PLACEHOLDER: ''
      },
      PROBLEM: {
        AVAILABLE_OPTIONS: 'Beschikbare opties',
        LABEL: 'Soort probleem*'
      },
      SUBJECT: {
        LABEL: 'Onderwerp van je vraag*',
        PLACEHOLDER: ''
      },
      TRACKER: {
        LABEL: 'Betreffende tracer aangaande je vraag',
        PLACEHOLDER: 'Selecteer de tracer'
      }
    },
    HEADLINE: 'Ondersteuning',
    HELP_CENTER: 'Helpcentrum',
    HELP_TEXT: 'Hulp nodig? Ga naar ons helpcenter om het antwoord op je vraag te vinden. Je kunt ook contact opnemen middels het formulier hieronder; ons team zal je op werkdagen binnen 24 uur antwoorden.',
    MAIN_TEXT: 'In ons helpcentrum vindt u alle artikelen die uw vragen kunnen beantwoorden.',
    MESSAGES: {
      SUBMIT_ERROR: 'Er is een fout opgetreden, probeer het nogmaals',
      SUBMIT_SUCCESS: 'Bericht met succes verzonden'
    },
    PROBLEM_TYPES: {
      CHARGE: 'Mijn tracer laadt niet normaal op',
      DOWNLOAD_APP: 'Het lukt me niet om de app te downloaden',
      GPS_INVALID: 'Mijn GPS-signaal wordt als ongeldig weergegeven',
      GPS_PRECISION: 'De GPS-locatie van mijn tracer is onjuist',
      LEDS_FIXED: 'De twee leds van mijn tracer veranderen niet',
      NO_GEOFENCE_ALERTS: 'Ik ontvang onjuiste meldingen voor het in- of uitgaan van een zone',
      NO_NOTIFICATIONS: 'Ik ontvang geen meldingen',
      OTHER: 'Andere',
      SIGN_IN: 'Het lukt me niet om in te loggen op mijn account',
      SUBSCRIPTION: 'Ik heb een probleem met mijn abonnement',
      TRACKERS_PARAMS: 'Ik kan mijn tracer niet instellen',
      TRACKER_NOT_CONNECTED: 'Mijn tracer wordt als niet-verbonden weergegeven'
    },
    SUBMITTED_TICKET: 'We hebben uw aanvraag goed ontvangen, dank u.\nEr is een e-mail ter bevestiging aan u verzonden (vergeet niet om uw spambox te controleren).',
    SUB_TEXT: 'Als deze uitleg niet voldoende is, kunt u ook contact opnemen met een lid van ons serviceteam, dat u binnen 24 werkuren zal antwoorden.',
    TITLE: 'Heb je hulp nodig?',
    URGENT_NUMBER: 'Als uw aanvraag dringend is, kunt u contact opnemen met onze klantenservice op \n+33 187 660 083 van 10 u. tot 12 u. en van 14 u. tot 17 u. van maandag tot en met vrijdag.'
  },
  trackerLeftPanel: {
    CALL: 'Oproepen',
    CTR_ONLY_SECONDS: 'U hebt nog {{SECONDS}} seconde(n) telefoongesprekken voor de lopende maand.',
    CTR_WITH_MINUTES: 'U hebt nog {{MINUTES}} minute(n) en {{SECONDS}} seconde(n) telefoongesprekken voor de lopende maand.',
    FLASH: 'Zaklamp',
    GOOD_GPS_ACCURACY: 'Goede GPS precisie ',
    LAST_POSITION: 'Laatste bekende positie:',
    MEDIUM_GPS_ACCURACY: 'Gemiddelde GPS precisie',
    OFFLINE: 'Tracer niet verbonden',
    POOR_GPS_ACCURACY: 'Slechte GPS precisie',
    RING: 'Beltoon',
    SMS_AVAILABLE: 'SMS beschikbaar : {{SMS_count}}',
    VIBRATE: 'Triller'
  },
  trackerSettings: {
    BUTTONS: {
      LABELS: {
        BUTTON: 'Knop'
      },
      PLACEHOLDER_BUTTON: 'Voer het bericht in dat verzonden wordt met Waarschuwing {{button_number}}',
      TEXT: 'Schrijf een bericht voor elke waarschuwing van uw lokalisator. U ontvangt het bericht in uw meldingen wanneer de knop van de lokalisator ingedrukt wordt.'
    },
    CONTACTS: {
      ADD_EMAIL_BUTTON: 'Een e-mailadres toevoegen',
      ADD_PHONE_BUTTON: 'Een telefoonnummer toevoegen',
      EMAILS_LABEL: 'E-mailadres voor meldingen per e-mail',
      MAIL: {
        PLACEHOLDER: 'votre@email.com'
      },
      MESSAGES: {
        UPDATE_ERROR_UNKNOWN: 'Er is een probleem opgetreden, probeer het nogmaals.',
        UPDATE_SUCCESS: 'Contacten met succes bijgewerkt'
      },
      NUMBERS_LABEL: 'Telefoonnummer voor meldingen per SMS',
      PHONE: {
        PLACEHOLDER: '+33600000000'
      }
    },
    DISPLAY: {
      MESSAGES: {
        UPDATE_ERROR_UNKNOWN: 'Er is een probleem opgetreden',
        UPDATE_SUCCESS: 'Instellingen met succes gewizigd'
      },
      MODAL: {
        HEADLINE: 'Icoon selecteren',
        TEXT: 'Je kunt de gewenste icoon in de onderstaande lijst selecteren. Deze zal je tracer op de kaart weergeven.'
      },
      TRACKER_ICON: {
        EDIT_TEXT: 'Icoon wijzigen',
        LABEL: 'Tracker icoon'
      },
      TRACKER_NAME: {
        LABEL: 'Naam van de tracker',
        PLACEHOLDER: 'Naam die wordt weergegeven op de kaart'
      },
      TRACKER_TYPE: {
        CHANGE: 'Het type tracker wijzigen',
        MODIFIED_SUCCESS: 'Type tracker met succes gewijzigd'
      }
    },
    GPS_FREQUENCY: {
      BATTERY_USAGE: 'Gemiddelde autonomie van een batterij:',
      BATTERY_USAGE_LEVELS: {
        HIGH: 'Hoog',
        LOW: 'Laag',
        MEDIUM: 'Gemiddeld'
      },
      GEOFENCE_NOTIFICATIONS: 'Gemiddelde reactiviteit van meldingen voor de zone:',
      HINT: 'Een hogere frequentie vermindert de autonomie van de batterij maar verbetert de reactiviteit van meldingen voor het in- en uitgaan van een zone. Een lagere frequentie verbetert daarentegen de autonomie van de batterij maar vermindert de reactiviteit van meldingen voor het in- en uitgaan van een zone.',
      TEXT: 'Kies de tijdsinterval tussen elke GPS-positie'
    },
    NOTIFICATION_SETTINGS: {
      APP: 'App',
      BATTERY: {
        HINT: 'Stuur een melding naar alle contacten indien de tracer een zwakke batterij heeft.',
        TITLE: 'Batterij'
      },
      BUTTONS: {
        HINT: 'Stuur een melding naar alle contacten wanneer één van de laterale knoppen wordt ingedrukt. Het voor deze knop ingestelde bericht zal bij de melding worden ingesloten.',
        TITLE: 'Laterale knoppen'
      },
      GEOFENCES: {
        HINT: 'Stuur een melding naar alle contacten wanneer de tracer een ingestelde zone in- of uitgaat.',
        TITLE: 'Veiligheidszones'
      },
      MAIL: 'E-mail',
      MESSAGES: {
        UPDATE_ERROR_UNKNOWN: 'Er is een probleem opgetreden, probeer het nogmaals',
        UPDATE_SUCCESS: 'De instellingen voor meldingen zijn met succes gewijzigd'
      },
      SMS: 'SMS',
      SOS: {
        HINT: 'Stuur een melding naar alle contacten wanneer de SOS-knop wordt ingedrukt.',
        TITLE: 'SOS oproep'
      },
      WARNING: {
        SMS: {
          LINK: 'Klik hier om die te activeren.',
          TEXT: 'Let op, als u meldingen per sms wilt ontvangen, moet u de bijbehorende keuze activeren.'
        }
      }
    },
    SECTION_TITLES: {
      BUTTONS: 'Knoppen',
      CONTACTS: 'Contacten voor meldingen',
      DISPLAY: 'Algemeen',
      GPS_FREQUENCY: 'Tijdsinterval tussen iedere GPS-positie',
      NOTIFICATION_SETTINGS: 'Type meldingen voor iedere alert',
      SOS: 'SOS-nummer',
      TRAINING: 'Telefoonfunctie'
    },
    TRAINING_SOS: {
      DIRECT_CALL: 'Dit nummer bellen in geval van een SOS-melding.',
      INPUT_PLACEHOLDER: '+33600000000',
      MESSAGES: {
        UPDATE_ERROR_UNKNOWN: 'Er is een probleem opgetreden, probeer het nogmaals',
        UPDATE_SUCCESS: 'Telefoonnummer met succes gewijzigd'
      },
      SOS_TEXT: 'Telefoonnummer dat de oproepen zal ontvangen bij een SOS alert',
      TRAINING_TEXT: 'Voer het telefoonnummer in dat zal standaard worden gebeld door de tracer wanneer de telefoonfunctie vanuit de app wordt gebruikt. Dit nummer kan worden aangepast bij iedere oproep aanvraag vanuit de app.'
    }
  },
  trackers: {
    ADD_TRACKER_BUTTON: {
      LABEL: 'Tracer toevoegen'
    },
    EXPIRATION: {
      EXPIRED: 'Het abonnement van {{tracker}} is verlopen',
      WILL_EXPIRE: 'Het abonnement van {{tracker}} verloopt over {{days}} dagen'
    },
    LINKS: {
      ACTIVITY: 'Opvolging van activiteit',
      GEOFENCES: 'Zones',
      HISTORY: 'Historie',
      SETTINGS: 'Instellingen',
      SUBSCRIPTION: 'Abonnement'
    }
  },
  trackersPositionRefresh: {
    MESSAGES: {
      NOT_REFRESHED: 'Uw tracker is er niet in geslaagd om nieuwe gps-posities te krijgen',
      REFRESHING: 'Even geduld, er is een verversingsverzoek bezig',
      SUCCESS: 'GPS positie van de tracer met succes bijgewerkt'
    }
  },
  transferPage: {
    ADD_TRACKER: 'Ik wil een nieuwe tracker toevoegen aan mijn account',
    BACK_TO_TRACKERS: 'Terug naar de kaart',
    FIRST_TITLE: 'Wat wil je doen?',
    FROM_HEADLINE: 'Te vervangen tracer:',
    HEADLINE: 'Een tracer vervangen',
    IMEI_LABEL: 'IMEI-nummer',
    INFO: 'Het huidige abonnement, evenals de geschiedenis van de GPS-posities en de instellingen, worden overgezet naar de nieuwe tracer na vervanging.',
    MESSAGES: {
      ERROR_UNKNOWN: 'Er is een probleem opgetreden, probeer het nogmaals',
      SUCCESS: 'Nieuwe tracer met succes toegewezen',
      WRONG_TYPE: 'De lokalisator met dit IMEI-nummer stemt niet overeen met het type van uw oude lokalisator. Neem contact op met onze helpdesk.'
    },
    NAME_LABEL: 'Naam van de tracker',
    PURCHASE: 'Heb je nog geen Weenect tracer? <1>Je kunt er hier één kopen</1>',
    REPLACE_BUTTON: {
      LABEL: 'Vervangen'
    },
    REPLACE_TRACKER: 'Ik wil een bestaande tracker vervangen',
    TO_HEADLINE: 'Nieuwe tracer:'
  },
  webappDisconnection: {
    BUG: 'Niet verbonden',
    MANUAL: 'U hebt uw tracker uitgeschakeld. Klik op de knop ON om hem weer aan te zetten.',
    NO_BATTERY: 'Uw tracker is uitgegaan omdat de accu leeg was.',
    NO_SUB: 'U hebt geen abonnement voor deze tracker. U kunt een abonnement afsluiten op de pagina'
  },
  wifiZones: {
    AIRPLANE_TEXT: 'Let op: zolang uw tracker zich in de Energiebesparende zones bevindt, kunt u hem in vliegtuigmodus niet meer laten rinkelen, trillen of handmatig de positie ervan verversen.',
    DEEP_SLEEP_TITLE: 'Vliegtuigmodus',
    DISPLAY_BUTTON: 'Toon gebied op de kaart',
    DISPLAY_TITLE: 'Meldingen en weergave',
    IN_AIRPLANE: '- in vliegtuigmodus',
    IN_OUT_TEXT: 'Ingangen en uitgangen van zones :',
    LAST_POS_IN_WIFI: 'In de Energiebesparende zones sinds {{date}} ',
    MAIL_NOTIF: 'Mail meldingen',
    NOTIF_TEXT: 'Ontvang een melding bij het betreden of verlaten van een zone.',
    POPIN: {
      AIRPLANE: {
        BUTTON_VALIDATE: 'De vliegtuigmodus uitzetten',
        CONTENT: 'U kunt niet langer communiceren met uw tracker terwijl deze zich in de&nbsp;<span style="color: #333333; font-family: Verdana, sans-serif;"><span>Energiebesparende zones</span></span><span>. Als je weer volledig functioneel wilt worden, &lt;1&gt; schakel je de vliegtuigmodus uit en haal je de tracker uit de zone&lt;1&gt;.</span>',
        TITLE: 'Uw tracker staat in vliegtuigmodus in de Energiebesparende zones {{wifiName}}.'
      },
      BUTTON_CLOSE: 'Later',
      BUTTON_VALIDATE: 'De zone deactiveren',
      CONTENT: 'In deze zone is het automatisch opvragen van GPS posities van uw tracker uitgeschakeld, maar u kunt het nog steeds laten bellen om het te vinden. U kunt ook de Energiebesparende zones deactiveren.',
      TITLE: 'Uw tracker bevindt zich in de energiebesparende zones {{wifiName}}.'
    },
    PUSH_NOTIF: 'Push meldingen',
    SMS_NOTIF: 'SMS meldingen',
    TITLE: 'Energiebesparende zones',
    ZONE_ACTIVATION: 'Gebied activeren'
  }
}
